<template>
  <div>
    <b-modal
      id="modal-data-category-picker"
      size="lg"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      :dir="dict.dir[lang]"
      scrollable
      content-class="shadow"
      @shown="initModal"
      @hidden="resetModal"
      hide-footer
    >

      <template #modal-header>
        <h5>{{ modalTitle[lang] }}</h5>
        <i
        >
          <font-awesome-icon
            icon="fas fa-xmark"
            class="close"
            @click="handleClose"
          />
        </i>
      </template>

      <div>
        <b-container fluid>
          <!--          <b-form-group>-->
          <!--            <b-form-radio-group-->
          <!--              v-model="type"-->
          <!--              class="d-flex"-->
          <!--              style="justify-content: space-around"-->
          <!--            >-->
          <!--              <b-form-radio value="data">Data</b-form-radio>-->
          <!--              <b-form-radio value="config" class="ml-5">Config</b-form-radio>-->
          <!--            </b-form-radio-group>-->
          <!--          </b-form-group>-->

          <b-card>
            <b-row align-h="between" class="mb-2">
              <b-col lg="4" sm="6" md="5" class="my-1">
                <ejs-textbox
                  :value="filter"
                  :placeholder="dict.search[lang]"
                  :enableRtl="lang === 'per'"
                  floatLabelType="Auto"
                  :showClearButton="true"
                  v-model="filter"
                ></ejs-textbox>
              </b-col>
            </b-row>

            <b-table
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              bordered
              small
              striped
              :empty-text="dict.emptyStr[lang]"
              @row-clicked="onRowClicked"
            >
              <template #head(name)="row">
                {{ row.field[lang] }}
              </template>
              <template #head(type)="row">
                {{ row.field[lang] }}
              </template>
            </b-table>

            <b-row align-h="between">
              <b-col lg="3" sm="4" md="4" class="my-1">
                <ejs-dropdownlist
                  :dataSource="pageOptions"
                  :fields="{ text: lang, value: 'value' }"
                  v-model="perPage"
                  @change="(val)=>{pageOptions=val.value}"
                ></ejs-dropdownlist>
              </b-col>

              <b-col lg="7" sm="8" md="8" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "dataCategoryModalPicker",

  data() {
    return {
      selected: null,
      to: "",
      type: "data",
      items: [],
      fields: [
        {
          key: "detail",
          label: "",
          _showDetails: true,
          thStyle: { width: "5%" }
        },
        { key: "name", per: "نام", eng: "name", label: "name", sortable: true },
        { key: "type", per: "نوع", eng: "type", label: "type", sortable: true, sortDirection: "asc" }
        // { key: "id", per: "نام", eng: "id", label: "name", sortable: true }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [{ value: 5, per: "5 ردیف", eng: "5 rows" },
        { value: 10, per: "10 ردیف", eng: "10 rows" },
        { value: 15, per: "15 ردیف", eng: "15 rows" },
        { value: 100, per: "100 ردیف", eng: "Show a lot" }],
      sortBy: "name",
      sortDesc: true,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      filterData: {},
      modalTitle: { per: "انتخاب داده", eng: "Pick Data" },
      dict: {
        dir: { per: "rtl", eng: "ltr" },
        emptyStr: { per: "هیچ رکوردی برای نمایش وجود ندارد", eng: "No records to display" },
        Clear: { per: "حذف", eng: "Clear" },
        search: { per: "جستجو", eng: "search" },
        name: { per: "نام داده", eng: "name" },
        type: { per: "نوع داده", eng: "type" }
      }
    };
  },
  computed: {
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    type(newValue) {
      this.getAllData();
    },
    parent2child(newValue) {
      if (newValue.task === "pickDataCategory") {
        this.to = newValue.from;
        this.filterData = newValue.filter;
      }
    }
  },
  methods: {
    initModal: function() {
      this.getAllData();
    },
    resetModal: function() {
      this.items = [];
    },
    getAllData() {
      this.$store.dispatch("data/getAllCategoryforList").then(
        data => {
          this.items = data;
          this.totalRows = data.length;
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    onRowClicked(item) {
      this.$store.dispatch("chpge/child2child", {
        task: "pickDataCategory",
        to: this.to,
        item: {
          id: item._id,
          name: item.name,
          dataType: 'freeArray',
          type: item.type
        }
      });
      this.$bvModal.hide("modal-data-category-picker");
    },
    handleClose() {
      this.$bvModal.hide("modal-data-category-picker");
    }
  }
};
</script>

<style scoped>
.close {
  font-size: 20px;
  color: #dc3545;
  cursor: pointer;
}

.close :hover {
  color: #9b9b9b;
}
</style>