var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"h-100",staticStyle:{"padding":"0 !important"},attrs:{"fluid":""}},[_c('div',[_c('ejs-grid',{ref:"wb_admin",attrs:{"width":'auto',"locale":_vm.lang,"height":"100%","loadingIndicator":{ indicatorType: 'Shimmer' },"allowSorting":true,"excelQueryCellInfo":_vm.excelQueryCellInfo,"allowExcelExport":true,"allowFiltering":true,"editSettings":_vm.curTableOptions.editSettings,"allowPdfExport":true,"toolbar":_vm.toolbarGenerator,"pdfExportComplete":_vm.pdfExportComplete,"toolbarClick":_vm.toolbarClick,"enableAdaptiveUI":true,"allowPaging":true,"enableRtl":_vm.lang == 'per',"pageSettings":{ pageSize: 5,pageSizes: [5, 10, 20, 50] },"selectionSettings":_vm.curTableOptions.selectionOptions,"dataSource":_vm.workbenchItems.length>=0 && !_vm.isList
            ? _vm.curTableOptions.items
            : _vm.workbenchGenerator},on:{"dataBound":_vm.dataBounded,"actionBegin":_vm.onActionBegin},scopedSlots:_vm._u([{key:"creator",fn:function(ref){
            var data = ref.data;
return [_vm._v(" "+_vm._s(((data.creator.fname ? data.creator.fname : "") + " " + (data.creator.lname ? data.creator.lname : "")))+" ")]}},{key:"status",fn:function(ref){
            var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.translateStatus[data.status][_vm.lang])+" ")]}},{key:"avatar",fn:function(ref){
            var data = ref.data;
return [_c('div',[_c('b-avatar',{staticStyle:{"background-color":"#0d6efd","color":"white"},attrs:{"variant":"info","src":_vm.avatarUrl[data.id],"text":((data.creator.fname ? data.creator.fname[0] : '') + " " + (data.creator.lname ? data.creator.lname[0] : ''))}})],1)]}},{key:"modify",fn:function(ref){
              var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-content-between col-5 gap-md-0 gap-5"},[_c('span',{directives:[{name:"permission",rawName:"v-permission",value:('admin_workbench\'s_users_list'),expression:"'admin_workbench\\'s_users_list'"}],staticClass:"e-icons e-people",staticStyle:{"font-size":"18px"},attrs:{"id":data.id},on:{"click":function($event){return _vm.showUsers(data)}}}),_c('span',{directives:[{name:"permission",rawName:"v-permission",value:('admin_allocate_resource'),expression:"'admin_allocate_resource'"}],staticClass:"e-icons e-page-setup",staticStyle:{"font-size":"18px"},attrs:{"id":"modalbtn"},on:{"click":function($event){return _vm.openModalLicence(data)}}})])]}}])},[_c('e-columns',[_c('e-column',{attrs:{"width":"50","type":"checkbox"}}),_vm._l((_vm.curTableOptions.fields),function(item,index){return _c('e-column',{key:index,attrs:{"field":item.key,"width":200,"headerText":item[_vm.lang],"template":item.hasTemplate ? item.key : null}})})],2)],1)],1)]),_c('b-modal',{staticStyle:{"width":"150% !important"},attrs:{"id":"processModal","size":"xl","centered":"","header-text-variant":"dark","header-border-variant":"primary","body-text-variant":"dark","no-close-on-backdrop":"","no-close-on-esc":"","content-class":"shadow","cancel-title":_vm.dict.cancel[_vm.lang],"dir":_vm.dict.dir[_vm.lang]},on:{"hidden":_vm.getWorkbenches,"shown":_vm.initList,"cancel":function($event){_vm.showThem=false}},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',[_vm._v(_vm._s(_vm.modalTitle))]),_c('i',{on:{"click":function($event){return _vm.$bvModal.hide('processModal')}}},[_c('font-awesome-icon',{staticClass:"close",attrs:{"icon":"fas fa-xmark"}})],1)]},proxy:true},{key:"modal-ok",fn:function(){return [_c('b-button',{staticClass:"p-0",attrs:{"variant":"primary"},on:{"click":function () {
            _vm.loadPanel = false;
          }}},[_vm._v(" "+_vm._s(_vm.dict.ok[_vm.lang])+" ")])]},proxy:true}])},[_c('div',[(_vm.showThem)?_c('workbench-users',{attrs:{"user-data":_vm.selectedId}}):_vm._e()],1)]),_c('b-modal',{attrs:{"id":"licenceModal","size":"xl","centered":"","header-text-variant":"dark","header-border-variant":"primary","body-text-variant":"dark","no-close-on-backdrop":"","scrollable":"","content-class":"shadow","dir":_vm.dict.dir[_vm.lang],"ok-title":_vm.dict.ok[_vm.lang],"cancel-title":_vm.dict.cancel[_vm.lang]},on:{"ok":_vm.handleLicenceSet},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{attrs:{"dir":_vm.dict.dir[_vm.lang]}},[_vm._v(_vm._s(_vm.dict.modalLicenceTitle[_vm.lang]))]),_c('i',{on:{"click":function($event){return _vm.$bvModal.hide('licenceModal')}}},[_c('font-awesome-icon',{staticClass:"close",attrs:{"icon":"fas fa-xmark"}})],1)]},proxy:true}])},[_c('div',[_c('licence-handler',{ref:"wbHandel",attrs:{"wb-id":_vm.wbId}})],1)]),_c('b-modal',{attrs:{"id":"admin-invite-user","size":"md","centered":"","header-text-variant":"dark","header-border-variant":"primary","body-text-variant":"dark","hide-header-close":"","no-close-on-backdrop":"","content-class":"shadow","title":_vm.dict.titleModal[_vm.lang],"dir":_vm.dict.dir[_vm.lang]},on:{"ok":_vm.invUser}},[_c('div',[_c('b-form-group',{staticStyle:{"width":"100%","padding":"0px 10px 10px 0px"},attrs:{"label":_vm.dict.labelModal[_vm.lang],"label-for":"user"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:1|max:200'),expression:"'required|min:1|max:200'"}],staticStyle:{"width":"100%"},attrs:{"name":"user","type":"email","placeholder":_vm.dict.placeholder[_vm.lang]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }