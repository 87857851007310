<template>
  <div
    :dir="dict.dir[lang]"
    :style="{ 'text-align': lang == 'per' ? 'right' : 'left' }"
  >
    <div id="for_firts">
      <b-container fluid>
        <b-row class="my-4">
          <template cols="12" sm>
            <b-col>
              <b-card no-body>
                <b-card-body>
                  <b-row>
                    <b-col cols="8" lg="11" md="10" sm="9">
                      <h5
                        :class="{
                          'text-start': lang == 'eng',
                          'text-end': lang == 'per',
                        }"
                        style="flex: 90%"
                      >
                        {{ dict.general[type][lang] }}
                      </h5>
                    </b-col>
                    <b-col cols="4" lg="1" md="2" sm="3">
                      <div
                        class="d-flex justify-content-around"
                        style="font-size: 24px"
                      >
                        <i v-if="registred" @click="handleRegister">
                          <font-awesome-icon
                            icon="fas fa-check-square"
                            class="apply"
                          />
                        </i>
                        <i @click="onReset">
                          <font-awesome-icon
                            icon="fas fa-xmark-square"
                            class="reset"
                          />
                        </i>
                      </div>
                    </b-col>
                  </b-row>

                  <hr class="my-2" />

                  <b-card no-body>
                    <div class="mga-tab h-100 p-0">
                      <div class="mga-tab-header">
                        <ul
                          v-if="registred"
                          class="mga-tab-nav mga-tab-nav-tabs"
                        >
                          <li
                            v-for="(item, index) in tabs"
                            class="mga-tab-nav-item"
                            @click="activeTab = item.name"
                            :key="index"
                          >
                            <a
                              class="mga-tab-nav-link"
                              :class="{ active: activeTab === item.name }"
                              :id="item.name"
                              data-toggle="tab"
                              role="tab"
                              aria-controls="tab1"
                              aria-selected="true"
                            >
                              {{ dict.tabs[item.name][lang] }}
                            </a>
                          </li>
                        </ul>
                        <ul v-else class="mga-tab-nav mga-tab-nav-tabs">
                          <li
                            class="mga-tab-nav-item"
                            @click="activeTab = 'basic'"
                            :key="index"
                          >
                            <a
                              class="mga-tab-nav-link"
                              :class="{ active: activeTab === 'basic' }"
                              :id="'basic'"
                              data-toggle="tab"
                              role="tab"
                              aria-controls="tab1"
                              aria-selected="true"
                            >
                              {{ dict.tabs.basic[lang] }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <transition-group :name="dict.transition[lang]" mode="out-in">
                      <div v-if="registred" class="p-3 pt-4" :key="registred">
                        <div v-show="activeTab == 'basic'" class="row">
                          <div class="col-md-3 col-12 pt-2">
                            <ejs-textbox
                              :autocomplete="'off'"
                              floatLabelType="Auto"
                              v-model="name"
                              :placeholder="dict.basic.name[lang]"
                              :enableRtl="lang === 'per'"
                              :required="true"
                              :maxLength="100"
                              :minLength="3"
                            ></ejs-textbox>
                          </div>

                          <div class="col-md-3 col-12 pt-2">
                            <ejs-dropdownlist
                              :dataSource="mapTypeList"
                              floatLabelType="Auto"
                              v-model="mapType"
                              :enabled="false"
                              :fields="{ text: 'text', value: 'value' }"
                              :placeholder="dict.basic.mapType[lang]"
                              :enableRtl="lang === 'per'"
                              :required="true"
                            ></ejs-dropdownlist>
                          </div>
                          <div class="col-md-3 col-12 pt-2">
                            <ejs-textbox
                              :autocomplete="'off'"
                              floatLabelType="Auto"
                              v-model="connection.host"
                              :placeholder="dict.basic.host[lang]"
                              :enableRtl="lang === 'per'"
                              :required="true"
                              :maxLength="100"
                              :minLength="3"
                            ></ejs-textbox>
                            <!--                            pattern="^(?!.*\\/$).*"-->
                          </div>
                          <div class="col-md-3 col-12">
                            <div class="form-check form-switch mt-4 ite">
                              <input
                                v-model="connection.onOffStatus"
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckChecked"
                              />
                              <label
                                class="form-check-label"
                                for="flexSwitchCheckChecked"
                              >{{ dict.status[lang] }}</label
                              >
                            </div>
                          </div>
                        </div>
                        <div v-show="activeTab == 'security'">
                          <b-form-radio-group
                            v-model="security.type"
                            class="
                              d-flex
                              justify-content-around
                              fw-bolder
                              text-center
                              border
                              rounded
                              p-1
                              mb-2
                              text-dark
                            "
                            style="
                              background-color: #a5b4fc;
                              width: 100%;
                              border-color: #a5b4fc !important;
                            "
                            name="security"
                            v-validate="'required'"
                          >
                            <b-form-radio value="basic" class="ml-5"
                            ><span class="mx-2">{{ dict.custom[lang] }}</span>
                            </b-form-radio>
                            <b-form-radio value="jwt" class="ml-5"
                            ><span class="mx-2">{{ dict.JWT[lang] }}</span>
                            </b-form-radio>
                            <b-form-radio value="apiKey" class="ml-5"
                            ><span class="mx-2">{{ dict.apiKey[lang] }}</span>
                            </b-form-radio>
                          </b-form-radio-group>

                          <b-collapse v-model="isSecClpsd2">
                            <div id="security_info" class="row">
                              <div class="col-md-4 my-2">
                                <ejs-textbox
                                  floatLabelType="Auto"
                                  :autocomplete="'off'"
                                  v-model="security.basic.url1"
                                  :placeholder="dict.security.url1[lang]"
                                  :enableRtl="lang === 'per'"
                                  :required="
                                    security.type == 'basic' ? true : false
                                  "
                                  :maxLength="100"
                                  :minLength="3"
                                ></ejs-textbox>
                              </div>
                              <div class="col-md-4 my-2">
                                <ejs-textbox
                                  floatLabelType="Auto"
                                  :autocomplete="'off'"
                                  v-model="security.basic.user1"
                                  :placeholder="dict.security.user1[lang]"
                                  :enableRtl="lang === 'per'"
                                  :required="
                                    security.type == 'basic' ? true : false
                                  "
                                  :maxLength="100"
                                  :minLength="3"
                                ></ejs-textbox>
                              </div>
                              <div class="col-md-4 my-2">
                                <ejs-textbox
                                  floatLabelType="Auto"
                                  :autocomplete="'off'"
                                  v-model="security.basic.pass1"
                                  :placeholder="dict.security.pass1[lang]"
                                  :enableRtl="lang === 'per'"
                                  :required="
                                    security.type == 'basic' ? true : false
                                  "
                                  type="password"
                                ></ejs-textbox>
                              </div>
                              <div class="col-md-4 my-2">
                                <ejs-textbox
                                  :autocomplete="'off'"
                                  v-model="security.basic.url2"
                                  :placeholder="dict.security.url2[lang]"
                                  :enableRtl="lang === 'per'"
                                  :required="
                                    security.type == 'basic' ? true : false
                                  "
                                  :maxLength="100"
                                  :minLength="3"
                                ></ejs-textbox>
                              </div>
                              <div class="col-md-4 my-2">
                                <ejs-textbox
                                  :autocomplete="'off'"
                                  v-model="security.basic.user2"
                                  :placeholder="dict.security.user2[lang]"
                                  :enableRtl="lang === 'per'"
                                  :required="
                                    security.type == 'basic' ? true : false
                                  "
                                  :maxLength="100"
                                  :minLength="3"
                                ></ejs-textbox>
                              </div>
                              <div class="col-md-4 my-2">
                                <ejs-textbox
                                  :autocomplete="'off'"
                                  v-model="security.basic.pass2"
                                  :placeholder="dict.security.pass2[lang]"
                                  :enableRtl="lang === 'per'"
                                  :required="
                                    security.type == 'basic' ? true : false
                                  "
                                  type="password"
                                ></ejs-textbox>
                              </div>
                              <div class="col-md-4 my-2">
                                <ejs-textbox
                                  :autocomplete="'off'"
                                  v-model="security.basic.retries"
                                  :placeholder="dict.security.retries[lang]"
                                  :enableRtl="lang === 'per'"
                                  type="number"
                                  :required="
                                    security.type == 'basic' ? true : false
                                  "
                                ></ejs-textbox>
                              </div>
                              <div class="col-md-4 my-2">
                                <ejs-textbox
                                  :autocomplete="'off'"
                                  v-model="security.basic.timeout"
                                  :placeholder="dict.security.timeout[lang]"
                                  :enableRtl="lang === 'per'"
                                  type="number"
                                  :required="
                                    security.type == 'basic' ? true : false
                                  "
                                ></ejs-textbox>
                              </div>
                            </div>
                          </b-collapse>

                          <b-collapse v-model="isSecClpsd3">
                            <b-card>
                              <div class="row mx-auto">
                                <div class="col-md-4 gap-3 col-12 d-flex">
                                  <ejs-textbox
                                    :autocomplete="'off'"
                                    v-model="security.jwt.user"
                                    :placeholder="dict.user[lang]"
                                    floatLabelType="Auto"
                                    :enableRtl="lang=='per'"
                                    :required="
                                      security.type == 'jwt' ? true : false
                                    "
                                    :maxLength="50"
                                  ></ejs-textbox>
                                </div>
                                <div class="col-md-4 gap-3 col-12 d-flex">
                                  <ejs-textbox
                                    :autocomplete="'off'"
                                    :value="security.jwt.pass"
                                    :placeholder="dict.pass[lang]"
                                    floatLabelType="Auto"
                                    :enableRtl="lang=='per'"
                                    :required="
                                      security.type == 'jwt' ? true : false
                                    "
                                    @input="
                                      (val) => {
                                        security.jwt.pass = val.value;
                                      }
                                    "
                                    type="text"
                                  ></ejs-textbox>
                                </div>
                                <div class="col-md-4 gap-3 col-12 d-flex">
                                  <ejs-textbox
                                    :autocomplete="'off'"
                                    v-model="security.jwt.url1"
                                    :placeholder="dict.url[lang]"
                                    floatLabelType="Auto"
                                    :enableRtl="lang=='per'"
                                    :required="
                                      security.type == 'jwt' ? true : false
                                    "
                                    :maxLength="50"
                                  ></ejs-textbox>
                                </div>
                              </div>
                            </b-card>
                          </b-collapse>
                          <b-collapse v-model="isSecClpsd1">
                            <b-card>
                              <div class="row mx-auto">
                                <ejs-textbox
                                  :autocomplete="'off'"
                                  v-model="security.apiKey.key"
                                  :placeholder="dict.apiKey[lang]"
                                  floatLabelType="Auto"
                                  :enableRtl="lang=='per'"
                                  :required="security.type == 'apiKey' ? true : false"

                                ></ejs-textbox>
                              </div>
                            </b-card>
                          </b-collapse>
                        </div>

                        <div v-show="activeTab == 'getMapping'">
                          <b-collapse v-model="isMapClpsd1">
                            <b-container fluid class="p-0">
                              <div
                                class="
                                  fw-bolder
                                  text-center
                                  border
                                  rounded
                                  p-1
                                  mb-2
                                  text-light
                                "
                                style="
                                  background-color: #a5b4fc;
                                  width: 100%;
                                  border-color: #a5b4fc !important;
                                "
                              >
                                {{ mapTypeText[lang] }}
                              </div>
                              <ejs-grid
                                ref="getMapping"
                                :width="'auto'"
                                :key="reRenGetmap"
                                :allowSorting="true"
                                :locale="lang"
                                :allowFiltering="true"
                                :editSettings="editSettings"
                                :toolbar="toolbarGenerator"
                                :toolbarClick="toolbarMagGettingClick"
                                :actionBegin="actionBegin"
                                :actionComplete="actionComplete"
                                :allowPaging="true"
                                :enableRtl="lang == 'per'"
                                :pageSettings="{
                                  pageSize: 5,
                                  pageSizes: [5, 10, 20, 50],
                                }"
                                :selectionSettings="selectionOptions"
                                :dataSource="mappingGet.JSON.items"
                              >
                                <e-columns>
                                  <e-column
                                    width="50"
                                    type="checkbox"
                                  ></e-column>
                                  <e-column
                                    v-for="(item, index) in statJsonFields"
                                    :key="index"
                                    :field="item.key"
                                    :width="item.key == 'modify' ? 120 : 'auto'"
                                    :headerText="item[lang]"
                                    :template="
                                      item.hasTemplate ? item.key : null
                                    "
                                  ></e-column>
                                </e-columns>

                                <template v-slot:modify="{ data }">
                                  <div class="d-flex justify-content-between">
                                    <span
                                      class="e-icons e-edit-6"
                                      style="font-size: 17px"
                                      @click="editMapPubJsonItem(data)"
                                    ></span>
                                    <span
                                      class="e-icons e-delete-1 text-danger"
                                      style="font-size: 17px"
                                      @click="removeMapPubJsonItem(data)"
                                    ></span>
                                  </div>
                                </template>
                              </ejs-grid>
                            </b-container>
                          </b-collapse>
                        </div>

                        <div v-show="activeTab == 'postMapping'">
                          <b-collapse v-model="isMapClpsd1">
                            <b-container fluid class="yekan p-0">
                              <div
                                class="
                                  fw-bolder
                                  text-center
                                  border
                                  rounded
                                  p-1
                                  mb-2
                                  text-light
                                "
                                style="
                                  background-color: #a5b4fc;
                                  width: 100%;
                                  border-color: #a5b4fc !important;
                                "
                              >
                                {{ mapTypeText[lang] }}
                              </div>
                              <ejs-grid
                                ref="mapSubJsonTable"
                                :width="'auto'"
                                :allowSorting="true"
                                :locale="lang"
                                :allowExcelExport="true"
                                :allowFiltering="true"
                                :editSettings="editSettings"
                                :allowPdfExport="true"
                                :toolbar="toolbarGenerator"
                                :toolbarClick="toolbarMagGettingClick"
                                :actionBegin="actionBegin"
                                :actionComplete="actionComplete"
                                :allowPaging="true"
                                :enableRtl="lang == 'per'"
                                :pageSettings="{
                                  pageSize: 5,
                                  pageSizes: [5, 10, 20, 50],
                                }"
                                :selectionSettings="selectionOptions"
                                :dataSource="mappingPost.JSON.items"
                              >
                                <e-columns>
                                  <e-column
                                    width="50"
                                    type="checkbox"
                                  ></e-column>
                                  <e-column
                                    v-for="(item, index) in statJsonFields"
                                    :key="index"
                                    :field="item.key"
                                    :width="item.key == 'modify' ? 120 : 'auto'"
                                    :headerText="item[lang]"
                                    :template="
                                      item.hasTemplate ? item.key : null
                                    "
                                  ></e-column>
                                </e-columns>

                                <template v-slot:modify="{ data }">
                                  <div class="d-flex justify-content-between">
                                    <span
                                      class="e-icons e-edit-6"
                                      style="font-size: 17px"
                                      @click="editMapPubJsonItem(data)"
                                    ></span>
                                    <span
                                      class="e-icons e-delete-1 text-danger"
                                      style="font-size: 17px"
                                      @click="removeMapPubJsonItem(data)"
                                    ></span>
                                  </div>
                                </template>
                              </ejs-grid>
                            </b-container>
                          </b-collapse>

                          <b-collapse v-model="isMapClpsd2">
                            <b-card></b-card>
                          </b-collapse>

                          <b-collapse v-model="isMapClpsd3">
                            <b-card></b-card>
                          </b-collapse>
                        </div>

                        <div v-show="activeTab == 'status'">
                          <b-collapse v-model="isMapClpsd1">
                            <b-container fluid class="yekan p-0">
                              <div
                                class="
                                  fw-bolder
                                  text-center
                                  border
                                  rounded
                                  p-1
                                  mb-2
                                  text-light
                                "
                                style="
                                  background-color: #a5b4fc;
                                  width: 100%;
                                  border-color: #a5b4fc !important;
                                "
                              >
                                {{ mapTypeText[lang] }}
                              </div>
                              <ejs-grid
                                ref="statusJsonTable"
                                :width="'auto'"
                                :allowSorting="true"
                                :locale="lang"
                                :allowExcelExport="true"
                                :allowFiltering="true"
                                :editSettings="editSettings"
                                :allowPdfExport="true"
                                :toolbar="[
                                  'Search',
                                  {
                                    text: dict.titleTranslateAdd[lang],
                                    prefixIcon: 'e-icons e-add',
                                    id: 'add_status',
                                  },
                                ]"
                                :toolbarClick="toolbarMagGettingClick"
                                :actionBegin="actionBegin"
                                :actionComplete="actionComplete"
                                :allowPaging="true"
                                :enableRtl="lang == 'per'"
                                :pageSettings="{
                                  pageSize: 5,
                                  pageSizes: [5, 10, 20, 50],
                                }"
                                :selectionSettings="selectionOptions"
                                :dataSource="status.JSON.items"
                              >
                                <e-columns>
                                  <e-column
                                    width="50"
                                    type="checkbox"
                                  ></e-column>
                                  <e-column
                                    v-for="(
                                      item, index
                                    ) in statusHttpJsonFields"
                                    :key="index"
                                    :field="item.key"
                                    :width="item.key == 'modify' ? 120 : 'auto'"
                                    :headerText="item[lang]"
                                    :template="
                                      item.hasTemplate ? item.key : null
                                    "
                                  ></e-column>
                                </e-columns>

                                <template v-slot:modify="{ data }">
                                  <div class="d-flex justify-content-between">
                                    <span
                                      class="e-icons e-edit-6"
                                      style="font-size: 17px"
                                      @click="editStatJsonItem(data)"
                                    ></span>
                                    <span
                                      class="e-icons e-delete-1 text-danger"
                                      style="font-size: 17px"
                                      @click="removeStatJsonItem(data)"
                                    ></span>
                                  </div>
                                </template>
                              </ejs-grid>
                            </b-container>
                          </b-collapse>

                          <b-collapse v-model="isMapClpsd2">
                            <b-card></b-card>
                          </b-collapse>

                          <b-collapse v-model="isMapClpsd3">
                            <b-card></b-card>
                          </b-collapse>
                        </div>

                        <div v-show="activeTab == 'telemetry'">
                          <b-collapse v-model="isMapClpsd1">
                            <b-container fluid class="p-0">
                              <div
                                class="
                                  fw-bolder
                                  text-center
                                  border
                                  rounded
                                  p-1
                                  mb-2
                                  text-light
                                "
                                style="
                                  background-color: #a5b4fc;
                                  width: 100%;
                                  border-color: #a5b4fc !important;
                                "
                              >
                                {{ mapTypeText[lang] }}
                              </div>
                              <div class="col-md-2 col-12 my-3">
                                <ejs-textbox
                                  :autocomplete="'off'"
                                  :key="'searchBox_155'"
                                  id="toolbar_search"
                                  :value="telemetry.limit"
                                  :placeholder="dict.telemetry.limit[lang]"
                                  :enableRtl="lang === 'per'"
                                  @input="
                                        (value) => {
                                          telemetry.limit = value.value;
                                        }
                                      "
                                ></ejs-textbox>
                              </div>
                              <ejs-grid
                                ref="telemetryJsonTable"
                                :width="'auto'"
                                :allowSorting="true"
                                :locale="lang"
                                :allowExcelExport="true"
                                :allowFiltering="true"
                                :editSettings="editSettings"
                                :allowPdfExport="true"
                                :toolbar="['Search']"
                                :toolbarClick="toolbarMagGettingClick"
                                :actionBegin="actionBegin"
                                :actionComplete="actionComplete"
                                :allowPaging="true"
                                :enableRtl="lang == 'per'"
                                :pageSettings="{
                                  pageSize: 5,
                                  pageSizes: [5, 10, 20, 50],
                                }"
                                :selectionSettings="selectionOptions"
                                :dataSource="telemetry.JSON.items"
                              >
                                <!--                                <template #toolbarTemplate>-->
                                <!--                                  <div>-->
                                <!--                                    -->
                                <!--                                  </div>-->
                                <!--                                </template>-->

                                <e-columns>
                                  <e-column
                                    width="50"
                                    type="checkbox"
                                  ></e-column>
                                  <e-column
                                    v-for="(item, index) in telemetryJsonFields"
                                    :key="index"
                                    :field="item.key"
                                    :width="item.key == 'modify' ? 120 : 'auto'"
                                    :headerText="item[lang]"
                                    :template="
                                      item.hasTemplate ? item.key : null
                                    "
                                  ></e-column>
                                </e-columns>

                                <template v-slot:telegram="{ data }">
                                  <i
                                    v-if="typeof data.telegram == 'object'"
                                    class="reset mx-2"
                                    @click="showVal(data)"
                                  >
                                    <font-awesome-icon icon="fas fa-equals" />
                                  </i>
                                </template>
                              </ejs-grid>
                            </b-container>
                          </b-collapse>
                        </div>
                        <div v-show="activeTab == 'event'">
                          <b-collapse v-model="isMapClpsd1">
                            <b-container fluid class="yekan p-0">
                              <div
                                class="
                                  fw-bolder
                                  text-center
                                  border
                                  rounded
                                  p-1
                                  mb-2
                                  text-light
                                "
                                style="
                                  background-color: #a5b4fc;
                                  width: 100%;
                                  border-color: #a5b4fc !important;
                                "
                              >
                                {{ mapTypeText[lang] }}
                              </div>
                              <div class="col-md-2 col-12 my-3">
                                <ejs-textbox
                                  :autocomplete="'off'"
                                  :key="'searchBox_155'"
                                  id="toolbar_search"
                                  :value="events.limit"
                                  :placeholder="dict.telemetry.limit[lang]"
                                  :enableRtl="lang === 'per'"
                                  @input="
                                        (value) => {
                                          events.limit = value.value;
                                        }
                                      "
                                ></ejs-textbox>
                              </div>
                              <ejs-grid
                                ref="EventsJsonTable"
                                :width="'auto'"
                                :allowSorting="true"
                                :locale="lang"
                                :allowExcelExport="true"
                                :allowFiltering="true"
                                :editSettings="editSettings"
                                :allowPdfExport="true"
                                :toolbar="['Search']"
                                :toolbarClick="toolbarMagGettingClick"
                                :actionBegin="actionBegin"
                                :actionComplete="actionComplete"
                                :allowPaging="true"
                                :enableRtl="lang == 'per'"
                                :pageSettings="{
                                  pageSize: 5,
                                  pageSizes: [5, 10, 20, 50],
                                }"
                                :selectionSettings="selectionOptions"
                                :dataSource="events.JSON.items"
                              >
                                <!--                                <template #toolbarTemplate>-->
                                <!--                                  -->
                                <!--                                </template>-->

                                <e-columns>
                                  <e-column
                                    width="50"
                                    type="checkbox"
                                  ></e-column>
                                  <e-column
                                    v-for="(item, index) in eventsJsonFields"
                                    :key="index"
                                    :field="item.key"
                                    :width="item.key == 'modify' ? 120 : 'auto'"
                                    :headerText="item[lang]"
                                    :template="
                                      item.hasTemplate ? item.key : null
                                    "
                                  ></e-column>
                                </e-columns>

                                <template v-slot:telegram="{ data }">
                                  <i
                                    v-if="typeof data.telegram == 'object'"
                                    class="reset mx-2"
                                    @click="showVal(data)"
                                  >
                                    <font-awesome-icon icon="fas fa-equals" />
                                  </i>
                                </template>
                              </ejs-grid>
                            </b-container>
                          </b-collapse>
                        </div>
                        <!--                      <div v-show="activeTab=='attributes'">-->
                        <!--                        <b-form>-->
                        <!--                          &lt;!&ndash; Input fields for Tab 4 &ndash;&gt;-->
                        <!--                        </b-form>-->
                        <!--                      </div>-->
                      </div>
                      <div :key="!registred" v-show="!registred">
                        <div id="for_val1" class="row mx-auto pt-4">
                          <div class="col-md-5 col-12">
                            <ejs-textbox
                              :autocomplete="'off'"
                              v-model="name"
                              :placeholder="dict.basic.name[lang]"
                              :enableRtl="lang === 'per'"
                              :required="true"
                              :minLength="3"
                            ></ejs-textbox>
                          </div>
                          <div class="col-md-5 col-12">
                            <ejs-dropdownlist
                              :dataSource="mapTypeList"
                              v-model="mapType"
                              :fields="{ text: 'text', value: 'value' }"
                              :placeholder="dict.basic.mapType[lang]"
                              :enableRtl="lang === 'per'"
                              :required="true"
                            ></ejs-dropdownlist>
                          </div>
                          <div
                            class="col-md my-3"
                            :class="{
                              'text-end': lang == 'eng',
                              'text-start': lang == 'per',
                            }"
                          >
                            <b-button class="w-auto" @click="registerCloud"
                            >{{ dict.general.nextBtn[lang] }}
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </transition-group>
                  </b-card>
                </b-card-body>
              </b-card>
            </b-col>
          </template>
        </b-row>
      </b-container>
    </div>
    <b-modal
      id="modal-edit-get-map-2"
      size="xl"
      centered
      :dir="dict.dir[lang]"
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      no-close-on-backdrop
      hide-header-close
      content-class="shadow"
      @shown="initModal"
      @cancel="resetModal"
      @ok="handleOk"
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]">
          <h5 v-if="parent2child.type == 'New'">
            {{ dict.titleTranslateAdd[lang] }}
          </h5>
          <h5 v-if="parent2child.type == 'Edit'">
            {{ dict.titleTranslateEdit[lang] }}
          </h5>
        </div>
      </template>
      <div :dir="dict.dir[lang]" id="for_firts_2">
        <div class="row mb-5">
          <div class="col-12 col-md-4 pt-3">
            <ejs-textbox
              floatLabelType="Auto"
              :autocomplete="'off'"
              :title="dict.contUrl[lang]"
              :placeholder="dict.contUrl[lang]"
              :enableRtl="lang === 'per'"
              v-model="data.url"
              :required="true"
            ></ejs-textbox>
          </div>
          <div class="col-12 col-md-4 pt-3">
            <ejs-dropdownlist
              floatLabelType="Auto"
              :title="dict.contType[lang]"
              :dataSource="intervalList"
              v-model="data.interval"
              :fields="{ text: lang, value: 'value' }"
              :placeholder="dict.contType[lang]"
              :enableRtl="lang === 'per'"
              :required="true"
            ></ejs-dropdownlist>
          </div>
          <div class="col-12 col-md-4 mt-4 form-check form-switch mt-1">
            <input
              v-model="data.uponChange.enable"
              class="form-check-input mx-md-auto"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
            />
            <label class="form-check-label mx-2" for="flexSwitchCheckChecked"
            >{{ dict.upon[lang] }}</label
            >
          </div>
        </div>

        <div class="my-2 row" v-if="data.uponChange.enable">
          <div class="col-md-6 col-12">
            <div class="d-flex align-items-center">
              <ejs-textbox
                :value="data.uponChange.data['name']"
                :placeholder="dict.pickData[lang]"
                :enableRtl="lang === 'per'"
                floatLabelType="Auto"
                :showClearButton="true"
                v-model="data.uponChange.data['name']"
              ></ejs-textbox>
              <b-button @click="pickData" class="btn-sm" variant="primary"><span class="e-icons e-edit-6 "></span>
              </b-button>

            </div>

          </div>

<!--          <div class="col-md-6 col-12 pt-3">-->
<!--            <ejs-textbox-->
<!--              :autocomplete="'off'"-->
<!--              v-model="data.uponChange.value"-->
<!--              :title="dict.namePH[lang]"-->
<!--              :placeholder="dict.namePH[lang]"-->
<!--              :enableRtl="lang === 'per'"-->
<!--            ></ejs-textbox>-->
<!--          </div>-->
        </div>
        <b-container fluid class="yekan p-0">
          <div
            class="fw-bolder text-center border rounded p-1 mb-2 text-light"
            style="
              background-color: #a5b4fc;
              width: 100%;
              border-color: #a5b4fc !important;
            "
          >
            <b-form-radio-group
              v-if="activeTab=='getMapping'"
              v-model="mappingType"
              class=" d-flex justify-content-around fw-bolder text-center border rounded p-1  text-dark"
              style="
                              background-color: #a5b4fc;
                              width: 100%;
                              border-color: #a5b4fc !important;
                            "
              name="security"
              v-validate="'required'"
            >
              <b-form-radio value="parameters" class="ml-5"
              ><span class="mx-2">{{ dict.parameters[lang] }}</span>
              </b-form-radio>
              <b-form-radio value="body" class="ml-5"
              ><span class="mx-2">{{ mapTypeText[lang] }}</span>
              </b-form-radio>
            </b-form-radio-group>
            <span v-if="activeTab!='getMapping'">{{ mapTypeText[lang] }}</span>
          </div>

          <div v-if="mappingType=='body'">
            <ejs-grid
              ref="newItem"
              :width="'auto'"
              :key="reRenMapping"
              :allowSorting="true"
              :allowExcelExport="true"
              :allowFiltering="true"
              :editSettings="editSettings"
              :allowPdfExport="true"
              :toolbar="[
              {
                text: dict.titleTranslateAdd[lang],
                id: 'add_mapGetting_sub',
                prefixIcon: 'e-icons e-add',
              },
              'Search',
            ]"
              :toolbarClick="toolbarMagGettingClick"
              :actionBegin="actionBegin"
              :actionComplete="actionComplete"
              :allowPaging="true"
              :enableRtl="lang == 'per'"
              :pageSettings="{ pageSize: 5, pageSizes: [5, 10, 20, 50] }"
              :selectionSettings="selectionOptions"
              :dataSource="mappingItems"
            >
              <e-columns>
                <e-column
                  v-for="(item, index) in mapSubJsonFields"
                  :key="index"
                  :field="item.key"
                  :width="item.key == 'modify' ? 120 : 'auto'"
                  :headerText="item[lang]"
                  :template="item.hasTemplate ? item.key : null"
                ></e-column>
              </e-columns>

              <template v-slot:modify="{ data }">
                <div class="d-flex justify-content-between">
                <span
                  class="e-icons e-edit-6"
                  style="font-size: 17px"
                  @click="editItem(data)"
                ></span>
                  <span
                    class="e-icons e-delete-1 text-danger"
                    style="font-size: 17px"
                    @click="removeMapGetItem(data)"
                  ></span>
                </div>
              </template>
              <template v-slot:name="{ data }">
                <p v-if="data.type == 'arrayOfObject'">-</p>
                <p v-if="data.type != 'arrayOfObject'">
                  {{ data.data.name }}
                </p>
              </template>
            </ejs-grid>
          </div>
          <div v-if="mappingType=='parameters'">
            <ejs-grid
              ref="newItem"
              :width="'auto'"
              :allowSorting="true"
              :allowExcelExport="true"
              :allowFiltering="true"
              :editSettings="editSettings"
              :allowPdfExport="true"
              :toolbar="[
              {
                text: dict.titleTranslateAdd[lang],
                id: 'add_param',
                prefixIcon: 'e-icons e-add',
              },
              'Search',
            ]"
              :toolbarClick="toolbarMagGettingClick"
              :actionBegin="actionBegin"
              :actionComplete="actionComplete"
              :allowPaging="true"
              :enableRtl="lang == 'per'"
              :pageSettings="{ pageSize: 5, pageSizes: [5, 10, 20, 50] }"
              :selectionSettings="selectionOptions"
              :dataSource="data.parameters"
            >
              <e-columns>
                <e-column
                  v-for="(item, index) in parametersFields"
                  :key="index"
                  :field="item.key"
                  :width="item.key == 'modify' ? 120 : 'auto'"
                  :headerText="item[lang]"
                  :template="item.hasTemplate ? item.key : null"
                ></e-column>
              </e-columns>
              <template v-slot:value="{ data }">
                <div>
                  <p v-if="typeof data.value =='string' ">{{ data.value }}</p>
                  <p v-if="typeof data.value !='string' ">{{ data.value["name"] }}</p>
                </div>
              </template>
              <template v-slot:modify="{ data }">
                <div class="d-flex justify-content-between">
                <span
                  class="e-icons e-edit-6"
                  style="font-size: 17px"
                  @click="editParam(data.id)"
                ></span>
                  <span
                    class="e-icons e-delete-1 text-danger"
                    style="font-size: 17px"
                    @click="removeParamm(data.id)"
                  ></span>
                </div>
              </template>
            </ejs-grid>
          </div>
        </b-container>
      </div>
    </b-modal>
    <b-modal
      id="modal-show-telegram"
      size="lg"
      hide-header-close
      content-class="shadow"
      title="telegram"
      hide-footer
      centered
    >
      <div>
        <p :key="index" v-for="(item, index) in telegramValue">
          <span class="text-bold mx-2">{{ index }}</span
          >:{{ item || null }}
        </p>
      </div>
    </b-modal>
    <b-modal
      id="modal-parameters-generation"
      size="lg"
      hide-header-close
      content-class="shadow"
      :title="dict.parameters[lang]"
      @ok="submitNewParam"
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
      :dir="dict.dir[lang]"
      centered
    >
      <div>
        <ejs-switch
          :enableRtl="lang == 'per'"
          :checked="newParam.isDynamic"
          @change="(val)=>{newParam.isDynamic=val.checked}"
        />
        <span class="mx-2">{{ dict.chooseData[lang] }}</span>
      </div>
      <div class="row mx-auto" id="param_validate">
        <div class="col-md-6 ocl-12">
          <ejs-textbox
            id="field3"
            floatLabelType="Auto"
            :autocomplete="'off'"
            :placeholder="dict.key[lang]"
            :enableRtl="lang === 'per'"
            v-model="newParam.key"
            :required="true"
          ></ejs-textbox>
        </div>
        <div class="col-md-6 col-12">
          <ejs-textbox
            id="field"
            :value="newParam.value"
            :placeholder="dict.value[lang]"
            :enableRtl="lang === 'per'"
            floatLabelType="Auto"
            :showClearButton="true"
            v-model="newParam.value"
          ></ejs-textbox>
        </div>
      </div>
    </b-modal>
    <!--    <set-get-post-mapping-json></set-get-post-mapping-json>-->
    <set-get-mapping-json :current-tab="activeTab" />
    <set-cont-http-server-status-json />
  </div>
</template>

<script>
/* eslint-disable */

import statJsonItemEditor from "./setContCloudModalStatusJson.vue";
import SetGetPostMappingJson from "@/components/setGetPostMappingJson.vue";
import SetGetMappingUrl from "@/components/setGetMappingUrl.vue";
import { v4 } from "uuid";
import SetContHttpServerStatusJson from "@/components/setContHttpServerStatusJson.vue";
import SetGetMappingJson from "@/components/setGetMappingJson.vue";
import { DialogUtility } from "@syncfusion/ej2-popups";
import { FormValidator, TextBox } from "@syncfusion/ej2-inputs";
import { DropDownList, MultiSelect } from "@syncfusion/ej2-dropdowns";
import Vue from "vue";
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";

export default {
  name: "masterEdit",
  components: {
    SetGetMappingJson,
    SetContHttpServerStatusJson,
    SetGetMappingUrl,
    SetGetPostMappingJson,
    statJsonItemEditor
  },
  data() {
    return {

      reRenMapping: 0,
      reRenGetmap: 0,
      reRenpostmap: 0,
      paramEdit: false,
      selectedIdToEdit: "",
      selectionOptions: {
        type: "Multiple",
        persistSelection: false,
        checkboxOnly: true
      },
      editSettings: {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
        mode: "Dialog"
      },
      reRender: 0,
      validator: null,
      validator1: null,
      validator3: null,
      activeTab: "basic",
      tabs: [
        { value: "basic", name: "basic" },
        { value: "security", name: "security" },
        { value: "getMapping", name: "getMapping" },
        { value: "postMapping", name: "postMapping" },
        { value: "status", name: "status" },
        // { value: "attribute", name: "attribute" },
        { value: "event", name: "event" },
        { value: "telemetry", name: "telemetry" }
      ],
      telegramValue: null,
      pageMode: "",
      urlModel: {},
      modalTitleUrl: "",
      modalTitle: "",
      id: "",
      name: "",
      parent: "",
      title: "",
      registred: false,
      mapType: "",
      mappingItems: [],
      security: {
        type: "basic",
        basic: {
          user1: "",
          pass1: "",
          url1: "",
          user2: "",
          pass2: "",
          url2: "",
          retries: "",
          timeout: ""
        },
        cert: {
          caCert: false,
          privateKey: false,
          cert: false
        },
        jwt: {
          user: "",
          pass: "",
          url1: ""
        },
        apiKey: {
          key: ""
        }
      },
      mappingPost: {
        type: "",
        JSON: {
          items: []
        }
      },
      dynamicComponentNames: [],
      mappingGet: {
        type: "",
        JSON: {
          items: []
        }
      },

      status: {
        type: "",
        JSON: {
          items: []
        }
      },

      telemetry: {
        type: "",
        limit: Number(10),
        JSON: {
          items: []
        }
      },
      events: {
        type: "",
        limit: Number(10),
        JSON: {
          items: []
        }
      },
      connection: {
        host: "",
        port: "",
        clientId: "",
        idPrefix: false,
        onOffStatus: false
      },

      reRen: false,
      submitted: false,
      successful: false,
      message: "",
      myErrors: {},
      mapTypeList: [
        { value: null, text: "select a type" },
        { value: "JSON", text: "Json Mapping" }
        // { value: "BYTE", text: "Byte Mapping", disabled: true },
        // { value: "CUST", text: "Custom mapping", disabled: true }
      ],

      mapTypeTextList: {
        JSON: { eng: "JSON Mapping", per: "نگاشت جیسون" },
        BYTE: { eng: "Byte Mapping", per: "نگاشت Byte" },
        CUST: { eng: "Custom mapping", per: "نگاشت شخصی سازی شده" },
        "null": { eng: "", per: "" }
      },


      mapPubJsonFields: [
        // { key: '_id', label: 'id', sortable: true},
        { key: "key", label: "data", sortable: true },
        { key: "key", label: "identifier", sortable: true },
        { key: "data.type", label: "type", sortable: true },
        { key: "data.name", label: "value", sortable: true },
        // { key: "interval", label: "interval", sortable: true },
        // { key: "uponChange", label: "upon change", sortable: true },

        { key: "modify", label: "" }
      ],

      mapSubJsonFields: [
        // { key: '_id', label: 'id', sortable: true},
        { key: "key", per: "کلید", eng: "Key", hasTemplate: false },
        { key: "type", per: "نوع", eng: "type", hasTemplate: false },
        { key: "name", per: "داده", eng: "data", hasTemplate: true },
        { key: "modify", per: "", eng: "", hasTemplate: true }
      ],
      parametersFields: [
        // { key: '_id', label: 'id', sortable: true},
        { key: "key", per: "کلید", eng: "Key", hasTemplate: false },
        { key: "value", per: "مقدار", eng: "value", hasTemplate: true },
        { key: "modify", per: "", eng: "", hasTemplate: true }
      ],

      statJsonFields: [
        // { key: '_id', label: 'id', sortable: true},
        { key: "url", per: "لینک", eng: "url", hasTemplate: false },
        { key: "interval", per: "فاصله زمانی", eng: "interval", hasTemplate: false },
        { key: "uponChange.value", per: "به محض تغییر", eng: "uponChange", hasTemplate: false },

        { key: "modify", per: "", eng: "", hasTemplate: true }
      ],

      statusHttpJsonFields: [
        // { key: '_id', label: 'id', sortable: true},
        { key: "condition", per: "شرط", eng: "condition", hasTemplate: false },
        { key: "data.name", per: "داده", eng: "data", hasTemplate: false },
        { key: "value", per: "مقدار", eng: "value", hasTemplate: false },

        { key: "modify", per: "", eng: "", hasTemplate: true }
      ],

      telemetryJsonFields: [
        // { key: '_id', label: 'id', sortable: true},
        { key: "time", per: "زمان", eng: "time", hasTemplate: false },
        { key: "telegram", per: "تلگرام", eng: "telegram", hasTemplate: true },
        { key: "source", per: "منبع", eng: "source", hasTemplate: false },
        { key: "type", per: "نوع", eng: "type", hasTemplate: false }
      ],

      eventsJsonFields: [
        // { key: '_id', label: 'id', sortable: true},
        { key: "time", per: "زمان", eng: "time", hasTemplate: false },
        { key: "message", per: "پیام", eng: "message", hasTemplate: false }
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [
        { value: 5, text: "5 rows" },
        { value: 10, text: "10 rows" },
        { value: 15, text: "15 rows" },
        { value: 100, text: "Show a lot" }
      ],
      sortBy: "topic",
      sortDesc: true,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      statTableOptions: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "topic",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      eventesTableOptions: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "topic",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      telemetryTableOptions: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "time",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },

      selectedFile: {
        CAfile: null,
        CFfile: null,
        PKfile: null
      },
      fileState: null,
      filePlaceholder: "Choose a file...",

      isSecClpsd1: false,
      isSecClpsd2: true,
      isSecClpsd3: false,

      isMapClpsd1: false,
      isMapClpsd2: false,
      isMapClpsd3: false,
      dict: {
        confirmTitle: { eng: "delete confirmation", per: "تایید حذف" },
        confirmText: {
          eng: "Are you sure to remove this record?",
          per: "آیا از حذف این رکورد اطمینان دارید؟"
        },
        chooseData: { eng: "choose from data", per: "انتخاب از داده ها" },
        key: { eng: "key", per: "کلید" },
        value: { eng: "value", per: "مقدار" },
        upon: { eng: "upon change", per: "به محض تغییر" },
        status: { eng: "status", per: "وضعیت اتصال" },
        pickData: { per: "انتخاب داده", eng: "Pick Data" },
        custom: { eng: "custom two step authentication", per: "احراز هویت سفارشی دو مرحله ای" },
        JWT: { eng: "Jwt Authentication", per: "احراز هویت Jwt" },
        apiKey: { eng: "api key", per: "کلید خصوصی" },
        contTitle: { eng: "title", per: "عنوان" },
        contUrl: { eng: "url", per: "لینک مورد نظر را وارد کنید" },
        contType: { eng: "type", per: "نوع دیتا مورد نظر را وارد کنید" },
        namePH: { eng: "Enter value", per: "مقدار موردنظر را وارد کنید" },
        contTitlePH: { eng: "Title", per: "عنوان را وارد کنید" },
        titleTranslateAdd: { eng: "New Item", per: "افزودن آیتم" },
        titleTranslateEdit: { eng: "Edit Item", per: "ویرایش آیتم" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        dir: { eng: "ltr", per: "rtl" },
        transition: { eng: "slide-fade-ltr", per: "slide-fade-rtl" },
        user: { eng: "user", per: "کاربر" },
        pass: { eng: "password", per: "رمز عبور" },
        url: { eng: "url", per: "لینک" },
        parameters: { eng: "parameters", per: "پارامترها" },
        tabs: {
          basic: { eng: "Basic Information", per: "اطلاعات پایه" },
          security: { eng: "Security", per: "امنیت" },
          getMapping: { eng: "Get Mapping", per: "دریافت اطلاعات" },
          postMapping: { eng: "Post Mapping", per: "ارسال اطلاعات" },
          status: { eng: "Status", per: "وضعیت" },
          attribute: { eng: "Attribute", per: "ارسال اطلاعات" },
          event: { eng: "Events", per: "گزارشات سیستمی" },
          telemetry: { eng: "Telemetry", per: "گزارشات داده ای" }
        },
        basic: {
          name: { eng: "name", per: "نام" },
          mapType: { eng: "mapType", per: "نوع داده" },
          host: { eng: "base url", per: "آدرس اصلی" },
          status: { eng: "status", per: "وضعیت" }
        },
        security: {
          user1: { per: "نام کاربری اول", eng: "user1" },
          pass1: { per: "رمز عبور اول", eng: "pass1" },
          url1: { per: "ادرس اول", eng: "url1" },
          user2: { per: "نام کاربری دوم", eng: "user2" },
          pass2: { per: "رمز عبور دوم", eng: "pass2" },
          url2: { per: "ادرس دوم", eng: "url2" },
          retries: { per: "تعداد دفعات نکرار", eng: "retries" },
          timeout: { per: "فاصله زمانی بین تکرارها", eng: "timeout (per second)" }
        },
        general: {
          nextBtn: { "per": "ادامه", "eng": "Next" },
          Add: { "per": "ایجاد اتصال Http server", "eng": "Add http server Connection" },
          Edit: { "per": "ویرایش اتصال Http server", "eng": "Edit http server Connection" },
          perPage: { per: "تعداد در هر صفحه", eng: "per page" },
          searchBox: { per: "جستجو", eng: "search" }
        },
        telemetry: {
          limit: { per: "حداکثر تعداد پیام های ذخیره شده", eng: "limit" }
        }
      },
      intervalList: [
        { per: "یک مورد را انتخاب کنید", eng: "Select One", value: null },
        { per: "هیچکدام", eng: "none", value: "none" },
        { per: "30 ثانیه", eng: "30 sec", value: "30_s" },
        { per: "1 دقیقه", eng: "1 min", value: "1_m" },
        { per: "3 دقیقه", eng: "3 min", value: "3_m" },
        { per: "5 دقیقه", eng: "5 min", value: "5_m" },
        { per: "15 دقیقه", eng: "15 min", value: "15_m" },
        { per: "30 دقیقه", eng: "30 min", value: "30_m" },
        { per: "45 دقیقه", eng: "45 min", value: "45_m" },
        { per: "1 ساعت", eng: "1 hour", value: "1_h" }
      ],
      renderTime: 0,
      renderTable: 0,
      mappingType: "body",
      data: {
        url: "",
        interval: "none",
        uponChange: {
          enable: false,
          value: "",
          data: {}
        },
        enableData: false,
        parameters: [],
        items: []
      },
      newParam: { id: v4(), key: null, value: "", isDynamic: false }
    };
  },
  watch: {
    lang: {
      handler(val) {
        this.renderTime = this.renderTime + 1;
      }, deep: true
    },
    secType(newVal) {
      this.isSecClpsd1 = newVal === "apiKey";
      this.isSecClpsd2 = newVal === "basic";
      this.isSecClpsd3 = newVal === "jwt";
    },
    mapType(newVal) {
      this.mappingPost.type = newVal;
      this.mappingGet.type = newVal;
      this.status.type = newVal;

      this.isMapClpsd1 = newVal === "JSON";
      this.isMapClpsd2 = newVal === "BYTE";
      this.isMapClpsd3 = newVal === "CUST";
    },
    child2parent(newValue, oldValue) {

      if ((newValue.task === "pickData") && (newValue.to === "uponChange")) {
        this.data.uponChange.data = newValue.item;
      }

      if ((newValue.task === "pickData") && (newValue.to === "parameters")) {
        this.newParam.value = newValue.item;
      }

      if (newValue.task == "addHttpServerStat") {
        if (newValue.type == "New") {
          this.status.JSON.items.push(newValue.item);
          this.$root.$emit("bv::hide::modal", "modal-httpServer-status-json-item");
        } else {
          if (newValue.type == "Edit") {
            let founded = this.status.JSON.items.find(x => x._id == newValue.item._id);
            let index = this.status.JSON.items.indexOf(founded);
            this.status.JSON.items[index] = newValue.item;
            this.$refs.statusJsonTable.ej2Instances.refresh();
          }
        }
      }


      if ((newValue.task === "editMapJson") && (newValue.to.type === "cloudPub")) {
        var map = newValue.to.map;
        // console.log("PPP", newValue);
        if (newValue.type === "New") {
          if (this.mappingPost[map].items) {
            this.mappingPost[map].items.push(JSON.parse(JSON.stringify(newValue.item)));
          } else {
            this.mappingPost[map]["items"] = [JSON.parse(JSON.stringify(newValue.item))];
          }
        } else if (newValue.type === "Edit") {
          var indx = this.mappingPost[map].items.findIndex(x => x._id === newValue.item._id);
          if (indx != -1) {
            this.mappingPost[map].items[indx] = JSON.parse(JSON.stringify(newValue.item));
          }
        }
        this.$refs.mapPubJsonTable.refresh();
        // console.log(newValue.task);
      } else if ((newValue.task === "editMapJson/identify") && (newValue.to.type === "httpServer")) {
        var map = newValue.to.map;
        if (newValue.type === "New") {
          if (this.mappingGet[map].items) {
            this.mappingItems.push(JSON.parse(JSON.stringify(newValue.item)));
            this.reRenMapping += 1;
            // this.mappingGet[map].items.push(JSON.parse(JSON.stringify(newValue.item)));
          } else {
            // this.mappingGet[map]["items"] = [JSON.parse(JSON.stringify(newValue.item))];
          }
        } else if (newValue.type === "Edit") {
          // this.data = this.mappingGet[map].items;
          let founded = this.mappingItems.find(x => x._id == newValue.item._id);
          let indexFounded = this.mappingItems.indexOf(founded);
          if (indexFounded > -1) {
            this.mappingItems[indexFounded] = newValue.item;
            this.reRenMapping += 1;
          }
          this.reRenMapping += 1;
        }
        this.$refs.newItem.ej2Instances.refresh();
      } else if (newValue.task === "editStatJson") {
        if (newValue.type === "New") {
          if (this.status.JSON.items) {
            this.status.JSON.items.push(JSON.parse(JSON.stringify(newValue.item)));
          } else {
            this.status.JSON["items"] = [JSON.parse(JSON.stringify(newValue.item))];
          }
        } else if (newValue.type === "Edit") {
          var indx = this.status.JSON.items.findIndex(x => x._id === newValue.item._id);
          if (indx != -1) {
            this.status.JSON.items[indx] = JSON.parse(JSON.stringify(newValue.item));
          }
        }
        this.$refs.statusJsonTable.refresh();
      }
    },
    parent2child(newValue, moldValue) {
      this.modalTitle = newValue.type + " Item";
      this.modalTitleUrl = newValue.type + " Item";
      if (newValue.type == "Edit" && newValue.task == "editMapJson") {
        this.mappingItems = newValue.item.items;
        this.data = newValue.item;
      }

    },
    activeTab: {
      handler(val) {
        if (val != "getMapping")
          this.mappingType = "body";
      }
    }
  },
  computed: {
    type() {
      return this.$store.state.chpge.type;
    },
    db() {
      return this.$store.state.chpge.db;
    },
    index() {
      return this.$store.state.chpge.index;
    },
    secType() {
      return this.security.type;
    },
    mapPubType() {
      return this.mappingPost.type;
    },
    mapSubType() {
      return this.mappingGet.type;
    },
    statType() {
      return this.status.type;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    mapTypeText() {
      if (this.registred)
        return this.mapTypeTextList[this.mapType];
      else return "null";
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    toolbarGenerator() {
      return [
        {
          text: this.dict.titleTranslateAdd[this.lang],
          id: "add_mapGetting",
          prefixIcon: "e-icons e-add"
        },
        "Search"
      ];
    }
  },
  methods: {
    editParam(id) {
      let founded = this.data.parameters.find(item => item.id == id);
      this.newParam = founded;
      this.paramEdit = true;
      this.$bvModal.show("modal-parameters-generation");
    },
    removeParamm(id) {
      let founded = this.data.parameters.find(item => item.id == id);
      this.data.parameters.splice(this.data.parameters.indexOf(founded), 1);
    },
    submitNewParam($bv) {
      $bv.preventDefault();
      const isValid = new FormValidator("#param_validate").validate();
      if (this.paramEdit == false) {
        if (isValid) {
          this.data.parameters.push(this.newParam);
          this.data.enableData = false;
          this.newParam = { id: v4(), key: null, value: "",isDynamic:false };
          this.$nextTick(() => {
            this.$bvModal.hide("modal-parameters-generation");
          });
        }
      } else {
        let found = this.data.parameters.find(item => item.id == this.newParam.id);
        found = this.newParam;
        this.paramEdit = false;
        this.$nextTick(() => {
          this.$bvModal.hide("modal-parameters-generation");
        });
      }
    },
    pickData() {
      var filterParents = [];
      // var filterParents = ['empty'];
      // if (this.to.type==='masterPub'||this.to.type==='cloudPub')
      //     filterParents = [];


      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "uponChange",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: filterParents,
          archive: null,
          withChild: false,
          page: 1,
          perPage: 5000
        }
      });
      this.$root.$emit("bv::show::modal", "modal-pick-data");
    },
    pickDataForParam() {
      var filterParents = [];
      // var filterParents = ['empty'];
      // if (this.to.type==='masterPub'||this.to.type==='cloudPub')
      //     filterParents = [];


      this.$store.dispatch("chpge/parent2child", {
        task: "pickData",
        from: "parameters",
        filter: {
          dataType: ["!Number", "!Complex", "!Boolean", "String", "Object"],
          usedBy: [],
          parents: filterParents,
          archive: null,
          withChild: false,
          page: 1,
          perPage: 5000,
          objDataType: ["String"]
        }
      });
      this.$root.$emit("bv::show::modal", "modal-pick-data");

    },
    actionBegin(args) {
      if (args.requestType != "paging" && args.requestType != "refresh")
        args.cancel = true;
    },
    actionComplete(args) {
      if (args.requestType != "refresh")
        args.cancel = true;
    },
    toolbarMagGettingClick(args) {
      if (args.item.id == "add_mapGetting") {
        this.openNewUrl();
      } else if (args.item.id == "add_mapGetting_sub") {
        this.addItemLev2();
      } else if (args.item.id == "add_status") {
        this.addStatJsonItem();
      } else if (args.item.id == "add_param") {
        this.newParam = { id: v4(), key: null, value: "", isDynamic: false };
        this.$bvModal.show("modal-parameters-generation");
      }
    },
    showVal(val) {
      this.$bvModal.show("modal-show-telegram");
      this.telegramValue = val.telegram;
    },
    checkFormat(e) {
      const pattern = /^(?!\/).*/;
      if (pattern.test(e))
        return e;
    },
    resetModal() {
      this.mappingItems = [];
      this.data = {
        url: "",
        interval: "none",
        uponChange: {
          enable: false,
          value: "",
          data: {}
        },
        enableData: false,
        parameters: [],
        items: []
      };
      this.urlModel = {};
      this.$root.$emit("bv::hide::modal", "modal-edit-get-map-2");
    },
    handleOk($bvModal) {
      let a = [];
      $bvModal.preventDefault();
      const isValid = new FormValidator("#for_firts_2").validate();
      if (isValid) {
        if (this.checkUniqueUrl()) {
          if (this.pageMode != "Edit") {
            if (this.activeTab != "postMapping") {
              this.mappingGet.JSON.items.push({
                _id: v4(),
                url: this.data.url,
                interval: this.data.interval,
                uponChange: this.data.uponChange,
                items: this.mappingItems,
                parameters: this.data.parameters,
                enableData: this.data.enableData
              });
              this.data = {
                url: "",
                interval: "none",
                uponChange: {
                  enable: false,
                  value: "",
                  data: {}
                },
                enableData: false,
                parameters: [],
                items: []
              };

              this.$nextTick(() => {
                this.$root.$emit("bv::hide::modal", "modal-edit-get-map-2");
              });
              this.resetModal();
              this.$refs.getMapping.ej2Instances.refresh();
            } else if (this.activeTab == "postMapping") {
              this.mappingPost.JSON.items.push({
                _id: v4(),
                url: this.data.url,
                interval: this.data.interval,
                uponChange: this.data.uponChange,
                items: this.mappingItems
              });
              this.data = {
                url: "",
                interval: "none",
                uponChange: {
                  enable: false,
                  value: "",
                  data: {}
                },
                enableData: false,
                parameters: [],
                items: []
              };
              this.$nextTick(() => {
                this.$root.$emit("bv::hide::modal", "modal-edit-get-map-2");
              });
              this.$refs.mapSubJsonTable.ej2Instances.refresh();
            }
          } else {
            if (this.activeTab != "postMapping") {
              a = this.mappingGet.JSON.items.find((item) => item._id == this.selectedIdToEdit);
              let indexItem = this.mappingGet.JSON.items.indexOf(a);
              if (indexItem > -1)
                this.mappingGet.JSON.items[indexItem] =
                  {
                    _id: this.data._id,
                    url: this.data.url,
                    interval: this.data.interval,
                    uponChange: this.data.uponChange,
                    parameters: this.data.parameters,
                    enableData: this.data.enableData,
                    items: this.mappingItems
                  };

              this.reRenGetmap += 1;
              this.$nextTick(() => {
                this.$root.$emit("bv::hide::modal", "modal-edit-get-map-2");
              });


            } else {
              a = this.mappingPost.JSON.items.find((item) => item._id == this.selectedIdToEdit);
              let indexItem = this.mappingPost.JSON.items.indexOf(a);
              if (indexItem > -1)
                this.mappingPost.JSON.items[indexItem] = {
                  _id: this.data._id,
                  url: this.data.url,
                  interval: this.data.interval,
                  uponChange: this.data.uponChange,
                  parameters: this.data.parameters,
                  enableData: this.data.enableData,
                  items: this.mappingItems
                };
              this.reRenpostmap += 1;
              this.$nextTick(() => {
                this.$root.$emit("bv::hide::modal", "modal-edit-get-map-2");
              });
            }
            if (this.activeTab != "postMapping")
              this.$refs.getMapping.ej2Instances.refresh();
            else this.$refs.mapSubJsonTable.ej2Instances.refresh();
          }
        } else $bvModal.preventDefault();

        this.renderTable += 1;
        // this.resetModal();
        // this.data = {
        //   url: "",
        //   interval: "none",
        //   uponChange: {
        //     enable: false,
        //     value: "",
        //     data: {}
        //   },
        //   enableData: false,
        //   parameters: [],
        //   items: []
        // };

      }
    },
    checkUniqueUrl() {
      let urlExists = this.mappingGet.JSON.items.some(item => item.url === this.data.url);
      if (urlExists && this.pageMode != "Edit") {
        alert("The URL already exists in the list. Please change it.");
        return false;
      } else return true;

    },
    initModal() {
      this.reRen = true;
      this.title = this.type + " http server Connection";
      this.modalTitle = this.type + " Item";
      if (this.type === "Add") {
        this.parent = { ptype: this.db.ptype, pid: this.db.pid };
      } else if (this.type === "Edit") {
        // this.topic = this.db.topic;
        this.registred = true;
        this.id = this.db.id;
        this.name = this.db.name;
        this.mapType = this.db.mapType;
        this.parent = this.db.parent;
        this.security = this.db.security;
        this.mappingPost = this.db.mappingPost;
        this.mappingGet = this.db.mappingGet;
        this.status = this.db.status;
        this.connection = this.db.connection;
        this.telemetry = this.db.telemetry;
        this.events = this.db.events;

        this.telemetryTableOptions.totalRows = this.telemetry.JSON.items.length;
        this.eventesTableOptions.totalRows = this.events.JSON.items.length;
        this.$refs.getMapping.ej2Instances.refresh();
        this.$refs.mapSubJsonTable.ej2Instances.refresh();
        this.$refs.telemetryJsonTable.ej2Instances.refresh();
        this.$refs.EventsJsonTable.ej2Instances.refresh();

      }
      this.initMe();

    },
    onReset() {
      // this.topic = null
      this.id = null,
        this.type = "",
        this.mapType = "",
        this.name = "",
        this.parent = {},
        this.security = {},
        this.mappingPost = {},
        this.mappingGet = {},
        this.status = {},
        this.connection = {},
        this.telemetry = {},
        this.events = {},

        this.$store.dispatch("chpge/changePage", "controllerSettings");
    },
    registerCloud() {
      this.message = "";
      this.submitted = true;
      const isValid = new FormValidator("#for_val1").validate();
      if (isValid) {
        var data = {
          type: "httpServer",
          name: this.name,
          parent: this.parent,
          mapType: this.mapType
        };
        this.$store.dispatch("data/createHttpServer", data).then(
          data => {
            this.submitted = false;
            this.successful = false;

            this.registred = true;
            this.id = data.message._id;
            this.name = data.message.name;
            this.mapType = data.message.mapType;
            this.parent = data.message.parent;
            this.security = data.message.security;
            this.mappingPost = data.message.mappingPost;
            this.mappingGet = data.message.mappingGet;
            this.status = data.message.status;
            this.connection = data.message.connection;
            this.telemetry = data.message.telemetry;
            this.events = data.message.events;


            this.telemetryTableOptions.totalRows = this.telemetry.JSON.items.length;
            this.eventesTableOptions.totalRows = this.events.JSON.items.length;

          },
          error => {
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            alert(this.message);
          }
        );
      }


    },
    handleRegister() {
      this.message = "";
      this.submitted = true;
      const isValid = new FormValidator("#for_firts").validate();
      if (isValid) {
        if ((this.security.type !== "cert") || (this.security.type === "cert" && this.security.cert.caCert && this.security.cert.cert && this.security.cert.privateKey)) {
          var data = {
            id: this.id,
            type: "httpServer",
            name: this.name,
            parent: this.parent,
            mapType: this.mapType,
            security: this.security,
            connection: this.connection,
            mappingPost: this.mappingPost,
            mappingGet: this.mappingGet,
            status: this.status,
            telemetry: {
              type: this.telemetry.type,
              limit: this.telemetry.limit || 10,
              JSON: this.telemetry.JSON
            },
            events: {
              type: this.events.type,
              limit: this.events.limit || 10,
              JSON: this.events.JSON
            }
          };

          this.$store.dispatch("data/editHttpServer", data).then(
            data => {
              this.message = data.message;
              this.successful = true;
              //alert(this.message)
              this.getContConf();
              this.$store.dispatch("chpge/changePage", "controllerSettings");
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              alert(this.message);
            }
          );
        }
      }
    },
    getContConf() {
      this.$store.dispatch("data/getContConf").then(
        data => {
          // console.log("master: ", JSON.stringify(data));
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    downloadFile(type) {
      this.$store.dispatch("data/getFileCloud", { id: this.id, type: type })

        .then((response) => {
          // Create a download link for the received file
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;

          let fileName = "";
          if (type === "CA")
            fileName = "ca.crt";
          else if (type === "PK")
            fileName = "client.key";
          else if (type === "CF")
            fileName = "client.crt";

          link.setAttribute("download", fileName);
          document.body.appendChild(link);

          // Click the download link to initiate the file download
          link.click();

          // Clean up the temporary download link
          document.body.removeChild(link);
        })
        .catch(error => {
          alert("Error downloading file:", error);
        });
    },

    uploadFile(file) {
      this.selectedFile[file] = this.$refs[file].files[0];
    },
    submitFile(type) {
      const formData = new FormData();

      formData.append("id", this.id);
      formData.append("type", type);
      formData.append("file", this.selectedFile[type]);


      this.$store.dispatch("data/uploadFileCloud", formData).then(
        Data => {
          this.successful = true;

          if (type === "CAfile")
            this.security.cert.caCert = true;
          else if (type === "CFfile")
            this.security.cert.cert = true;
          else if (type === "PKfile")
            this.security.cert.privateKey = true;

          this.message = Data.message;
          // this.$store.dispatch('chpge/child2parent', {task: 'updateCB', data: this.updateCBCnt+1})

        },

        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          // alert(this.message)
        }
      );
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    statusTableOnFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.statTableOptions.totalRows = filteredItems.length;
      this.statTableOptions.currentPage = 1;
    },

    telemetryTableOnFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.telemetryTableOptions.totalRows = filteredItems.length;
      this.telemetryTableOptions.currentPage = 1;
    },

    editMapPubJsonItem(item) {
      this.data = {
        url: item.url,
        interval: item.interval,
        uponChange: item.uponChange,
        enableData: item.enableData,
        parameters: item.parameters,
        items: item.items
      };
      this.selectedIdToEdit = item._id;
      var channels = [];
      this.$store.dispatch("chpge/parent2child", {
        task: "editMapJson",
        from: { type: "cloudSub", map: "JSON", id: this.id, extra: channels },
        type: "Edit",
        item: item
      });
      this.$root.$emit("bv::show::modal", "modal-edit-get-map-2");
      this.pageMode = "Edit";
      //do3a
    },

    openNewUrl() {
      this.data = {
        url: "",
        interval: "none",
        uponChange: {
          enable: false,
          value: "",
          data: {}
        },
        enableData: false,
        parameters: [],
        items: []
      };
      var channels = [];
      this.$store.dispatch("chpge/parent2child", {
        task: "editMapJson",
        from: { type: "cloudSub", map: "JSON", id: this.id, extra: channels },
        type: "New",
        item: {}
      });
      this.$root.$emit("bv::show::modal", "modal-edit-get-map-2");
      this.pageMode = "New";
    },

    removeMapPubJsonItem: function(item, event) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.confirmTitle[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.confirmText[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            if (this.activeTab != "postMapping") {
              var indx = this.mappingGet.JSON.items.findIndex(x => x._id === item._id);
              if (indx != -1) {
                this.mappingGet.JSON.items.splice(indx, 1);
              }
            } else if (this.activeTab == "postMapping")
              var indx = this.mappingPost.JSON.items.findIndex(x => x._id === item._id);
            if (indx != -1) {
              this.mappingPost.JSON.items.splice(indx, 1);
            }
            if (this.activeTab != "postMapping")
              this.$refs.getMapping.ej2Instances.refresh();
            else this.$refs.mapSubJsonTable.ej2Instances.refresh();
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }

    },
    editItem(item) {
      var channels = [];
      this.$store.dispatch("chpge/parent2child", {
        task: "editGetMappingMain",
        from: { type: "httpServer", map: "JSON", id: this.id, extra: channels },
        type: "Edit",
        item: item
      });
      this.$root.$emit("bv::show::modal", "modal-get-mapping");
    },

    addItemLev2() {
      var channels = [];
      this.$store.dispatch("chpge/parent2child", {
        task: "editGetMappingMain",
        from: { type: "httpServer", map: "JSON", id: this.id, extra: channels },
        type: "New",
        item: {}
      });
      this.$root.$emit("bv::show::modal", "modal-get-mapping");
    },

    removeMapGetItem: function(item, event) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.confirmTitle[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.confirmText[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            this.mappingItems.splice(this.mappingItems.indexOf(item._id), 1);
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }

    },
    addStatJsonItem() {
      this.$store.dispatch("chpge/parent2child", { task: "editStatusJson", type: "New", item: {} });
      this.$root.$emit("bv::show::modal", "modal-httpServer-status-json-item");
    },
    editStatJsonItem(item) {
      this.$store.dispatch("chpge/parent2child", { task: "editStatusJson", type: "Edit", item: item });

      this.$root.$emit("bv::show::modal", "modal-httpServer-status-json-item");
    },
    removeStatJsonItem: function(item, event) {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete it.")
        .then(value => {
          if (value === true) {
            var indx = this.status.JSON.items.findIndex(x => x._id === item._id);
            if (indx != -1) {
              this.status.JSON.items.splice(indx, 1);
            }
          }
        })
        .catch(err => {
          // An error occurred
        });
    },
    initMe() {
      // this.validator3 = new FormValidator("#for_firts_2");
      this.reRen = !this.reRen;
    }
  },
  mounted() {
    this.initModal();
    if (this.registred == false) {
      this.reRender += 1;
    }

  },
  provide: {
    grid: [Page, Sort, Toolbar, Edit, Search]
  }
};
</script>

<style scoped>
.apply {
  color: #198754;
  cursor: pointer;
}

.apply :hover {
  color: #9b9b9b;
}

.reset {
  color: #dc3545;
  cursor: pointer;
}

.reset :hover {
  color: #9b9b9b;
}

.download-link {
  margin-bottom: 10px;
}
</style>
