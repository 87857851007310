<template>
  <div>
    <b-modal
      id="modal-marker-layer-new"
      size="xl"
      centered
      header-border-variant="primary"
      header-class="my-modal-header "
      no-close-on-esc
      no-close-on-backdrop
      @shown="initModal"
      @ok="editLayer"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      :dir="dict.dir[lang]"
      @hidden="resetModal"
    >
      <div class="card mt-2 mx-auto">
        <div class="col-md-10 m-3">
          <ejs-textbox
            :placeholder="dict.title[lang]"
            :enableRtl="lang === 'per'"
            floatLabelType="Auto"
            :showClearButton="true"
            :value="layerInfo.title"
          ></ejs-textbox>
        </div>
        <div class="border px-0">
          <ejs-toolbar :items="toolbarForObj"></ejs-toolbar>
          <div
            class="row border-bottom border-top mx-0 py-1"
            v-for="(data1, index) in items"
            :key="index"
            style="font-size: 13px"
          >
            <div class="col-md-8 col-12 pt-3">
              <div class="row">
                <div class="col-md-4 mt-4">
                  <ejs-switch
                    :value="data1.isCategory"
                    :checked="data1.isCategory"
                    @change="(val)=>{data1.isCategory=val.checked}"
                  />
                  <span class="mx-2" style="font-size: 12px">{{ dict.dataGp[lang] }}</span>
                </div>
                <div v-if="!data1.isCategory" class="d-flex col-md align-items-center">
                  <ejs-textbox
                    :placeholder="dict.pickData[lang]"
                    :enableRtl="lang === 'per'"
                    floatLabelType="Auto"
                    :showClearButton="true"
                    :value="data1.data['name']"
                  ></ejs-textbox>
                  <b-button
                    @click="pickData(data1.id)"
                    class="btn-sm"
                    variant="primary"
                  ><span class="e-icons e-edit-6"></span>
                  </b-button>
                </div>
                <div v-show="data1.isCategory" class="col-md">
                  <ejs-dropdownlist
                    :dataSource="categories"
                    :fields="{ text: lang, value: 'value' }"
                    :placeholder="dict.cats[lang]"
                    :enableRtl="lang === 'per'"
                    floatLabelType="Auto"
                    :value="data1.data"
                    @change="
                  (val) => {
                    data1.data = val.value;
                  }
                "
                  ></ejs-dropdownlist>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-12 pt-3">
              <ejs-dropdownlist
                :dataSource="typeList"
                :fields="{ text: lang, value: 'value' }"
                :placeholder="dict.dataType[lang]"
                :enableRtl="lang === 'per'"
                floatLabelType="Auto"
                :value="data1.typeId"
                @change="
                  (val) => {
                    data1.typeId = val.value;
                  }
                "
              ></ejs-dropdownlist>
            </div>
            <div class="col-md-1 col-12 pt-4">
              <span
                class="e-icons e-delete-1 text-danger"
                style="font-size: 17px"
                @click="deleteRow(data1)"
              ></span>
            </div>
          </div>
          <ejs-pager
            style="border: unset !important"
            :pageSize="5"
            v-model="valCurPage"
            :pageSizes="[5, 10, 20, 50]"
            :locale="lang"
            :enableRtl="lang == 'per'"
            @click="getValValue"
            :totalRecordsCount="items.length"
          ></ejs-pager>
        </div>
      </div>
    </b-modal>
    <data-picker-modal />
  </div>
</template>
<script>
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";
import { ToolbarComponent } from "@syncfusion/ej2-vue-navigations";
import { v4 } from "uuid";
import DataPickerModal from "@/components/dataManagerModalDataPicker.vue";

export default {
  name: "markerLayer",
  components: {
    DataPickerModal,
    "ejs-toolbar": ToolbarComponent
  },
  data() {
    return {
      categories: [],
      typeList: [],
      items: [],
      valPerPage: 5,
      currentId: 0,
      valCurPage: 1,
      dict: {
        dataGp: { eng: "choose category data", per: " انتخاب داده های دسته بندی شده" },
        cats: { eng: "categories", per: " دسته بندی ها" },
        dataType: { eng: "marker type", per: "نوع مارکر" },
        pickData: { per: "انتخاب داده", eng: "Pick Data" },
        title: { per: "عنوان لایه", eng: "layer title" },
        dir: { eng: "ltr", per: "rtl" },
        cancel: { eng: "cancel", per: "لغو" },
        ok: { eng: "ok", per: "تایید" },
        addValue: { eng: "New marker", per: "مارکر جدید" }
      },
      selectedId: "",
      layerInfo: {
        id: "",
        title: "",
        type: "marker",
        map: {
          center: [32.6524, 51.6746],
          minZoom: 12,
          maxZoom: 20,
          maxNativeZoom: 10,
          tileId: 1,
          viewZoom: 14
        }
      }
    };
  },
  methods: {
    getValValue(e) {
      // if (e.newProp.hasOwnProperty("pageSize"))
      //   this.valPerPage = e.newProp.pageSize;

      this.valCurPage = e.currentPage;
    },
    deleteRow(e) {
      if (this.items.indexOf(e) > -1)
        this.items.splice(this.items.indexOf(e), 1);
    },
    initModal() {
      this.getAllMarkerType();
      this.getAllCategories();
    },
    resetModal() {
    },
    newItem() {
      this.items.push({
        id: v4(),
        typeId: "",
        isCategory: false,
        data: ""
      });
    },
    pickData(id) {
      this.selectedId = id;
      this.$store.dispatch("chpge/parent2child", {
        task: "pickedData",
        from: "markerLayer",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: [],
          archive: null,
          perPage: 5000,
          page: 1,
          withChild: true
        }
      });
      this.$root.$emit("bv::show::modal", "modal-data-picker");
    },
    editLayer() {
      this.$store
        .dispatch("data/editFloor", {
          _id: this.layerInfo.id,
          title: this.layerInfo.title,
          details: {
            map: this.layerInfo.map,
            markers: this.items
          }
        })
        .then((res) => {
          this.$store.dispatch("chpge/updatePage", this.updatePage + 1);
          this.$nextTick(() => {
            this.$bvModal.hide("modal-marker-layer-new");
          });
        });
    },
    getAllMarkerType() {
      this.$store.dispatch("data/getAllMarkerTypes").then((res) => {
        this.typeList = [];
        res.forEach((item) => {
          this.typeList.push({
            per: item.name,
            eng: item.name,
            value: item._id
          });
        });

      });
    },
    getAllCategories() {
      this.$store.dispatch("data/getAllCategoryforList").then((data) => {
          this.categories = [];
          data.forEach((item) => {
            this.categories.push({
              per: item.name,
              eng: item.name,
              value: item._id
            });
          });
        }
      );
    }
  },
  computed: {
    toolbarForObj() {
      return [
        {
          prefixIcon: "e-icons e-plus-3",
          text: this.dict.addValue[this.lang],
          id: "add",
          click: () => this.newItem()
        }
      ];
    },
    valuesGenerator() {
      const start = (this.valCurPage - 1) * this.valPerPage;
      const end = start + this.valPerPage;
      return this.items.slice(start, end);
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    child2parent() {
      return this.$store.state.chpge.child2child;
    }
  },
  watch: {
    parent2child(newValue) {
      if (newValue.task == "editMarker" && newValue.from == "mapManager") {
        this.layerInfo.id = newValue.map._id;
        this.items = newValue.map.details.markers;
        this.layerInfo.title = newValue.map.title;
        this.layerInfo.type = newValue.map.type;
        // this.layerInfo.map = newValue.map.details.map;
      }
    },
    child2parent(newValue) {
      if (newValue.to == "markerLayer" && newValue.task == "pickedData") {
        this.items.find((item) => item.id == this.selectedId).data = newValue.item;
      }
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  }
};
</script>

<style scoped></style>
