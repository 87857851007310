<template>
  <div
    style="overflow-x: hidden"
    :dir="dict.dir[lang]"
    class="container-fluid h-100 p-0 bg-none"
    :class="lang === 'per' ? 'text-end' : 'text-start'"
  >
    <div class="p-3 m-0 bg-page-title d-flex">
      <div
        :class="
          dict.dir[lang] === 'rtl'
            ? 'page-title-icon-rtl'
            : 'page-title-icon-ltr'
        "
      >
        <img
          src="@/assets/icons/dashboard_settings-3.png"
          alt="dashboard"
          style="width: 60px"
        />
      </div>

      <div class="d-flex align-items-center">
        <h5 class="mb-0">{{ dict.title[lang] }}</h5>
      </div>

      <!-- <div :class="dict.dir[lang]==='rtl'?  'page-title-logo-rtl':'page-title-logo-ltr' ">
        <img src="../assets/images/mehrsanat.png" alt="dashboard" style="width: 200px;">
      </div>

       <div :class="dict.dir[lang]==='rtl'?  'page-title-logo-rtl':'page-title-logo-ltr' ">
        <img src="../assets/images/MSC.png" alt="dashboard" style="width: 200px;">
      </div> -->

      <div
        class="d-flex"
        :class="
          dict.dir[lang] === 'rtl'
            ? 'page-title-right-rtl'
            : 'page-title-right-ltr'
        "
      >
        <div
          v-permission="'add_page'"
          v-if="editable"
          class="page-title-ricon"
          @click="addPage"
        >
          <i>
            <font-awesome-icon icon="fas fa-file-circle-plus" size="lg" />
          </i>
        </div>

        <div
          v-if="editable"
          class="page-title-ricon"
          @click="openWidgetModal"
          v-permission="'edit_widgets'"
        >
          <i>
            <font-awesome-icon icon="fas fa-plus" size="lg" />
          </i>
        </div>

        <!--        <b-dropdown-->
        <!--          v-permission="'addItemDashboard'"-->
        <!--          class="page-title-ricon mr-2 add-icon-dropdown"-->
        <!--          v-if="editable"-->
        <!--          right-->
        <!--          size="sm"-->
        <!--          variant="link"-->
        <!--          no-caret-->
        <!--        >-->
        <!--          <template #button-content>-->
        <!--            <i  ><font-awesome-icon icon="fas fa-plus" size="lg" /></i>-->
        <!--          </template>-->
        <!--          <b-dropdown-item-->
        <!--            v-for="(item, i) in widgetsList"-->
        <!--            :key="'a' + i"-->
        <!--            href="#"-->
        <!--            @click="addItem('widget', item)"-->
        <!--          >{{ item.text }}</b-dropdown-item-->
        <!--          >-->
        <!--          <b-dropdown-item-->
        <!--            v-for="(item, i) in contWidgetsList"-->
        <!--            :key="'b' + i"-->
        <!--            href="#"-->
        <!--            @click="addItem('contWidget', item)"-->
        <!--          >{{ item.text }}</b-dropdown-item-->
        <!--          >-->
        <!--        </b-dropdown>-->

        <div
          :class="editClasses"
          @click="addComponent"
          v-permission="'add_widgets'"
        >
          <i v-if="this.editable">
            <font-awesome-icon icon="fas fa-edit" beat size="lg" />
          </i>
          <i v-else>
            <font-awesome-icon icon="fas fa-edit" size="lg" />
          </i>
        </div>

        <!-- <div class="page-title-ricon" @click="curPage = '1'">
          <i><font-awesome-icon icon="fas fa-house" size="lg" /></i>
        </div> -->
        <div
          v-for="(item, i) in totalPages"
          :key="i"
          :class="
            item === curPage ? 'page-title-ricon-active' : 'page-title-ricon'
          "
          @click="changePage(item)"
        >
          <i>{{ item }}</i>
        </div>
      </div>
    </div>

    <div
      v-if="editable"
      class="app-pagination"
      :class="paginationClasses"
      @mouseover="pagination = true"
      @mouseleave="pagination = false"
    >
      <div
        v-if="!pagination"
        class="
          d-flex
          justify-content-center
          align-content-center
          text-center
          w-100
        "
      >
        <div style="font-weight: bold">{{ `Page ${curPage}` }}</div>
      </div>
      <PerfectScrollbar v-if="pagination" style="width: 100%">
        <ul
          class="pagination"
          style="
            flex-direction: column;
            width: 100%;
            text-align: center;
            padding: 15px;
          "
        >
          <li
            v-for="(item, i) in totalPages"
            :key="i"
            class="page-item"
            :class="item === curPage ? 'active' : ''"
            @click="curPage = item"
          >
            <p class="page-link">{{ item }}</p>
          </li>
        </ul>
      </PerfectScrollbar>
    </div>
    <grid-layout
      :layout.sync="dashLayout"
      :responsive-layouts="dashLayouts"
      :col-num="colNum"
      :cols="cols"
      :margin="margin"
      :row-height="15"
      :is-draggable="draggable"
      :is-resizable="resizable"
      :responsive="responsive"
      :isBounded="bounded"
      :key="reRenderLayout"
      :vertical-compact="true"
      :use-css-transforms="true"
      @breakpoint-changed="breakpointChangedEvent"
      @layout-updated="layoutUpdatedEvent"
      :is-mirrored="mirrored"
      ref="gridLayout"
      v-permission="'widgets_list'"
    >
      <grid-item
        v-for="item in dashLayout"
        :static="item.static"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
        @resized="resizedEvent(item)"
      >
        <!-- PerfectScrollbar -->
        <!--  -->
        <div :class="computedClasses">
          <div style="height: 100%; width: 100%">
            <div
              v-if="editable"
              class="widget-float-btn"
              style="z-index: 9999"
              :class="{
                'widget-float-open': item.floatBtn,
                'widget-float-close': !item.floatBtn,
                'widget-float-rtl': dict.dir[lang] === 'rtl',
                'widget-float-ltr': dict.dir[lang] === 'ltr',
              }"
              @mouseover="item.floatBtn = true"
              @mouseleave="item.floatBtn = false"
            >
              <span v-if="editable && !item.floatBtn" class="w-100 text-center">
                <i><font-awesome-icon icon="fas fa-ellipsis-vertical" /></i>
              </span>

              <div v-if="editable && item.floatBtn" class="text-center">
                <span
                  v-permission="'edit_single_widget'"
                  class="edit w-100 text-success"
                  @click="openSetting(item)"
                >
                  <i><font-awesome-icon icon="fas fa-pencil" /></i>
                </span>
              </div>

              <div v-if="editable && item.floatBtn" class="text-center">
                <span
                  v-permission="'remove_single_widget'"
                  class="remove w-100 text-danger"
                  @click="removeItem(item.i)"
                >
                  <i><font-awesome-icon icon="fas fa-xmark" /></i>
                </span>
              </div>
            </div>

            <!-- <div class="card-header" style="min-height: 40px">
              <div class="row">
                <div class="col-8">
                  <p class="mb-0">

                    {{ item.data? (item.data.attribute? item.data.attribute.title? item.data.attribute.title : 'noname' : 'noname') : 'noname' }}
                    



                  </p>
                </div>
                <div class="col-2 text-center" v-if="editable">
                  <span class="edit" @click="openSetting(item)">
                    <i><font-awesome-icon icon="fas fa-pencil" /></i>
                  </span>
                </div>
                <div class="col-2 text-center" v-if="editable">
                  <span class="remove" @click="removeItem(item.i);">
                    <i><font-awesome-icon icon="fas fa-xmark" /></i>
                  </span>
                </div>
              </div>
            </div> -->
            <component
              style="z-index: -10"
              :is="dashComponents[item.type]"
              :plotId="item.i"
              :data="item.data"
              :editable="editable"
              :resized="item.resized"
              :setting="item.setting"
              :key="reRender"
            />
          </div>
        </div>
      </grid-item>
    </grid-layout>

    <!-- <div v-if="carousel" id="carouselContainer" class="carousel">
     
      <div style="height:100px; display: flex; justify-content: end; padding: 20px;">
        <b-button  @click="carousel=false" size="sm" variant="outline-success"  class="mr-2" style="border: none; height: 25px; box-shadow: none;">
            <i><font-awesome-icon icon="fas fa-xmark" /></i>
        </b-button>
      </div>

      <carousel-3d  :width="500" :height="500"
                    :controls-visible="true" :controls-prev-html="'&#10092; '" :controls-next-html="'&#10093;'" 
                    :controls-width="30" :controls-height="60"
                    :perspective="0" :space="400" :display="3">

        <slide v-for="(slide, i) in carouselData" :key="slide.key" :index="i" >
          <myComponent_lighting  v-if="slide.type==='lighting'"  type = "type1" :panelData = "slide" :disable="false"  style="transform: scale(1);"/>
          <myComponent_termostat  v-if="slide.type==='termostat'" :panelData = "slide"/>
          <myComponent_circulationFan  v-if="(slide.type==='circulationFan')||(slide.type==='exhaustFan')||
                                             (slide.type==='irrigation')||(slide.type==='heater')||(slide.type==='window')||
                                             (slide.type==='growLight')||(slide.type==='cooler')||(slide.type==='fogger')" :panelData = "slide"/>
        </slide>
      </carousel-3d>
    </div> -->

    <!-- <div v-if="control" id="controlContainer" class="overlay">
      <myComponent_control class="control"/>
    </div>

    <div v-if="schedule" id="scheduleContainer" class="overlay">
      <myComponent_panel_1_schedule class="schedule"/>
    </div>

    <div v-if="group" id="groupContainer" class="overlay">
      <myComponent_panel_1_group class="group"/>
    </div> -->

    <widgetSelector />
  </div>
</template>

<script>
/* eslint-disable */

import { Carousel3d, Slide } from "vue-carousel-3d";
import { GridLayout, GridItem } from "vue-grid-layout";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import widgetSelector from "./widgetSelectorModal.vue";

import widgetChart from "./widgetChart.vue";
import widgetPieChart from "./widgetPieChart.vue";
import widgetGaugeRadial from "./widgetGaugeRadial.vue";
import widgetGaugeLinear from "./widgetGaugeLinear.vue";
import widgetSliderVertical from "./widgetSliderVertical.vue";
import widgetSliderHorizontal from "./widgetSliderHorizontal.vue";
import widgetSliderCircular from "./widgetSliderCircular.vue";
import widgetGauge from "./widgetGauge.vue";
import widgetDeviceStatus from "./widgetDeviceStatus.vue";
import widgetMap from "./widgetMap.vue";
import fireEvents from "./widgetFireEvents.vue";

import widgetCtrlSolarTracker from "./widgetCtrlSolarTracker.vue";
import widgetCtrlSolarCalculator from "./widgetCtrlSolarCalculator.vue";
import widgetCtrlVPD from "./widgetCtrlVPD.vue";
import widgetCtrlLighting from "./widgetCtrlLighting.vue";
import widgetCtrlThermostat from "./widgetCtrlThermostat.vue";
import widgetCtrlRoofWindow from "./widgetCtrlRoofWindow.vue";
import widgetCtrlGasHeater from "./widgetCtrlGasHeater.vue";
import widgetCtrlCirculationFan from "./widgetCtrlCirculationFan.vue";
import widgetCtrlUnitHeater from "./widgetCtrlUnitHeater.vue";
import widgetCtrlFogger from "./widgetCtrlFogger.vue";
import widgetCtrlExhaustFan from "./widgetCtrlExhaustFan.vue";
import widgetCtrlCooler from "./widgetCtrlCooler.vue";
import widgetCtrlGrowLight from "./widgetCtrlGrowLight.vue";
import widgetCtrlIrrigation from "./widgetCtrlIrrigation.vue";

import widgetCtrlFanPad from "./widgetCtrlFanPad.vue";
import widgetCtrlHeatExchangerTank from "./widgetCtrlHeatExchangerTank.vue";
import widgetCtrlPumpSwitcherA from "./widgetCtrlPumpSwitcherA.vue";
import widgetCtrlPumpSwitcherB from "./widgetCtrlPumpSwitcherB.vue";
import widgetCtrlDieselFuelLevel from "./widgetCtrlDieselFuelLevel.vue";
import widgetCtrlWaterLevel from "./widgetCtrlWaterLevel.vue";
import widgetCtrlUnderFloorHeatingCooling from "./widgetCtrlUnderFloorHeatingCooling.vue";
import widgetCtrlSuggestion from "./widgetCtrlSuggestion.vue";
import widgetCtrlSuggestion2 from "./widgetCtrlSuggestion2.vue";
import proccessflow from "./widgetProcess.vue";
import markerWidget from "./mapComponents/widgetMarker.vue";
import scadaComponent from "././processFlowComponents/crud/schada";
import tableWidget from "../components/widgetTable.vue";
import textInput from "../components/widgetInputText.vue";
import numberInput from "../components/widgetInputNum.vue";
import switchInput from "../components/widgetInputSwitch.vue";
import checkboxInput from "../components/widgetInputCheckbox.vue";
import LinearGuage from "../components/widgetInputLinearGuage.vue";


import Vue from "vue";
import { DialogUtility } from "@syncfusion/ej2-popups";
import WidgetTable from "@/components/widgetTable.vue";

export default {
  name: "CustomPath",
  components: {
    WidgetTable,
    Carousel3d,
    Slide,
    GridLayout,
    GridItem,
    PerfectScrollbar,
    widgetSelector,
    proccessflow,
    markerWidget,
    scadaComponent,
    tableWidget,
    // myComponent_lighting,
    // myComponent_panel_1_schedule,
    // myComponent_panel_1_group,
    // myComponent_control,
    // myComponent_termostat,
    // myComponent_circulationFan,

    widgetChart,
    widgetPieChart,
    widgetGaugeRadial,
    widgetGaugeLinear,
    widgetSliderVertical,
    widgetSliderHorizontal,
    widgetSliderCircular,
    widgetGauge,
    widgetDeviceStatus,
    widgetMap,
    fireEvents,

    widgetCtrlSolarTracker,
    widgetCtrlSolarCalculator,
    widgetCtrlVPD,
    widgetCtrlLighting,
    widgetCtrlRoofWindow,
    widgetCtrlGasHeater,
    widgetCtrlCirculationFan,
    widgetCtrlUnitHeater,
    widgetCtrlFogger,
    widgetCtrlExhaustFan,
    widgetCtrlCooler,
    widgetCtrlGrowLight,
    widgetCtrlIrrigation,
    widgetCtrlThermostat,

    widgetCtrlFanPad,
    widgetCtrlHeatExchangerTank,
    widgetCtrlPumpSwitcherA,
    widgetCtrlPumpSwitcherB,
    widgetCtrlDieselFuelLevel,
    widgetCtrlWaterLevel,
    widgetCtrlUnderFloorHeatingCooling,
    widgetCtrlSuggestion,
    widgetCtrlSuggestion2,
    textInput,
    numberInput,
    switchInput,
    checkboxInput,
    LinearGuage

  },
  data() {
    return {
      reRenderLayout: 0,
      reRender: 0,
      dashComponents: {
        chart: "widgetChart",
        pieChart: "widgetPieChart",
        radialGauge: "widgetGaugeRadial",
        linearGauge: "widgetGaugeLinear",
        sliderVertical: "widgetSliderVertical",
        sliderHorizontal: "widgetSliderHorizontal",
        sliderCircular: "widgetSliderCircular",
        gauge: "widgetGauge",
        deviceStatus: "widgetDeviceStatus",
        map: "widgetMap",
        fireEvents: "fireEvents",
        proccessflow: "proccessflow",
        scada: "scadaComponent",
        markerWidget: "markerWidget",
        tableWidget: "tableWidget",
        textInput: "textInput",
        numberInput: "numberInput",
        switchInput: "switchInput",
        checkboxInput: "checkboxInput",
        LinearGuage: "LinearGuage",


        roofWindowController: "widgetCtrlRoofWindow",
        gasHeaterController: "widgetCtrlGasHeater",
        circulationFanController: "widgetCtrlCirculationFan",
        unitHeaterController: "widgetCtrlUnitHeater",
        foggerController: "widgetCtrlFogger",
        exhaustFanController: "widgetCtrlExhaustFan",
        coolerController: "widgetCtrlCooler",
        growLightController: "widgetCtrlGrowLight",
        irrigationController: "widgetCtrlIrrigation",
        thermostatController: "widgetCtrlThermostat",
        solarTrackerController: "widgetCtrlSolarTracker",
        solarCalculatorController: "widgetCtrlSolarCalculator",
        vpdCalculatorController: "widgetCtrlVPD",
        lightingController: "widgetCtrlLighting",

        fanPadController: "widgetCtrlFanPad",
        heatExchangerTankController: "widgetCtrlHeatExchangerTank",
        pumpSwitcherA: "widgetCtrlPumpSwitcherA",
        pumpSwitcherB: "widgetCtrlPumpSwitcherB",
        dieselFuelLevelController: "widgetCtrlDieselFuelLevel",
        waterLevelController: "widgetCtrlWaterLevel",
        underFloorHeatingCoolingController: "widgetCtrlUnderFloorHeatingCooling",
        suggestionController: "widgetCtrlSuggestion",
        suggestionController2: "widgetCtrlSuggestion2"


      },
      activeZone: null,
      carousel: false,
      schedule: false,
      group: false,
      control: false,
      controlType: "",
      carouselData: [],
      equipments: [],
      dashLayouts: {},
      dashLayout: [],

      curPage: 1,
      totalPages: [],
      pagination: false,
      editable: false,
      draggable: false,
      resizable: false,
      responsive: true,
      bounded: true,
      colNum: 36,
      index: 0,
      cols: { lg: 36, md: 30, sm: 18, xs: 12, xxs: 6 },
      margin: [15, 15],
      newBreakpoint: "",
      // newLayout:[],


      contComponents: {},
      dict: {
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "Dashboard", per: "داشبورد" },
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        deleteConfirmContent: {
          eng: "Are you sure to remove the data ?",
          per: "آیا از حذف این رکورد اطمینان دارید ؟"
        },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" }
      },

      widgetsList: [],
      contWidgetsList: []
    };
  },
  computed: {
    updatePage: function() {
      return this.$store.state.chpge.reload;
    },
    changeDashPage: function() {
      return this.$store.state.chpge.dashPage;
    },
    // child2parent(){
    //   return this.$store.state.chpge.parent2child;
    // },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    updatedData() {
      return this.$store.state.panel.data;
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    mirrored() {
      if (this.dict.dir[this.lang] === "rtl")
        return true;
      else
        return false;
    },
    editClasses() {
      const classes = {
        "text-center": true,
        "page-title-ricon": true
      };
      if (this.editable)
        classes["bg-warning"] = true;

      return classes;
    },
    widgetFloatBtnClasses() {
      const classes = {};
      if (this.pagination)
        classes["pagination-open"] = true;
      else
        classes["pagination-close"] = true;

      if (this.dict.dir[this.lang] === "rtl")
        classes["pagination-rtl"] = true;
      else if (this.dict.dir[this.lang] === "ltr")
        classes["pagination-ltr"] = true;

      return classes;
    },
    paginationClasses() {
      const classes = {};
      if (this.pagination)
        classes["pagination-open"] = true;
      else
        classes["pagination-close"] = true;

      if (this.dict.dir[this.lang] === "rtl")
        classes["pagination-rtl"] = true;
      else if (this.dict.dir[this.lang] === "ltr")
        classes["pagination-ltr"] = true;

      return classes;
    },
    myworkBench() {
      return this.$store.state.data.workbench;
    },
    activeWorkbench() {
      if (this.myworkBench) {
        return this.myworkBench.activeWorkbench;
      } else
        return {};
    },
    sidebarColor() {
      if (this.currentUser) return this.currentUser.theme.sidebarColor;
      else return "";
    },
    sidebarFont() {
      if (this.currentUser) return this.currentUser.theme.sidebarFont;
      else return "dark";
    },
    currentUser() {
      return this.$store.state.data.user;
    },
    computedClasses() {
      const classes = {
        "card": true,
        "card-shadow": true,
        "h-100": true
      };
      if (this.sidebarColor) {
        classes[this.sidebarColor] = true;
      } else {
        classes["bg-default-sidebar"] = true;
      }

      if (this.sidebarFont) {
        classes[this.sidebarFont + "-font"] = true;
      } else {
        classes["dark-font"] = true;
      }
      return classes;


    }

  },
  watch: {
    changeDashPage(newValue, oldValue) {
      this.curPage = newValue;
    },
    activeWorkbench(newValue) {
      this.getDashboard();
      this.getAllData();
    },
    child2parent(newValue) {
      if (newValue.task === "rmDashItem") {
        //console.log('remove item',newValue.item)
        this.removeItem(newValue.item);
      } else if (newValue.task === "updateDash") {
        //console.log('update dshboard item')
        this.getDashboard();
      } else if (newValue.task === "addDashItem") {
        this.addItem(newValue.type, newValue.item);
        this.getDashboard();
      }

      if (newValue.task === "zoneClick") {
        this.zoneClick(newValue.data);
      }

      if (newValue.task === "open_schedule_modal")
        this.schedule = true;
      else if (newValue.task === "close_schedule_modal")
        this.schedule = false;

      if (newValue.task === "open_group_modal")
        this.group = true;
      else if (newValue.task === "close_group_modal")
        this.group = false;

      if (newValue.task === "open_control_modal") {
        this.control = true;
      } else if (newValue.task === "close_control_modal")
        this.control = false;
    },
    updatePage(newValue, oldValue) {
      if (this.activeZone) {
        this.$store.dispatch("panel/clickonMap", this.activeZone)
          .then(
            data => {
              this.carouselData = JSON.parse(JSON.stringify(data.equipments));
              for (var d of this.carouselData) {
                d["key"] = d["id"] + "_" + newValue;
              }
            },
            error => {
              var message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              alert(message);
            }
          );
      }
    },
    curPage(newValue) {
      this.$store.dispatch("chpge/changeDashPage", newValue);
      this.getDashboard();
    }
  },
  methods: {
    changePage(page) {
      this.curPage = page;
      this.reRenderLayout += 1;
    },
    sendIdForSse(data) {
      const infos = [];
      // console.log(this.datas.tableStatus ,'categoryDatas');
      data.dashboard.forEach((item) => {
        if (item.data != null) {
          if (item.type != "tableWidget") {
            item.data.datas.forEach((val) => {
              if (item.type == "map") {
                if (item.data.attribute.dataType != "static") {
                  if ("id" in item && val.id != undefined) {
                    infos.push(val.id);
                  }
                }
              } else if ("_id" in item && val._id != undefined)
                infos.push(val._id);
            });
          } else {
            if (item.data.datas.tableStatus == "fix") {
              if ("categoryDatas" in item.data.datas && item.data.datas.categoryDatas.length > 0) {
                item.data.datas.categoryDatas.forEach(item => {
                  if (item._id != undefined)
                    infos.push(item.id);
                });
              }
            } else {
              if (item.data.datas.assignedData.id != undefined && item.data.datas.tableStatus == "timeSeri")
                infos.push(item.data.datas.assignedData.id);
            }
          }
        }
      });
      this.$store.dispatch("data/sendDataForSSE", { id: infos });

    },
    zoneClick: function(e) {
      this.activeZone = e;
      this.$store.dispatch("panel/clickonMap", e)
        .then(
          data => {
            this.carouselData = data.equipments;
            for (var d of this.carouselData) {
              d["key"] = d["id"];
            }
            this.carousel = true;
          },
          error => {
            var message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            alert(message);
          }
        );
    },
    getDashboard() {
      this.$store.dispatch("data/getDashboard", { page: this.curPage }).then(
        data => {
          this.sendIdForSse(data);
          //console.log('dashboard data: ',data)


          if (this.dashLayouts && this.dashLayouts["lg"])
            var dl = this.dashLayouts["lg"].length;

          this.dashLayouts = {};
          this.dashLayout = [];

          this.totalPages = data.pages;

          var layouts = {};
          var layout = [];
          //console.log('ssssssss',data.dashboard)
          var bps = ["lg", "md", "sm", "xs", "xxs"];
          data.dashboard.forEach(element => {
            for (var bp of bps) {
              // console.log(bp)
              if (element[bp]) {
                if (layouts[bp])
                  layouts[bp].push({
                    ...element[bp],
                    i: element.i,
                    type: element.type,
                    data: element.data ? element.data : null,
                    resized: 0,
                    setting: false,
                    floatBtn: false
                  });
                else
                  layouts[bp] = [{
                    ...element[bp],
                    i: element.i,
                    type: element.type,
                    data: element.data ? element.data : null,
                    resized: 0,
                    setting: false,
                    floatBtn: false
                  }];
              }
            }
          });
          // if (layouts["lg"].length === dl) {
          //   for (var bp of bps) {
          //     layouts[bp].push({ x: 0, y: 0, w: 0, h: 0, i: "0", type: "blank" });  //ahmad
          //   }
          // }


          // console.log(layouts)


          for (var bp of bps) {
            if (layouts[bp]) {
              layout = layouts[bp];
              break;
            }
          }

          // this.dashLayouts = layouts;

          Object.keys(layouts).forEach(key => {
            this.$set(this.dashLayouts, key, layouts[key]);
          });

          // this.dashLayout = layout;

          layout.forEach((componentData) => {
            this.dashLayout.push(componentData);
          });
          this.reRender += 1;
          // this.reRenderLayout += 1;
          //console.log('dl',dl,this.dashLayout.length)


          //console.log('this.dashLayouts',this.dashLayouts)
          //console.log('this.dashLayout',this.dashLayout)


          var dashWidgetTypes = [];
          var dashControlTypes = [];
          this.dashLayout.forEach(item => {
            if (item.type === "controlNode") {
              if (!dashControlTypes.includes(item.data.controlNode.type))
                dashControlTypes.push(item.data.controlNode.type);
            } else {
              if (!dashWidgetTypes.includes(item.type))
                dashWidgetTypes.push(item.type);
            }
          });

          this.$store.dispatch("data/nodeHandler", { type: "getUi", id: dashControlTypes }).then(
            data => {
              this.contComponents = {};
              Object.entries(data.data).forEach(item => {
                //console.log(item[1])
                this.createDynamicComponent(item[1]);
                this.contComponents[item[0]] = item[1].name;
              });
              // console.log(this.contComponents)
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              // this.successful = false;
            }
          );
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    async createDynamicComponent(config) {
      const { name, template, methods, computed, data, mounted, props } = config;

      // Create methods properties
      const dynamicMethods = {};
      for (const methodName in methods) {
        if (methods.hasOwnProperty(methodName)) {
          // console.log(methods[methodName])
          dynamicMethods[methodName] = new Function(methods[methodName]);
        }
      }

      // Create computed properties
      const dynamicComputed = {};
      for (const computedName in computed) {
        if (computed.hasOwnProperty(computedName)) {
          // dynamicComputed[computedName] = new Function(`return ${computed[computedName]}`);
          dynamicComputed[computedName] = new Function(computed[computedName]);
        }
      }

      // Create mounted properties
      const mountedFunc = new Function(mounted);

      // Dynamically create the Vue component
      const DynamicComponent = Vue.extend({
        template: template,
        data() {
          return data;
        },
        computed: dynamicComputed,
        methods: dynamicMethods,
        mounted: mountedFunc,
        props: props
      });

      Vue.component(name, DynamicComponent);
    },
    // handleButton(type,component) {
    //   let ai = this.dashLayout.findIndex(x => x._id === component)
    //   if (ai != -1){
    //     var data = this.dashLayout[ai]
    //     if (data.type === 'chart'){
    //       this.$store.dispatch('chpge/parent2child', {task: 'chart_modal', type:type, data:data})
    //       this.$root.$emit('bv::show::modal', 'modal-setDashComp');
    //     }
    //     else if (data.type === 'gauge'){
    //       this.$store.dispatch('chpge/parent2child', {task: 'gauge_modal', type:type, data:data})
    //       this.$root.$emit('bv::show::modal', 'modal-setDashDGauge');
    //     }
    //     else if (data.type === 'deviceStatus'){
    //       this.$store.dispatch('chpge/parent2child', {task: 'deviceStatus_modal', type:type, data:data})
    //       this.$root.$emit('bv::show::modal', 'modal-setDashDeviceStatus');
    //     }
    //     else if (data.type === 'map'){
    //       this.$store.dispatch('chpge/parent2child', {task: 'map_modal', type:type, data:data})
    //       this.$root.$emit('bv::show::modal', 'modal-setDashMap');
    //     }
    //     else if (data.type === 'controlNode'){
    //       this.$store.dispatch('chpge/parent2child', {task: 'controlNode_modal', type:type, data:data})
    //       this.$root.$emit('bv::show::modal', 'modal-setDashControlNode');
    //     }
    //   }
    // },
    addComponent() {
      this.editable = !this.editable;
      this.draggable = !this.draggable;
      this.resizable = !this.resizable;

      if (!this.editable) {
        //console.log(this.dashLayout)
        this.handleRegister();
      }
    },
    handleRegister() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {
          var data = { page: this.curPage, breakpoint: this.newBreakpoint, layout: this.dashLayout };
          //console.log('createDashboard',data)
          this.$store.dispatch("data/createDashboard", data).then(
            data => {
              this.message = data.message;
              this.successful = true;
              this.getDashboard();
            },
            error => {
              // this.message =
              //   (error.response && error.response.data && error.response.data.message) ||
              //   error.message ||
              //   error.toString();
              // this.successful = false;
              // alert(this.message);
            }
          );
        }
      });
    },
    addItem: function(wtype, iData) {
      var item = {
        x: (this.dashLayout.length * 2) % (this.colNum || 36),
        y: this.dashLayout.length + (this.colNum || 36), // puts it at the bottom
        w: iData.size.width,
        h: iData.size.height,
        type: iData.id,
        name: "",
        data: iData.data
        // data: (wtype==='contWidget')? iData.data:{},

      };
      // console.log('add widget 1',item)

      this.$store.dispatch("data/addDashboardItem", { page: this.curPage, item: item }).then(
        data => {
          var addedItem = {
            x: data.lg.x,
            y: data.lg.y,
            w: data.lg.w,
            h: data.lg.h,
            type: data.type,
            name: data.name,
            data: data.data ? data.data : {},
            i: data.i,
            _id: data._id
          };
          //console.log('new layout',this.dashLayout,addedItem)
          this.dashLayout.push(addedItem);
          var bps = ["lg", "md", "sm", "xs", "xxs"];
          for (var bp of bps) {
            this.dashLayouts[bp].push(addedItem);
          }
          //console.log('new layout',this.dashLayout)
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          alert(this.message);
        }
      );
    },
    removeItem: function(item) {

      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            this.$store.dispatch("data/removeDashboardItem", { item: item }).then(
              data => {
                const index = this.dashLayout.map(item => item.i).indexOf(item);
                this.dashLayout.splice(index, 1);

                //console.log('new layout',this.dashLayout)

                var bps = ["lg", "md", "sm", "xs", "xxs"];

                for (var bp of bps) {
                  const index = this.dashLayouts[bp].map(item => item.i).indexOf(item);
                  this.dashLayouts[bp].splice(index, 1);
                }
                this.getDashboard();

                //console.log('new layout',this.dashLayouts)
              },
              error => {
                this.message =
                  (error.response && error.response.data && error.response.data.message) ||
                  error.message ||
                  error.toString();
                this.successful = false;
                alert(this.message);
              }
            );
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    openSetting: function(item) {
      item.setting = true;
      setTimeout(() => {
        item.setting = false;
      }, 100);
    },
    openWidgetModal: function() {
      this.$root.$emit("bv::show::modal", "modal-widget-selector");
    },
    addPage: function() {
      var pn = this.totalPages.length + 1;
      pn = pn.toString();
      this.totalPages.push(pn);
      this.curPage = pn;
      // this.reRenderLayout += 1;
    },
    getAllData() {


      this.widgetsList = [
        { value: "chart", text: "Line chart" },
        { value: "pieChart", text: "Pie Chart" },
        { value: "radialGauge", text: "Radial Gauge" },
        { value: "linearGauge", text: "Linear Gauge" },
        { value: "deviceStatus", text: "Device Status" },
        { value: "sliderVertical", text: "vertical Slider" },
        { value: "sliderHorizontal", text: "horizontal Slider" },
        { value: "sliderCircular", text: "circular Slider" },
        { value: "gauge", text: "Gauge" },
        { value: "map", text: "Map" },
        { value: "proccessFlow", text: "process flow" },
        { value: "markerWidget", text: "marker layer" }
      ];

      this.$store.dispatch("data/getAllData", {
        dataType: ["complex"],
        usedBy: [],
        parents: [],
        archive: null,
        page: 1,
        perPage: 5000
      }).then(
        data => {
          data.newmatches.forEach(node => {
            let ai = node.usedBy.findIndex(x => x.utype === "widget");
            if (ai === -1) {
              var widgetData = {
                datas: [{ type: node.type, _id: node._id, name: node.name }],
                attribute: { title: node.name }
              };
              this.contWidgetsList.push({
                value: this.toCamelCase(node.value.type),
                text: node.name,
                data: widgetData
              });
            }
          });
          // console.log("==============================: ",this.contWidgetsList)
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    toCamelCase(inputString) {
      // Split the input string into words
      const words = inputString.split(/\s+/);

      // Format each word to start with a lowercase letter
      const camelCaseString = words
        .map((word, index) => {
          if (index === 0) {
            // Capitalize the first word
            return word.charAt(0).toLowerCase() + word.slice(1);
          } else {
            // Format subsequent words to start with an uppercase letter
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          }
        })
        .join("");

      return camelCaseString;
    },


    breakpointChangedEvent: function(newBreakpoint, newLayout) {
      this.newBreakpoint = newBreakpoint;
      // this.newLayout = newLayout
      //console.log("BREAKPOINT CHANGED breakpoint=", newBreakpoint, ", layout: ", newLayout );
    },
    layoutUpdatedEvent: function(newLayout) {
      // console.log("Updated layout: ", newLayout)
    },
    resizedEvent(item) {
      item.resized += 1;
      //console.log(item)
    },
    getAllWidgetFiles() {
      this.$store.dispatch("data/getWidgetsList").then(
        data => {
          var WL = [];
          data.forEach(widget => {
            WL.push(widget._id);
            this.getWidgetFile(widget._id);
          });
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    getWidgetFile(id) {
      this.$store.dispatch("data/getWidgetFile", { id: id }).then(
        data => {
          this.addWidgetScript(data, id);

        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    addWidgetScript(data, id) {
      const scriptElem = document.createElement("script");
      //   convert base64 vidget to js
      const base64Content = data.file;
      const jsContent = atob(base64Content);


      //   add vidget to script elem
      scriptElem.innerHTML = jsContent;
      scriptElem.id = `widget-script-${id}`;

      const existingScript = document.getElementById(scriptElem.id);
      if (!existingScript) {
        //   append script to document heade
        document.head.appendChild(scriptElem);
      }

      //   append vidget elem to page
      // const widgetBoxElem = document.getElementById("widgets-box");
      // const widgetElem = document.createElement(this.widgets.key);
      // widgetBoxElem.appendChild(widgetElem);

      //   add event listener to vidget
      // vidgetElem.addEventListener(this.vidgets.key, this.updateVidgetValue);
    },
    createWidget(data, id) {

      //   append vidget elem to page
      const widgetBoxElem = document.getElementById("widgets-box");
      const widgetElem = document.createElement(this.widgets.key);
      widgetBoxElem.appendChild(widgetElem);

      //   add event listener to vidget
      vidgetElem.addEventListener(this.vidgets.key, this.updateVidgetValue);
    }
  },
  mounted() {
    this.curPage = 1;
    this.$store.dispatch("chpge/changeDashPage", 1);
    this.getAllWidgetFiles();
    this.getDashboard();
    this.getAllData();
  },
  created() {
    //console.log('webmap creation!!')
  }
};
</script>

<style scoped>
.page_container {
  padding: 0.75% 0.35%;
  width: 100%;
  height: 100%;
  position: relative;
}

/* main-svg */

.carousel {
  /* display:flex; */
  position: absolute;
  z-index: 5;
  background-color: rgba(0, 0, 0, .95); /* Black w/opacity */
  /* margin:10px; */
  /* padding: 10px; */
  /* border: 1px solid #ffffff;
  border-radius: 0.5rem;
  left:  10%;
  right: 10%; */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* padding-top: 100px; */
}

.schedule {
  /* display:flex; */
  position: absolute;
  z-index: 11;
  margin: 10px;
  /* padding: 10px; */
  border: 1px solid #ffffff;
  background-color: rgb(255, 255, 255);
  border-radius: 0.5rem;
  left: 5%;
  right: 5%;
  bottom: 3%;
  top: 3%;
}

.group {
  /* display:flex; */
  position: absolute;
  z-index: 11;
  margin: 10px;
  /* padding: 10px; */
  border: 1px solid #ffffff;
  background-color: rgb(255, 255, 255);
  border-radius: 0.5rem;
  left: 35%;
  right: 35%;
  bottom: 5%;
  top: 5%;
}

.overlay {
  position: absolute; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgb(0,0,0); Black fallback color */
  background-color: rgba(0, 0, 0, .95); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s;
}

.control {
  position: absolute;
  z-index: 11;
  margin: 10px;
  /* box-shadow: rgba(8, 237, 237, 0.975) 0px 3px 6px, rgba(38, 212, 218, 0.877) 0px 3px 6px; */
  border: 1px solid #ffffff;
  background-color: rgb(255, 255, 255);
  border-radius: 0.5rem;
  left: 15%;
  right: 15%;
  bottom: 5%;
  top: 5%;
}

.mycont {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #16113a;
  opacity: 1;
  padding: 10px 15px 10px 15px;

  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.myrow {
  /* height: 100%;  */
  width: 100%;
  display: flex;
  justify-content: space-between;
  --bs-gutter-x: 1.5rem;
  margin: 5px 0px 5px 0px;
}

.mycol {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* justify-content: space-between; */
  /* --bs-gutter-x: 1.5rem; */
  /* margin: 5px 0px 5px 0px; */
}

.mymap {
  height: 100%;
  width: 100%;
  border: 1px solid #16113a;
  border-radius: 0.45rem;
  padding: 10px 10px 10px 10px;
}

.map {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.mymapcont {
  height: 100%;
  width: 100%;
  border: 1px solid #16113a;
  border-radius: 0.45rem;
  background-color: rgb(39, 41, 83);
  opacity: 1;
  /* padding: 10px 10px 10px 10px; */
  display: flex;
  flex-direction: column;
}

.mystatus {
  /* height: 100%;
  width: 100%; */
  border: 1px solid #16113a;
  border-radius: 0.475rem;
  padding: 10px 10px 10px 10px;
  background-color: rgb(39, 41, 83);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(255, 255, 255)
}


.black-white {
  filter: invert(99%) sepia(100%) saturate(0%) hue-rotate(135deg) brightness(106%) contrast(101%);
}

.myvalue {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: normal;
  color: rgb(255, 255, 255);
  text-shadow: 1px 1px 2px rgb(68, 66, 66), 0 0 25px rgb(120, 120, 224), 0 0 5px rgb(104, 104, 118);
  /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
}

.status-pill-off {
  position: relative;
  display: block;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  background: #171515;
  border-radius: 50%;
  font-size: 25px;
  color: #666;
}

.status-pill-on {
  position: relative;
  display: block;
  width: 55px;
  height: 55px;
  text-align: center;
  line-height: 55px;
  background: #171515;
  border-radius: 50%;
  font-size: 25px;
  color: #00d0ff;
  box-shadow: 0 0 15px #00d0ff;
  text-shadow: 0 0 15px #00d0ff;
}


.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}

.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}

/*************************************/
.remove {
  cursor: pointer;
  font-size: 18px;
}

.edit {
  cursor: pointer;
  font-size: 18px;
}

.vue-grid-layout {
  /* background-color: #16113a; */
  background-color: none;

}

.vue-grid-item {

  touch-action: none;
}

/* .vue-grid-item:not(.vue-grid-placeholder) {
    background-color:rgb(39, 41, 83);
    border: 1px solid #16113a;
    border-radius: 0.45rem;
} */
.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item .text {
  color: #cce;
  font-size: 24px;
  text-align: center;
  /* position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  margin: auto;
  /* height: 100%;
  width: 100%; */
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}


</style>

<style lang="scss">
.carousel-3d-container {
  margin: -20px auto !important;
}

.carousel-3d-container {
  .carousel-3d-slide {
    border: 4px solid #ffffff;
    // box-shadow: 0 20px 50px rgba(0,0,0,0.8);

    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border-radius: 0.5rem;
    background-color: rgb(255, 255, 255);
    opacity: 1 !important;

    .title {
      font-size: 22px;
    }
  }

  .carousel-3d-controls {
    .next, .prev {
      color: #0ee097
    }


  }
}
</style>

<style scoped src="@/assets/styles/theme.css"></style>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"></style>

<style>
.add-icon-dropdown .btn-check:focus + .btn,
.add-icon-dropdown .btn:focus {
  outline: none;
  box-shadow: none;
}

.add-icon-dropdown .dropdown-toggle {
  height: 100%;
}

.activeBlur {
  filter: brightness(100%) blur(3px);
  cursor: not-allowed;
}


</style>

<style scoped>

.widget-float-btn {
  opacity: 1;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  position: fixed;
  /* right: 1%; */
  height: 50px;
  width: 50px;
  top: 10px;

  transition: all .2s;
  background: #fff;
  border-radius: 50px;
  padding: 0 20px !important;
  overflow: hidden;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.widget-float-open {
  width: 100px;
  border-radius: 50px;
}

.widget-float-close {
  height: 50px;
  /* padding: 5px 5px !important; */
}

.widget-float-ltr {
  left: 10px !important;
}

.widget-float-rtl {
  right: 10px !important;
}

.app-pagination {
  opacity: 1;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  position: fixed;
  /* right: 1%; */
  width: 100px;
  bottom: 2%;
  transition: all .2s;
  background: #fff;
  border-radius: 20px;
  padding: 20px 5px !important;
  overflow: hidden;
  z-index: 20;
  display: flex;
  align-items: center;
  align-content: center;
}

.pagination-ltr {
  right: 1%;
}

.pagination-rtl {
  left: 1%;
}


.pagination-open {
  height: 35%;
}

.pagination-close {
  height: 50px;
  padding: 5px 5px !important;
}

.ps {
  height: 100%;
}
</style>  

