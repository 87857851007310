import { render, staticRenderFns } from "./formGenerator.vue?vue&type=template&id=2dac0bb9&scoped=true&"
import script from "./formGenerator.vue?vue&type=script&lang=js&"
export * from "./formGenerator.vue?vue&type=script&lang=js&"
import style0 from "./formGenerator.vue?vue&type=style&index=0&lang=css&"
import style1 from "./formGenerator.vue?vue&type=style&index=1&id=2dac0bb9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dac0bb9",
  null
  
)

export default component.exports