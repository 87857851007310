<template>
  <div>
    <dataPickerModal></dataPickerModal>
    <b-modal
      :dir="dict.dir[lang]"
      id="create-marker"
      size="lg"
      centered
      no-close-on-backdrop
      header-border-variant="primary"
      header-class="my-modal-header"
      scrollable
      @ok="saveData"
      @shown="initModal"
      :ok-title="dict.ok[lang]"
      :cancel-title="dict.cancel[lang]"
      @hidden="resetAll"
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]">
          <h5>{{ dict.title[lang] }}</h5>
        </div>
      </template>
      <div id="markerValid">
        <div class="p-3">
          <ul class="mga-tab-nav mga-tab-nav-tabs d-md-flex d-block">
            <li
              v-for="(item, index) in tabs"
              class="mga-tab-nav-item"
              @click="activeTab = item.value"
              :key="index"
            >
              <a
                class="mga-tab-nav-link"
                :class="{ active: activeTab == item.value }"
                data-toggle="tab"
                role="tab"
                aria-controls="tab1"
                aria-selected="true"
              >
                {{ item[lang] }}
              </a>
            </li>
          </ul>
        </div>
        <div class="px-2" v-show="activeTab == 'general'">
          <div class="col-md-12">
            <div class="row mx-auto mt-4">
              <div class="col-md-12 col-12 px-3">
                <ejs-textbox
                  :autocomplete="'off'"
                  floatLabelType="Auto"
                  v-model="schema.name"
                  :placeholder="dict.markerName[lang]"
                  :enableRtl="lang == 'per'"
                ></ejs-textbox>
              </div>
              <div class="col-md-6 col-12 px-3">
                <ejs-textbox
                  :autocomplete="'off'"
                  floatLabelType="Auto"
                  v-model="schema.properties.name"
                  :placeholder="dict.pop[lang]"
                  :enableRtl="lang == 'per'"
                ></ejs-textbox>
              </div>
              <div class="col-12 col-md-6">
                <ejs-textbox
                  :autocomplete="'off'"
                  floatLabelType="Auto"
                  v-model="schema.properties.locationProperty"
                  :placeholder="dict.location[lang]"
                  :enableRtl="lang == 'per'"
                />
              </div>
              <div class="col-12 col-md-6">
                <ejs-textbox
                  :autocomplete="'off'"
                  floatLabelType="Auto"
                  v-model="schema.properties.width"
                  :placeholder="dict.width[lang]"
                  :enableRtl="lang == 'per'"
                  type="number"
                />
              </div>
              <div class="col-12 col-md-6">
                <ejs-textbox
                  :autocomplete="'off'"
                  floatLabelType="Auto"
                  v-model="schema.properties.height"
                  :placeholder="dict.height[lang]"
                  :enableRtl="lang == 'per'"
                  type="number"
                />
              </div>
            </div>
            <div
              class="row col-md-12 mx-auto mt-4"
              :class="{
                'justify-content-md-start': lang == 'per',
                'justify-content-md-start': lang == 'eng',
              }"
            >
              <div
                :class="{
                  'col-md-1 col-3 mx-2 border-2 border-red border m-2 p-2 cursor-pointer': true,
                  'border-success border-2 border': item.address == selected,
                }"
                @click="chooseIcon(item)"
                v-for="(item, index) in iconList"
                :key="index"
              >
                <component
                  v-if="item.component"
                  :is="item.component"
                  color="black"
                ></component>
                <img v-else :src="item.address" alt="" class="img-fluid" />
              </div>
            </div>
            <div
              class="row col-md-12 mx-auto mt-3"
              :class="{
                'justify-content-md-start': lang == 'per',
                'justify-content-md-start': lang == 'eng',
              }"
            >
              <div
                :class="{
                  'col-md-1 col-3 mx-2 border-2 border-red border m-2 p-2 cursor-pointer': true,
                  'border-success border-2 border': item.address == selected,
                }"
                @click="chooseIcon(item)"
                v-for="(item, index) in fireMarkers"
                :key="index"
              >
                <component
                  v-if="item.component"
                  :is="item.component"
                  color="black"
                ></component>
                <img v-else :src="item.address" alt="" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div class="px-3" v-show="activeTab == 'shape'">
          <div class="p-3">
            <ejs-switch
              @change="
                (val) => {
                  schema.variableIcon.enable = val.checked;
                }
              "
              :value="schema.variableIcon.enable"
              :checked="schema.variableIcon.enable"
            />
            <span class="mx-2" style="font-size: 14px">{{
                dict.onClickVar[lang]
              }}</span>
          </div>
          <b-card class="mt-4" v-if="schema.variableIcon.enable">
            <div class="row">
              <div class="col-md-6">
                <ejs-textbox
                  :autocomplete="'off'"
                  floatLabelType="Auto"
                  :placeholder="dict.page[lang]"
                  type="text"
                  v-model="schema.variableIcon.propertyName"
                ></ejs-textbox>
              </div>
            </div>
            <b-table
              class="mt-5"
              hover
              stacked="md"
              small
              :items="schema.variableIcon.shapes"
              :fields="dataFields"
              ref="alertTable"
            >
              <template v-slot:cell(icon)="row">
                <img
                  style="width: 30px"
                  v-if="row.item.icon != ''"
                  :src="row.item.icon.address"
                  alt=""
                  class="img-fluid"
                />
              </template>
              <template v-slot:cell(value)="row">
                <div>
                  <ejs-textbox
                    :autocomplete="'off'"
                    floatLabelType="Auto"
                    type="'text'"
                    v-model="row.item.value"
                  ></ejs-textbox>
                </div>
              </template>
              <template v-slot:cell(action)="row">
                <div
                  class="gap-3 mt-2"
                  style="display: flex; justify-content: flex-end"
                >
                  <span
                    @click="openShapes(row, false)"
                    class="e-icons e-image-2"
                    style="font-size: 19px"
                  ></span>
                  <span
                    class="e-icons e-delete-1 text-danger"
                    style="font-size: 19px"
                    @click="deleteData(row.item)"
                  ></span>
                </div>
              </template>
            </b-table>
            <i class="reset" @click="newRowTable">
              <font-awesome-icon icon="fas fa-plus-square" class="arrow-down" />
            </i>
          </b-card>
        </div>
        <div class="px-3" v-show="activeTab == 'action'">
          <div class="p-3">
            <ejs-switch
              @change="EnableClick"
              :value="schema.onClick.enable"
              :checked="schema.onClick.enable"
            />
            <span class="mx-2" style="font-size: 14px">{{
                dict.onClick[lang]
              }}</span>
          </div>
          <div class="row mx-auto" v-if="schema.onClick.enable == true">
            <div class="col-md-6 col-12">
              <ejs-dropdownlist
                :dataSource="typeList"
                :value="schema.onClick.type"
                :fields="{ text: lang, value: 'value' }"
                :placeholder="dict.clickType[lang]"
                :enableRtl="lang === 'per'"
                @change="
                  (val) => {
                    schema.onClick.type = val.value;
                  }
                "
              ></ejs-dropdownlist>
            </div>
            <div class="col-md-6 col-12" v-if="schema.onClick.type != ''">
              <ejs-textbox
                :value="schema.onClick.value"
                v-model="schema.onClick.value"
                :placeholder="dict.page[lang]"
                :enableRtl="lang === 'per'"
                type="text"
                :required="true"
              ></ejs-textbox>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="shape-selector"
      size="lg"
      :dir="dict.dir[lang]"
      centered
      hide-header-close
      :title="dict.markerIcon[lang]"
      header-border-variant="primary"
      header-class="my-modal-header"
      hide-footer
    >
      <div
        class="row col-md-12 mx-auto mt-4"
        :class="{
          'justify-content-center': true,
          'justify-content-md-end': lang == 'per',
          'justify-content-md-start': lang == 'eng',
        }"
      >
        <div
          :class="{
            'col-md-1 col-3 mx-2 border-2 border-red border m-2 p-2 cursor-pointer': true,
            'border-success border-2 border': item.name == file,
          }"
          @click="submitIcon(item)"
          v-for="(item, index) in iconList"
          :key="index"
        >
          <component
            v-if="item.component"
            :is="item.component"
            color="black"
          ></component>
          <img v-else :src="item.address" alt="" class="img-fluid" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DataPickerModal from "@/components/dataManagerModalDataPicker.vue";
import L from "leaflet";
import { v4 } from "uuid";
import { FormValidator } from "@syncfusion/ej2-inputs";

export default {
  name: "markerCreator",
  components: { DataPickerModal },
  props: {
    information: {
      default: {}
    },
    latLng: {
      default: {}
    },
    value: {
      default: false
    },
    type: {
      default: ""
    }
  },
  data() {
    return {
      formsList: [],
      tabs: [
        { per: "تنظیمات عمومی", eng: "general settings", value: "general" },
        { per: "مارکرهای متغیر", eng: "variable markers", value: "shape" },
        { per: "عملیات مارکر", eng: "marker click", value: "action" }
      ],
      selectedId: "",
      typeList: [
        { per: "یک مورد انتخاب کنید", eng: "Select One", value: null },
        { per: "تغییر صفحه", eng: "change page", value: "page" },
        { per: "افزودن فرم", eng: "assign form", value: "form" }
      ],
      activeTab: "general",
      dict: {
        markerIcon: { eng: "marker icon", per: "آیکن مارکر" },
        page: { eng: "property name", per: "نام پراپرتی" },
        clickType: { eng: "action type", per: "نوع عملیات" },
        label: { eng: "data", per: "داده" },
        pop: { eng: "marker title property", per: "نام متغیر عنوان مارکر" },
        markerName: { eng: "marker type name", per: "نام نوع مارکر" },
        dir: { eng: "ltr", per: "rtl" },
        title: { per: "ایجاد مارکر", eng: "create marker" },
        titleInput: { per: "عنوان", eng: "title" },
        width: { per: "عرض", eng: "width" },
        height: { per: "طول", eng: "height" },
        location: { per: "نام متغیر نقطه جغرافیایی", eng: "location property" },
        latitude: { per: "عرض جغرافیایی", eng: "latitude" },
        longtitude: { per: "طول جغرافیایی", eng: "longtitude" },
        draggable: { per: "قابلیت حرکت", eng: "draggable" },
        onClick: { per: "فعال کردن عملیات مارکر", eng: "enable action" },
        onClickVar: {
          per: "فعال کردن لیست مقادیر مارکر",
          eng: "enable variable markers"
        },
        shapeTab: { per: "آیکن مارکر", eng: "shape" },
        tooltipTab: { per: "متن نمایش", eng: "tooltip" },
        pathTab: { per: "مسیرحرکت", eng: "path" },
        generalTab: { per: "تنظیمات", eng: "general" },
        mobile: { per: "متحرک", eng: "mobile" },
        stationary: { per: "ثابت", eng: "stationary" },
        status: { per: "وضعیت مارکر", eng: "status" },
        shapeType: { per: "نوع ایکن", eng: "icon type" },
        variable: { per: "ایکن متفیر", eng: "variable icon" },
        constant: { per: "ایکن ثابت", eng: "constant icon" },
        modalTitle: { per: "تخصیص داده", eng: "set data" },
        ok: { per: "تایید", eng: "Ok" },
        cancel: { per: "لغو", eng: "Cancel" }
      },
      curData: { name: "" },
      popups: "",
      iconPic: "",
      selected: "",
      hasStroke: false,
      updatePage: 0,
      iconForDefValue: false,
      schema: {
        name: "",
        properties: {
          width: "",
          height: "",
          locationProperty: "",
          icon: "",
          name: ""
        },
        variableIcon: {
          enable: false,
          shapes: [],
          propertyName: ""
        },
        onClick: {
          enable: false,
          value: "",
          type: ""
        }
      },
      currentMarkerData: {
        range: {
          min: "",
          max: "",
          state: null,
          term: ""
        },
        type: ""
      },
      iconList: [
        {
          name: "fire_sensor1.png",
          address: require("@/assets/images/fire_sensor1.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_sensor2.png",
          address: require("@/assets/images/fire_sensor2.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_sensor3.png",
          address: require("@/assets/images/fire_sensor3.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_sensor4.png",
          address: require("@/assets/images/fire_sensor4.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire station01.png",
          address: require("@/assets/images/traffic/fire station01.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_station00.png",
          address: require("@/assets/images/traffic/fire_station00.png"),
          iconSize: [30, 45]
        },
        {
          name: "traffic_light07.png",
          address: require("@/assets/images/traffic/traffic_light07.png"),
          iconSize: [30, 45]
        },
        {
          name: "traffic_light06.png",
          address: require("@/assets/images/traffic/traffic_light06.png"),
          iconSize: [30, 45]
        },
        {
          name: "traffic_light05.png",
          address: require("@/assets/images/traffic/traffic_light05.png"),
          iconSize: [30, 45]
        },
        {
          name: "traffic_light04.png",
          address: require("@/assets/images/traffic/traffic_light04.png"),
          iconSize: [30, 45]
        },
        {
          name: "hydrant1.png",
          address: require("@/assets/images/traffic/hydrant1.png"),
          iconSize: [30, 45]
        },
        {
          name: "hydrant2.png",
          address: require("@/assets/images/traffic/hydrant2.png"),
          iconSize: [30, 45]
        },
        {
          name: "gray.png",
          address: require("@/assets/images/mapIcons/gray.png"),
          iconSize: [30, 30]
        },
        {
          name: "green.png",
          address: require("@/assets/images/mapIcons/green.png"),
          iconSize: [30, 30]
        },
        {
          name: "fire_marker_fire.png",
          address: require("@/assets/images/photo_4.gif"),
          iconSize: [30, 45]
        },
        {
          name: "fire_marker_blue.png",
          address: require("@/assets/images/mapIcons/fire_marker_blue.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_marker_yellow.png",
          address: require("@/assets/images/mapIcons/fire_marker_yellow.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_marker_green.png",
          address: require("@/assets/images/mapIcons/fire_marker_green.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_marker_gray.png",
          address: require("@/assets/images/mapIcons/fire_marker_gray.png"),
          iconSize: [30, 45]
        },
        {
          name: "fire_marker_red.png",
          address: require("@/assets/images/mapIcons/fire_marker_red.png"),
          iconSize: [30, 45]
        }
      ],
      fireMarkers: [
        {
          name: "fireTrcuk3.png",
          address: require("@/assets/images/cars/fireTrcuk3.png"),
          iconSize: [30, 45]
        },
        {
          name: "fireTrcuk2.png",
          address: require("@/assets/images/cars/fireTrcuk2.png"),
          iconSize: [30, 45]
        },
        {
          name: "fireTrcuk1.png",
          address: require("@/assets/images/cars/fireTrcuk1.png"),
          iconSize: [30, 45]
        },
        {
          name: "fireTrcuk4.png",
          address: require("@/assets/images/cars/fireTrcuk4.png"),
          iconSize: [30, 45]
        },
        {
          name: "van.png",
          address: require("@/assets/images/cars/van.png"),
          iconSize: [30, 45]
        },
        {
          name: "kamiunet.png",
          address: require("@/assets/images/cars/kamiunet.png"),
          iconSize: [30, 45]
        },
        {
          name: "neisan.png",
          address: require("@/assets/images/cars/neisan.png"),
          iconSize: [30, 45]
        },
        {
          name: "ambulanc.png",
          address: require("@/assets/images/cars/ambulanc.png"),
          iconSize: [30, 45]
        },
        {
          name: "jaro_kesh.png",
          address: require("@/assets/images/cars/jaro_kesh.png"),
          iconSize: [30, 45]
        }
      ],
      pathFields: {
        stroke: [
          { value: "", name: "color", type: "color" },
          { value: "", name: "weight", type: "number" },
          { value: "", name: "opacity", type: "number" },
          { value: "", name: "lineCap", type: "select" },
          { value: "", name: "lineJoin", type: "select" }
        ],
        fill: [
          { value: "", name: "fillColor", type: "color" },
          { value: "", name: "fillOpacity", type: "number" },
          { value: "", name: "fillRule", type: "select" }
        ]
      },
      lineCap: [
        { value: "butt", text: "butt" },
        { value: "square", text: "square" },
        { value: "round", text: "round" }
      ],
      fillRule: [
        { value: "evenodd", text: "evenodd" },
        { value: "nonzero ", text: "nonzero " }
      ],
      lineJoin: [
        { value: "arcs", text: "arcs" },
        { value: "bevel", text: "bevel" },
        { value: "miter", text: "miter" },
        { value: "miter-clip", text: "miter-clip" },
        { value: "round", text: "round" }
      ],
      shapeType: "constant icon",
      dataFields: [
        { key: "value", label: "value" },
        { key: "icon", label: "icon" },
        { key: "action", label: "" }
      ],
      triggerList: [
        { text: "Select One", value: null },
        { text: "True", value: true },
        { text: "False", value: false }
      ],
      selectedRow: {},
      list: [],
      chosenIconUrl: {},
      file: "",
      pageMode: "New"
    };
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    dataType() {
      return this.$store.state.chpge.dataTypeForMap;
    },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    child() {
      return this.$store.state.chpge.child2child;
    },
    marker() {
      return this.$store.state.data.currentMarker;
    }
  },
  methods: {
    EnableClick(val) {
      this.schema.onClick.enable = val.checked;
    },
    initModal() {
      this.activeTab = "general";
    },
    chooseIcon(item) {
      this.schema.properties.icon = item.address;
      this.selected = item.address;
    },
    submitIcon(item) {
      if (!this.iconForDefValue) {
        if (item["component"]) {
          this.schema.variableIcon.shapes.find(
            (val) => val.id == this.selectedRow.id
          ).icon = {
            address: item.address,
            name: item.name
          };
        } else {
          this.schema.variableIcon.shapes.find(
            (val) => val.id == this.selectedRow.id
          ).icon = item;
        }
      } else {
        this.schema.variableIcon.defaultValue.icon = {
          address: item.address,
          name: item.name
        };
      }

      this.$bvModal.hide("shape-selector");
    },
    saveData($bv) {
      $bv.preventDefault();
      const isValid = new FormValidator("#markerValid").validate();

      if (isValid) {
        if (this.pageMode == "New") {
          this.$store.dispatch("data/createMarkerType", {
            name: this.schema.name,
            details: {
              properties: {
                width: this.schema.properties.width,
                height: this.schema.properties.height,
                icon: this.selected,
                locationProperty: this.schema.properties.locationProperty,
                name: this.schema.properties.name
              },
              variableIcon: {
                enable: this.schema.variableIcon.enable,
                shapes: this.schema.variableIcon.shapes,
                propertyName: this.schema.variableIcon.propertyName
              },
              onClick: this.schema.onClick
            }
          })
            .then((res) => {
              this.selected = "";
              this.$store.dispatch("chpge/child2parent", {
                task: "markerNewType"
              });
              this.$nextTick(() => {
                this.$bvModal.hide("create-marker");
              });
              this.resetForSub();
            });

        } else {

          const update = {
            _id: this.selectedId,
            name: this.schema.name,
            details: {
              properties: {
                width: this.schema.properties.width,
                height: this.schema.properties.height,
                icon: this.selected,
                locationProperty: this.schema.properties.locationProperty,
                name: this.schema.properties.name
              },
              variableIcon: {
                enable: this.schema.variableIcon.enable,
                shapes: this.schema.variableIcon.shapes,
                propertyName: this.schema.variableIcon.propertyName
              },
              onClick: this.schema.onClick
            }
          };
          this.$store.dispatch("data/updateMarkerType", { updates: update }).then((res) => {
            this.selected = "";
            this.$store.dispatch("chpge/child2parent", {
              task: "markerNewType"
            });
            this.$nextTick(() => {
              this.$bvModal.hide("create-marker");
            });
            this.resetForSub();
          });
        }
      }
    },
    openShapes(row, isDefault) {
      this.iconForDefValue = isDefault;
      if (!isDefault) this.selectedRow = row.item;
      this.$bvModal.show("shape-selector");
    },
    resetAll() {
      if (this.type != "edit") {
        this.iconPic = "";
        this.schema.variableIcon.shapes = [];
        this.selectedRow = {};
        this.schema = {
          name: "",
          properties: {
            width: "",
            height: "",
            icon: "",
            locationProperty: "",
            name: ""
          },
          variableIcon: {
            enable: false,
            shapes: [],
            propertyName: ""
          },
          onClick: {
            enable: false,
            value: "",
            type: ""
          }
        };
      }
    },
    resetForSub() {
      this.iconPic = "";
      this.schema.variableIcon.shapes = [];
      this.selectedRow = {};
      this.selected = "";
      this.chosenIconUrl = {};
      this.schema = {
        name: "",
        properties: {
          width: "",
          height: "",
          icon: "",
          locationProperty: "",
          name: ""
        },
        variableIcon: {
          enable: false,
          shapes: [],
          propertyName: ""
        },
        onClick: {
          enable: false,
          value: "",
          type: ""
        }
      };
    },
    deleteData(item) {
      this.schema.variableIcon.shapes.splice(
        this.schema.variableIcon.shapes.indexOf(item),
        1
      );
    },
    newRowTable() {
      this.schema.variableIcon.shapes.push({
        value: "",
        icon: "",
        id: v4()
      });
    }
  },
  watch: {
    dataType(val) {
      this.currentMarkerData.type = val;
    },
    parent2child(newValue) {
      if (newValue.task == "markerEditType") {
        this.pageMode = "Edit";
        this.selectedId = newValue.id;
        this.selected = newValue.details.properties.icon;
        this.schema.name = newValue.name;
        this.schema.properties = newValue.details.properties;
        this.schema.variableIcon = newValue.details.variableIcon;
        this.schema.onClick = newValue.details.onClick;
      } else if (newValue.task == "markerNewType") {
        this.pageMode = "New";
      }
    }
  }
};
</script>

<style>
/* Icon styling */
.prefix-icon {
  color: #474bff; /* Color of the icon */
  font-size: 18px; /* Adjust size */
  cursor: pointer !important;
}
</style>
