<template>
  <div>
    <ejs-grid
      ref="grid"
      :width="'auto'"
      :locale="lang"
      :key="items.length"
      :allowSorting="true"
      :enableAdaptiveUI="true"
      :allowExcelExport="true"
      :allowFiltering="true"
      :editSettings="editSettings"
      :allowPdfExport="true"
      :toolbar="toolabrGenerator"
      :toolbarClick="MaptoolbarClick"
      :actionBegin="mapActionBegin"
      :allowPaging="true"
      :enableRtl="lang == 'per'"
      :pageSettings="{ pageSize: 5, pageSizes: [5, 10, 20, 50] }"
      :selectionSettings="selectionOptions"
      :dataSource="items"
    >
      <e-columns>
        <e-column width="50" type="checkbox"></e-column>
        <e-column
          v-for="(item, index) in fields"
          :key="index"
          :field="item.key"
          :headerText="item[lang]"
          :template="item.hasTemplate ? item.key : null"
        ></e-column>
      </e-columns>
      <template v-slot:modify="{ data }">
        <div class="d-flex justify-content-center">
          <span
            class="e-icons e-edit-6 mx-3"
            style="font-size: 17px"
            @click="editType(data)"
          ></span>
          <span
            class="e-icons e-delete-1 mx-3 text-danger"
            style="font-size: 17px"
            @click="deleteMarkerType(data)"
          ></span>
        </div>
      </template>
      <!--      <template v-slot:icon="{ data }">-->
      <!--      <div>-->
      <!--        <img src="@/assets/images/traffic/fire_station00.png" class="img-fluid" :width="100" alt="">-->
      <!--      </div>-->
      <!--      </template>-->
    </ejs-grid>
    <marker-creator ref="createMarker" />
  </div>
</template>

<script>
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";
import MarkerCreator from "@/components/markerCreator.vue";
import { v4 } from "uuid";
import { DialogUtility } from "@syncfusion/ej2-popups";

export default {
  name: "markerTypeCrud",
  components: { MarkerCreator },
  data() {
    return {
      fields: [
        {
          key: "name",
          eng: "name",
          per: "نام",
          hasTemplate: false
        },
        { key: "modify", eng: "", per: "", hasTemplate: true }
      ],
      items: [],
      selectionOptions: {
        type: "Multiple",
        persistSelection: false,
        checkboxOnly: true
      },
      editSettings: {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog"
      },
      dict: {
        refresh: { eng: "refresh datas", per: "به روزرسانی اطلاعات" },
        confirmTitle: { eng: "close confirmation", per: "تایید بستن" },
        confirmRemoveTitle: { eng: "remove confirmation", per: "تایید حذف" },
        confirmText: {
          eng: "Are you sure to remove this record?",
          per: "آیا از حذف این رکورد اطمینان دارید؟"
        },
        dir: { eng: "ltr", per: "rtl" },
        newMark: {
          eng: "New marker",
          per: "مارکر جدید"
        },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        deleteConfirmContent: {
          eng: "Are you sure to remove the data ?",
          per: "آیا از حذف این رکورد اطمینان دارید ؟"
        }
      }
    };
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    toolabrGenerator() {
      return [
        "Search",
        {
          text: this.dict.newMark[this.lang],
          id: "New_markerType",
          prefixIcon: "e-icons e-add"
        },
        {
          text: this.dict.refresh[this.lang],
          id: "refresh_table",
          prefixIcon: "e-icons e-refresh-2"
        }
      ];
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    updatePage() {
      return this.$store.state.chpge.reload;
    }
  },
  methods: {
    MaptoolbarClick(args) {
      if (args.item.id.includes("New_markerType")) {
        this.$store.dispatch("chpge/parent2child", {
          task: "markerNewType"
        });
        this.$bvModal.show("create-marker");
      } else this.getAllType();
    },
    mapActionBegin(args) {
    },
    editType(args) {
      this.$store.dispatch("chpge/parent2child", {
        task: "markerEditType",
        id: args._id,
        name: args.name,
        details: args.details
      });
      this.$bvModal.show("create-marker");
    },
    deleteMarkerType(data) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            let founded = this.items.find((item) => item.id == data.id);
            this.$store.dispatch("data/removeMarkerType", { _id: founded._id }).then(() => {
              this.getAllType();
            });
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }
    },
    getAllType() {
      this.$store.dispatch("data/getAllMarkerTypes").then(res => {
        this.items = res;
      });
    }
  },
  watch: {
    child2parent(args) {
      if (args.task == "markerNewType")
        this.getAllType();
      else if (args.task == "markerEditType") {
        let founded = this.items.find(item => item.id == args.id);
        founded.name = args.name;
        founded.details = args.details;
        this.$refs.grid.ej2Instances.refresh();
      }
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, PdfExport, ExcelExport, Edit, Search]
  },
  mounted() {
    this.getAllType();
  }
};
</script>

<style scoped></style>
