<template>
  <div
    :key="renderTime"
    :dir="dict.dir[lang]"
    :style="{ 'text-align': lang == 'per' ? 'right' : 'left' }"
  >
    <div id="setContHttps">
      <b-container fluid>
        <b-row class="my-4">
          <b-col cols="12" sm>
            <b-card no-body>
              <b-card-body>
                <b-row>
                  <b-col cols="8" lg="11" md="10" sm="9">
                    <h5 style="flex: 90%">{{ dict[type][lang] }}</h5>
                  </b-col>
                  <b-col cols="4" lg="1" md="2" sm="3" v-if="registred">
                    <div
                      style="
                        font-size: 24px;
                        display: flex;
                        justify-content: space-around;
                      "
                    >
                      <i @click="handleRegister">
                        <font-awesome-icon
                          icon="fas fa-check-square"
                          class="apply"
                        />
                      </i>
                      <i @click="onReset">
                        <font-awesome-icon
                          icon="fas fa-xmark-square"
                          class="reset"
                        />
                      </i>
                    </div>
                  </b-col>
                  <b-col cols="4" lg="1" md="2" sm="3" v-if="!registred">
                    <div
                      style="
                        font-size: 24px;
                        display: flex;
                        justify-content: space-around;
                      "
                    >
                      <i @click="onReset">
                        <font-awesome-icon
                          icon="fas fa-xmark-square"
                          class="reset"
                        />
                      </i>
                    </div>
                  </b-col>
                </b-row>

                <hr class="my-2" />

                <b-card no-body class="p-3">
                  <ul v-if="registred" class="mga-tab-nav mga-tab-nav-tabs">
                    <li
                      v-for="(item, index) in tabs"
                      class="mga-tab-nav-item"
                      @click="activeTab = item.value"
                      :key="index"
                    >
                      <a
                        class="mga-tab-nav-link"
                        :class="{ active: activeTab === item.value }"
                        :id="item.name"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="tab1"
                        aria-selected="true"
                      >
                        {{ dict.tabs[item.value][lang] }}
                      </a>
                    </li>
                  </ul>
                  <ul v-else class="mga-tab-nav mga-tab-nav-tabs">
                    <li
                      class="mga-tab-nav-item"
                      @click="activeTab = 'ConnectionData'"
                      :key="index"
                    >
                      <a
                        class="mga-tab-nav-link"
                        :class="{ active: activeTab === 'ConnectionData' }"
                        :id="'basic'"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="tab1"
                        aria-selected="true"
                      >
                        {{ dict.tabs.ConnectionData[lang] }}
                      </a>
                    </li>
                  </ul>

                  <transition :name="dict.transition[lang]" mode="out-in">
                    <div v-if="registred">
                      <div
                        v-show="activeTab == 'ConnectionData'"
                        class="row py-4"
                      >
                        <div class="col-md-4 row col-12 my-3">
                          <div class="col-md-9 col-10">
                            <ejs-textbox
                              :value="name"
                              :readOnly="false"
                              v-model="name"
                              :placeholder="dict.ConnectionData.name[lang]"
                              :enableRtl="lang === 'per'"
                              @input="
                                (val) => {
                                  name = val.value
                                }
                              "
                              :required="true"
                              :maxLength="100"
                              :minLength="3"
                            >
                            </ejs-textbox>
                          </div>
                          <div class="col-md mb-0 col-2 pb-0">
                            <b-button
                              size="sm"
                              class="btn-sm"
                              @click="copyToClipboard(name)"
                            >
                              <font-awesome-icon icon="fas fa-copy" />
                            </b-button>
                          </div>
                        </div>

                        <div class="col-md-4 col-12 my-3">
                          <component
                            :is="'DropDownList'"
                            :id="'ConnectionData_mapType_input'"
                            :key="'ConnectionData_mapType_input'"
                            :dataSource="mapTypeList"
                            :value="mapType"
                            :enabled="false"
                            :fields="{ text: 'text', value: 'value' }"
                            :placeholder="dict.ConnectionData.mapType[lang]"
                            :enableRtl="lang === 'per'"
                            @change="
                              (val) => {
                                mapType = val;
                              }
                            "
                            :validatorRules="{ required: true }"
                          ></component>
                        </div>

                        <div class="col-md-4 row col-12 my-3">
                          <div class="col-md-9 col-10">
                            <component
                              :is="'TextBox'"
                              :id="'ConnectionData_route_input'"
                              :key="'ConnectionData_route_input'"
                              :value="connection.host"
                              :readOnly="false"
                              :placeholder="dict.ConnectionData.route[lang]"
                              :enableRtl="lang === 'per'"
                              @change="
                                (val) => {
                                  connection.host = val;
                                }
                              "
                              :validatorRules="{
                                required: true,
                                maxLength: 100,
                                minLength: 3,
                              }"
                            ></component>
                          </div>
                          <div class="col-md mb-0 pb-0 col-2">
                            <b-button
                              size="sm"
                              class="btn-sm"
                              @click="copyToClipboard(connection.host)"
                            >
                              <font-awesome-icon icon="fas fa-copy" />
                            </b-button>
                          </div>
                        </div>

                        <div class="col-md-4 row col-12 my-3">
                          <div class="col-md-9 col-10">
                            <component
                              :is="'TextBox'"
                              :id="'ConnectionData_clientId_input'"
                              :key="'ConnectionData_clientId_input'"
                              :value="connection.clientId"
                              :readOnly="false"
                              :placeholder="dict.ConnectionData.clientId[lang]"
                              :enableRtl="lang === 'per'"
                              @change="
                                (val) => {
                                  connection.clientId = val;
                                }
                              "
                              :validatorRules="{
                                required: true,
                                maxLength: 100,
                                minLength: 3,
                              }"
                            ></component>
                          </div>
                          <div class="col-md mb-0 pb-0 col-2">
                            <b-button
                              size="sm"
                              class="btn-sm"
                              @click="copyToClipboard(connection.clientId)"
                            >
                              <font-awesome-icon icon="fas fa-copy" />
                            </b-button>
                          </div>
                        </div>

                        <div class="form-check col-md-4 form-switch mt-4 mb-2 mx-2">
                          <input
                            v-model="connection.onOffStatus"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckChecked"
                          >status</label
                          >
                        </div>
                      </div>

                      <div v-show="activeTab == 'security'" class="row py-4">
                        <div class="col-md-4 my-3 row col-12">
                          <div class="col-md-9 col-10">
                            <ejs-textbox
                              floatLabelType="Auto"
                              :value="security.basic.user"
                              :placeholder="dict.security.user[lang]"
                              :enableRtl="lang === 'per'"
                              @input="
                                (val) => {
                                  security.basic.user = val.value;
                                }
                              "
                              :required="true"
                              :maxLength="100"
                              :minLength="3"
                            >
                            </ejs-textbox>
                          </div>
                          <div class="col-md col-2 mb-0 pb-0">
                            <b-button
                              size="sm"
                              class="btn-sm"
                              @click="copyToClipboard(security.basic.user)"
                            >
                              <font-awesome-icon icon="fas fa-copy" />
                            </b-button>
                          </div>
                        </div>

                        <div class="col-md-4 my-3 row col-12">
                          <div class="col-md-9 col-10">
                            <ejs-textbox
                              floatLabelType="Auto"
                              :value="security.basic.pass"
                              :placeholder="dict.security.pass[lang]"
                              :enableRtl="lang === 'per'"
                              :type="!newGenerate ? 'password' : 'text'"
                              @input="
                                (val) => {
                                  security.basic.pass = val.value;
                                }
                              "
                              :required="true"
                              :maxLength="100"
                              :minLength="3"
                            >
                            </ejs-textbox>
                          </div>
                          <div class="col-md col-2 mb-0 pb-0">
                            <b-button
                              class="btn-sm"
                              v-if="newGenerate"
                              @click="copyToClipboard(security.basic.pass)"
                            >
                              <font-awesome-icon icon="fas fa-copy" />
                            </b-button>
                            <b-button
                              class="btn-sm"
                              v-if="!newGenerate"
                              @click="generateRandomString(10)"
                            >
                              <font-awesome-icon icon="fas fa-refresh" />
                            </b-button>
                          </div>
                        </div>


                        <div class="col-md-4 my-3 row col-12">
                          <ejs-textbox
                            floatLabelType="Auto"
                            :value="security.basic.tokenExpireTime"
                            :placeholder="dict.security.token[lang]"
                            :enableRtl="lang === 'per'"
                            :type="'number'"
                            @input="
                                (val) => {
                                  security.basic.tokenExpireTime = val.value;
                                }
                              "
                            :required="true"
                          >
                          </ejs-textbox>
                        </div>


                      </div>

                      <div v-show="activeTab == 'getMapping'" class="row py-4">
                        <b-container fluid class="p-0">
                          <div
                            class="
                              fw-bolder
                              text-center
                              border
                              rounded
                              p-1
                              mb-2
                              text-light
                            "
                            style="
                              background-color: #a5b4fc;
                              width: 100%;
                              border-color: #a5b4fc !important;
                            "
                          >
                            {{ mapTypeText[lang] }}
                          </div>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border rounded-top border-bottom-0"
                          >
                            <b-col lg="2" sm="6" md="5" class="my-1">
                              <ejs-textbox
                                :value="getMapTable.filter"
                                :placeholder="dict.general.searchBox[lang]"
                                :enableRtl="lang === 'per'"
                                @input="
                                  (val) => {
                                    getMapTable.filter = val.value;
                                  }
                                "
                              ></ejs-textbox>
                            </b-col>

                            <b-col lg="1" sm="1" md="1" class="my-1">
                              <div
                                style="
                                  font-size: 24px;
                                  display: flex;
                                  justify-content: end;
                                "
                              >
                                <i @click="openNewUrl" class="reset">
                                  <font-awesome-icon
                                    icon="fas fa-plus-square"
                                  />
                                </i>
                              </div>
                            </b-col>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border border-top-0 border-bottom-0"
                          >
                            <b-table
                              :items="getMapping.JSON.items"
                              :fields="getMapTable.fields"
                              :current-page="getMapTable.currentPage"
                              :per-page="getMapTable.perPage"
                              :filter="getMapTable.filter"
                              :filter-included-fields="getMapTable.filterOn"
                              :sort-by.sync="getMapTable.sortBy"
                              :sort-desc.sync="getMapTable.sortDesc"
                              :sort-direction="getMapTable.sortDirection"
                              stacked="md"
                              show-empty
                              :empty-text="dict.emptyStr[lang]"
                              small
                              @filtered="getMapTable.onFiltered"
                              ref="mapPubJsonTable"
                            >
                              <template #head(url)="row">
                                {{ row.field[lang] }}
                              </template>
                              <template #head(interval)="row">
                                {{ row.field[lang] }}
                              </template>
                              <template #head(uponChange.value)="row">
                                {{ row.field[lang] }}
                              </template>
                              <template #cell(modify)="row">
                                <div
                                  style="
                                    display: flex;
                                    justify-content: flex-end;
                                  "
                                >
                                  <b-button
                                    pill
                                    size="sm"
                                    variant="outline-success"
                                    @click="
                                      editMapPubJsonItem(row.item, $event)
                                    "
                                    class="mr-2"
                                    style="
                                      outline: none !important;
                                      border: none;
                                      box-shadow: none;
                                    "
                                  >
                                    <b-icon
                                      icon="pencil-square"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                  <b-button
                                    pill
                                    size="sm"
                                    variant="outline-danger"
                                    @click="
                                      removeMapPubJsonItem(row.item, $event)
                                    "
                                    class="mr-2"
                                    style="
                                      margin-inline: 10px;
                                      outline: none !important;
                                      border: none;
                                      box-shadow: none;
                                    "
                                  >
                                    <b-icon
                                      icon="trash-fill"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                </div>
                              </template>
                            </b-table>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="
                              g-0
                              mb-0
                              p-2
                              border border-top-0
                              rounded-bottom
                            "
                          >
                            <b-col lg="2" sm="4" md="3" class="my-1">
                              <component
                                :is="'DropDownList'"
                                :id="'per_page_1'"
                                :dataSource="getMapTable.pageOptions"
                                :value="getMapTable.perPage"
                                :fields="{ text: 'text', value: 'value' }"
                                :placeholder="dict.general.perPage[lang]"
                                :enableRtl="lang === 'per'"
                                @change="
                                  (val) => {
                                    getMapTable.perPage = val;
                                  }
                                "
                              ></component>
                            </b-col>

                            <b-col lg="4" sm="6" md="5" class="my-1 mt-4">
                              <b-pagination
                                v-model="getMapTable.currentPage"
                                :total-rows="getMapTable.totalRows"
                                :per-page="getMapTable.perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                              ></b-pagination>
                            </b-col>
                          </b-row>
                        </b-container>
                      </div>


                      <div v-show="activeTab == 'postMapping'" class="row py-4">
                        <b-container fluid class="p-0">
                          <div
                            class="
                              fw-bolder
                              text-center
                              border
                              rounded
                              p-1
                              mb-2
                              text-light
                            "
                            style="
                              background-color: #a5b4fc;
                              width: 100%;
                              border-color: #a5b4fc !important;
                            "
                          >
                            {{ mapTypeText[lang] }}
                          </div>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border rounded-top border-bottom-0"
                          >
                            <b-col lg="2" sm="6" md="5" class="my-1">
                              <component
                                :is="'TextBox'"
                                :id="'searchBox_125'"
                                :value="postMapTable.filter"
                                :placeholder="dict.general.searchBox[lang]"
                                :enableRtl="lang === 'per'"
                                @change="
                                  (value) => {
                                    postMapTable.filter = value;
                                  }
                                "
                              ></component>
                            </b-col>

                            <b-col lg="1" sm="1" md="1" class="my-1">
                              <div
                                style="
                                  font-size: 24px;
                                  display: flex;
                                  justify-content: end;
                                "
                              >
                                <i @click="openNewUrl" class="reset">
                                  <font-awesome-icon
                                    icon="fas fa-plus-square"
                                  />
                                </i>
                              </div>
                            </b-col>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border border-top-0 border-bottom-0"
                          >
                            <b-table
                              :items="mappingPost.JSON.items"
                              :empty-text="dict.emptyStr[lang]"
                              :fields="postMapTable.fields"
                              :current-page="postMapTable.currentPage"
                              :per-page="postMapTable.perPage"
                              :filter="postMapTable.filter"
                              :filter-included-fields="postMapTable.filterOn"
                              :sort-by.sync="postMapTable.sortBy"
                              :sort-desc.sync="postMapTable.sortDesc"
                              :sort-direction="postMapTable.sortDirection"
                              stacked="md"
                              show-empty
                              small
                              @filtered="postMapTable.onFiltered"
                              ref="mapSubJsonTable"
                            >
                              <template #head(url)="row">
                                {{ row.field[lang] }}
                              </template>
                              <template #head(interval)="row">
                                {{ row.field[lang] }}
                              </template>
                              <template #head(uponChange.value)="row">
                                {{ row.field[lang] }}
                              </template>
                              <template #cell(modify)="row">
                                <div
                                  style="
                                    display: flex;
                                    justify-content: flex-end;
                                  "
                                >
                                  <b-button
                                    pill
                                    size="sm"
                                    variant="outline-success"
                                    @click="
                                      editMapPubJsonItem(row.item, $event)
                                    "
                                    class="mr-2"
                                    style="
                                      outline: none !important;
                                      border: none;
                                      box-shadow: none;
                                    "
                                  >
                                    <b-icon
                                      icon="pencil-square"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                  <b-button
                                    pill
                                    size="sm"
                                    variant="outline-danger"
                                    @click="
                                      removeMapPubJsonItem(row.item, $event)
                                    "
                                    class="mr-2"
                                    style="
                                      margin-inline: 10px;
                                      outline: none !important;
                                      border: none;
                                      box-shadow: none;
                                    "
                                  >
                                    <b-icon
                                      icon="trash-fill"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                </div>
                              </template>

                              <template #cell(data)="row">
                                <i v-if="row.item.data">{{
                                    row.item.data.name
                                  }}</i>
                              </template>
                            </b-table>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="
                              g-0
                              mb-0
                              p-2
                              border border-top-0
                              rounded-bottom
                            "
                          >
                            <b-col lg="2" sm="4" md="3" class="my-1">
                              <component
                                :is="'DropDownList'"
                                :id="'per_page_2'"
                                :dataSource="postMapTable.pageOptions"
                                :value="postMapTable.perPage"
                                :fields="{ text: 'text', value: 'value' }"
                                :placeholder="dict.general.perPage[lang]"
                                :enableRtl="lang === 'per'"
                                @change="
                                  (value) => {
                                    postMapTable.perPage = value;
                                  }
                                "
                              ></component>
                            </b-col>

                            <b-col lg="4" sm="6" md="5" class="my-1">
                              <b-pagination
                                v-model="postMapTable.currentPage"
                                :total-rows="postMapTable.totalRows"
                                :per-page="postMapTable.perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                              ></b-pagination>
                            </b-col>
                          </b-row>
                        </b-container>
                      </div>

                      <div class="row py-4" v-show="activeTab == 'status'">
                        <b-container fluid class="p-0">
                          <div
                            class="
                              fw-bolder
                              text-center
                              border
                              rounded
                              p-1
                              mb-2
                              text-light
                            "
                            style="
                              background-color: #a5b4fc;
                              width: 100%;
                              border-color: #a5b4fc !important;
                            "
                          >
                            {{ mapTypeText[lang] }}
                          </div>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border rounded-top border-bottom-0"
                          >
                            <b-col lg="2" sm="6" md="5" class="my-1">
                              <component
                                :is="'TextBox'"
                                :id="'searchBox_136'"
                                :value="statTableOptions.filter"
                                :placeholder="dict.general.searchBox[lang]"
                                :enableRtl="lang === 'per'"
                                @change="
                                  (value) => {
                                    statTableOptions.filter = value;
                                  }
                                "
                              ></component>
                            </b-col>

                            <b-col lg="1" sm="1" md="1" class="my-1">
                              <div
                                style="
                                  font-size: 24px;
                                  display: flex;
                                  justify-content: end;
                                "
                              >
                                <i @click="addStatJsonItem" class="reset">
                                  <font-awesome-icon
                                    icon="fas fa-plus-square"
                                  />
                                </i>
                              </div>
                            </b-col>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border border-top-0 border-bottom-0"
                          >
                            <b-table
                              :items="status.JSON.items"
                              :empty-text="dict.emptyStr[lang]"
                              :fields="statusHttpJsonFields"
                              :current-page="statTableOptions.currentPage"
                              :per-page="statTableOptions.perPage"
                              :filter="statTableOptions.filter"
                              :filter-included-fields="
                                statTableOptions.filterOn
                              "
                              :sort-by.sync="statTableOptions.sortBy"
                              :sort-desc.sync="statTableOptions.sortDesc"
                              :sort-direction="statTableOptions.sortDirection"
                              stacked="md"
                              show-empty
                              small
                              @filtered="statusTableOnFiltered"
                              ref="statusJsonTable"
                            >
                              <template #head(condition)="row">
                                {{ row.field[lang] }}
                              </template>
                              <template #head(data.name)="row">
                                {{ row.field[lang] }}
                              </template>
                              <template #head(value)="row">
                                {{ row.field[lang] }}
                              </template>
                              <template #cell(modify)="row">
                                <div
                                  style="
                                    display: flex;
                                    justify-content: flex-end;
                                  "
                                >
                                  <b-button
                                    pill
                                    size="sm"
                                    variant="outline-success"
                                    @click="editStatJsonItem(row.item, $event)"
                                    class="mr-2"
                                    style="
                                      outline: none !important;
                                      border: none;
                                      box-shadow: none;
                                    "
                                  >
                                    <b-icon
                                      icon="pencil-square"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                  <b-button
                                    pill
                                    size="sm"
                                    variant="outline-danger"
                                    @click="
                                      removeStatJsonItem(row.item, $event)
                                    "
                                    class="mr-2"
                                    style="
                                      margin-inline: 10px;
                                      outline: none !important;
                                      border: none;
                                      box-shadow: none;
                                    "
                                  >
                                    <b-icon
                                      icon="trash-fill"
                                      aria-hidden="true"
                                    ></b-icon>
                                  </b-button>
                                </div>
                              </template>

                              <template #cell(data)="row">
                                <i v-if="row.item.data">{{
                                    row.item.data.name
                                  }}</i>
                              </template>
                            </b-table>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="
                              g-0
                              mb-0
                              p-2
                              border border-top-0
                              rounded-bottom
                            "
                          >
                            <b-col lg="2" sm="4" md="3" class="my-1">
                              <component
                                :is="'DropDownList'"
                                :id="'per_page_3'"
                                :dataSource="statTableOptions.pageOptions"
                                :value="statTableOptions.perPage"
                                :fields="{ text: 'text', value: 'value' }"
                                :placeholder="dict.general.perPage[lang]"
                                :enableRtl="lang === 'per'"
                                @change="
                                  (value) => {
                                    statTableOptions.perPage = value;
                                  }
                                "
                              ></component>
                            </b-col>

                            <b-col lg="4" sm="6" md="5" class="my-1">
                              <b-pagination
                                v-model="statTableOptions.currentPage"
                                :total-rows="statTableOptions.totalRows"
                                :per-page="statTableOptions.perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                              ></b-pagination>
                            </b-col>
                          </b-row>
                        </b-container>
                      </div>

                      <div class="row py-4" v-show="activeTab == 'telemetry'">
                        <b-container fluid class="p-0">
                          <div
                            class="
                              fw-bolder
                              text-center
                              border
                              rounded
                              p-1
                              mb-2
                              text-light
                            "
                            style="
                              background-color: #a5b4fc;
                              width: 100%;
                              border-color: #a5b4fc !important;
                            "
                          >
                            {{ mapTypeText[lang] }}
                          </div>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border rounded-top border-bottom-0"
                          >
                            <b-col lg="2" sm="6" md="5" class="my-1">
                              <component
                                :is="'TextBox'"
                                :id="'searchBox_148'"
                                :value="telemetryTableOptions.filter"
                                :placeholder="dict.general.searchBox[lang]"
                                :enableRtl="lang === 'per'"
                                @change="
                                  (value) => {
                                    telemetryTableOptions.filter = value;
                                  }
                                "
                              ></component>
                            </b-col>

                            <b-col lg="2" sm="6" md="5" class="my-1">
                              <component
                                :is="'TextBox'"
                                :key="'searchBox_155'"
                                :id="'searchBox_155'"
                                :value="telemetry.limit"
                                :placeholder="dict.events.limit[lang]"
                                :enableRtl="lang === 'per'"
                                @change="
                                  (value) => {
                                    telemetry.limit = value;
                                  }
                                "
                              ></component>
                            </b-col>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border border-top-0 border-bottom-0"
                          >
                            <b-table
                              :empty-text="dict.emptyStr[lang]"
                              :items="telemetry.JSON.items"
                              :fields="telemetryTableOptions.fields"
                              :current-page="telemetryTableOptions.currentPage"
                              :per-page="telemetryTableOptions.perPage"
                              :filter="telemetryTableOptions.filter"
                              :filter-included-fields="
                                telemetryTableOptions.filterOn
                              "
                              :sort-by.sync="telemetryTableOptions.sortBy"
                              :sort-desc.sync="telemetryTableOptions.sortDesc"
                              :sort-direction="
                                telemetryTableOptions.sortDirection
                              "
                              stacked="md"
                              show-empty
                              small
                              @filtered="telemetryTableOnFiltered"
                              ref="statusJsonTable"
                            >
                              <template #head(time)="row">
                                {{ row.field[lang] }}
                              </template>
                              <template #head(telegram)="row">
                                {{ row.field[lang] }}
                              </template>
                              <template #head(source)="row">
                                {{ row.field[lang] }}
                              </template>
                              <template #head(type)="row">
                                {{ row.field[lang] }}
                              </template>
                              <template #cell(telegram)="row">
                                <i
                                  v-if="typeof row.item.telegram == 'object'"
                                  class="reset mx-2"
                                  @click="showVal(row.item)"
                                >
                                  <font-awesome-icon icon="fas fa-equals" />
                                </i>
                              </template>
                            </b-table>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="
                              g-0
                              mb-0
                              p-2
                              border border-top-0
                              rounded-bottom
                            "
                          >
                            <b-col lg="2" sm="4" md="3" class="my-1">
                              <component
                                :is="'DropDownList'"
                                :id="'per_page_448'"
                                :dataSource="telemetryTableOptions.pageOptions"
                                :value="telemetryTableOptions.perPage"
                                :fields="{ text: 'text', value: 'value' }"
                                :placeholder="dict.general.perPage[lang]"
                                :enableRtl="lang === 'per'"
                                @change="
                                  (value) => {
                                    telemetryTableOptions.perPage = value;
                                  }
                                "
                              ></component>
                            </b-col>

                            <b-col lg="4" sm="6" md="5" class="my-1">
                              <b-pagination
                                v-model="telemetryTableOptions.currentPage"
                                :total-rows="telemetryTableOptions.totalRows"
                                :per-page="telemetryTableOptions.perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                              ></b-pagination>
                            </b-col>
                          </b-row>
                        </b-container>
                      </div>

                      <div class="row py-4" v-show="activeTab == 'event'">
                        <b-container fluid class="p-0">
                          <div
                            class="
                              fw-bolder
                              text-center
                              border
                              rounded
                              p-1
                              mb-2
                              text-light
                            "
                            style="
                              background-color: #a5b4fc;
                              width: 100%;
                              border-color: #a5b4fc !important;
                            "
                          >
                            {{ mapTypeText[lang] }}
                          </div>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border rounded-top border-bottom-0"
                          >
                            <b-col lg="2" sm="6" md="5" class="my-1">
                              <component
                                :is="'TextBox'"
                                :id="'searchBox_178'"
                                :value="eventsTableOptions.filter"
                                :placeholder="dict.general.searchBox[lang]"
                                :enableRtl="lang === 'per'"
                                @change="
                                  (value) => {
                                    eventsTableOptions.filter = value;
                                  }
                                "
                              ></component>
                            </b-col>

                            <b-col lg="2" sm="6" md="5" class="my-1">
                              <component
                                :is="'TextBox'"
                                :key="'searchBox_175'"
                                :id="'searchBox_175'"
                                :value="events.limit"
                                :placeholder="dict.events.limit[lang]"
                                :enableRtl="lang === 'per'"
                                @change="
                                  (value) => {
                                    events.limit = value;
                                  }
                                "
                              ></component>
                            </b-col>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="g-0 p-2 border border-top-0 border-bottom-0"
                          >
                            <b-table
                              :items="events.JSON.items"
                              :fields="eventsTableOptions.fields"
                              :current-page="eventsTableOptions.currentPage"
                              :per-page="eventsTableOptions.perPage"
                              :filter="eventsTableOptions.filter"
                              :filter-included-fields="
                                eventsTableOptions.filterOn
                              "
                              :empty-text="dict.emptyStr[lang]"
                              :sort-by.sync="eventsTableOptions.sortBy"
                              :sort-desc.sync="eventsTableOptions.sortDesc"
                              :sort-direction="eventsTableOptions.sortDirection"
                              stacked="md"
                              show-empty
                              small
                              @filtered="eventsTableOnFiltered"
                              ref="statusJsonTable"
                            >
                              <template #head(time)="row">
                                {{ row.field[lang] }}
                              </template>
                              <template #head(message)="row">
                                {{ row.field[lang] }}
                              </template>
                            </b-table>
                          </b-row>

                          <b-row
                            align-h="between"
                            class="
                              g-0
                              mb-0
                              p-2
                              border border-top-0
                              rounded-bottom
                            "
                          >
                            <b-col lg="2" sm="4" md="3" class="my-1">
                              <component
                                :is="'DropDownList'"
                                :id="'per_page_545'"
                                :dataSource="eventsTableOptions.pageOptions"
                                :value="eventsTableOptions.perPage"
                                :fields="{ text: 'text', value: 'value' }"
                                :placeholder="dict.general.perPage[lang]"
                                :enableRtl="lang === 'per'"
                                @change="
                                  (val) => {
                                    eventsTableOptions.perPage = val;
                                  }
                                "
                              ></component>
                            </b-col>

                            <b-col lg="4" sm="6" md="5" class="my-1">
                              <b-pagination
                                v-model="eventsTableOptions.currentPage"
                                :total-rows="eventsTableOptions.totalRows"
                                :per-page="eventsTableOptions.perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                              ></b-pagination>
                            </b-col>
                          </b-row>
                        </b-container>
                      </div>
                    </div>

                    <div v-show="!registred">
                      <div id="firts_step_info" class="row mx-auto">
                        <div
                          :key="'name12_' + reRender"
                          class="col-md-5 col-12"
                        >
                          <component
                            :is="'TextBox'"
                            :id="'DataConnection_2_name_input'"
                            :value="name"
                            :placeholder="dict.ConnectionData.name[lang]"
                            :enableRtl="lang === 'per'"
                            @change="
                              (val) => {
                                name = val;
                              }
                            "
                            :validatorInstance="validator1"
                            :validatorRules="{ required: true, minLength: 3 }"
                          ></component>
                        </div>
                        <div :key="'name_' + reRender" class="col-md-5 col-12">
                          <component
                            :is="'DropDownList'"
                            :id="'DataConnection_2_mapType_input'"
                            :dataSource="mapTypeList"
                            :value="mapType"
                            :fields="{ text: 'text', value: 'value' }"
                            :placeholder="dict.ConnectionData.mapType[lang]"
                            :enableRtl="lang === 'per'"
                            @change="
                              (val) => {
                                mapType = val;
                              }
                            "
                            :validatorInstance="validator1"
                            :validatorRules="{ required: true }"
                          ></component>
                        </div>
                        <div
                          class="col-md my-3"
                          :class="{
                            'text-end': lang == 'eng',
                            'text-start': lang == 'per',
                          }"
                        >
                          <b-button class="w-auto" @click="registerClient"
                          >{{ dict.general.nextBtn[lang] }}
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </transition>
                </b-card>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal id="modal-text-coppied" hide-footer hide-header centered size="sm">
      <div class="my-modal-text">Message copied to clipboard!</div>
    </b-modal>
    <jsonItemEditor></jsonItemEditor>
    <b-modal
      id="modal-edit-get-map"
      size="xl"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      hide-header-close
      :dir="dict.dir[lang]"
      content-class="shadow"
      @cancel="resetModal"
      @ok="handleOk"
      @shown="initGetMapModal"
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
    >
      <template #modal-header>
        <div :dir="dict.dir[lang]">
          <h5 v-if="pageMode == 'New'">{{ dict.titleTranslateAdd[lang] }}</h5>
          <h5 v-if="pageMode == 'Edit'">{{ dict.titleTranslateEdit[lang] }}</h5>
        </div>
      </template>
      <div :dir="dict.dir[lang]" id="for_firts_2">
        <set-get-mapping-url
          :key="reRen"
          :re-ren="reRen"
          :validator="validator3"
          v-model="urlModel"
          :is-http="true"
        />
        <b-container fluid class="yekan p-0">
          <div
            class="fw-bolder text-center border rounded p-1 mb-2 text-light"
            style="background-color: #a5b4fc;width: 100%; border-color: #a5b4fc !important;
                            "
          >
            {{ mapTypeText[lang] }}
          </div>

          <b-row
            align-h="between"
            class="g-0 p-2 border rounded-top border-bottom-0"
          >
            <b-col lg="2" sm="6" md="5" class="my-1">
              <ejs-textbox
                :value="mapGettingItemsTable.filter"
                :placeholder="dict.general.searchBox[lang]"
                :enableRtl="lang === 'per'"
                @input="
                  (value) => {
                    mapGettingItemsTable.filter = value.value;
                  }
                "
              ></ejs-textbox>
            </b-col>

            <b-col lg="1" sm="1" md="1" class="my-1">
              <div style="font-size: 24px; display: flex; justify-content: end">
                <i @click="addItemLev2" class="reset">
                  <font-awesome-icon icon="fas fa-plus-square" />
                </i>
              </div>
            </b-col>
          </b-row>

          <b-row
            align-h="between"
            class="g-0 p-2 border border-top-0 border-bottom-0"
          >
            <b-table
              :items="mappingItems"
              :empty-text="dict.emptyStr[lang]"
              :fields="mapGettingItemsTable.fields"
              :current-page="mapGettingItemsTable.currentPage"
              :per-page="mapGettingItemsTable.perPage"
              :filter="mapGettingItemsTable.filter"
              :filter-included-fields="mapGettingItemsTable.filterOn"
              :sort-by.sync="mapGettingItemsTable.sortBy"
              :sort-desc.sync="mapGettingItemsTable.sortDesc"
              :sort-direction="mapGettingItemsTable.sortDirection"
              stacked="md"
              show-empty
              small
              @filtered="mapGettingItemsTable.onFiltered"
              ref="mapSubJsonTable"
            >
              <template #head(key)="row">
                {{ row.field[lang] }}
              </template>
              <template #head(type)="row">
                {{ row.field[lang] }}
              </template>
              <template #head(data.name)="row">
                {{ row.field[lang] }}
              </template>
              <template #cell(modify)="row">
                <div style="display: flex; justify-content: flex-end">
                  <b-button
                    pill
                    size="sm"
                    variant="outline-success"
                    @click="editItem(row.item, $event)"
                    class="mr-2"
                    style="
                      outline: none !important;
                      border: none;
                      box-shadow: none;
                    "
                  >
                    <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                  </b-button>
                  <b-button
                    pill
                    size="sm"
                    variant="outline-danger"
                    @click="removeMapGetItem(row.item, $event)"
                    class="mr-2"
                    style="
                      margin-inline: 10px;
                      outline: none !important;
                      border: none;
                      box-shadow: none;
                    "
                  >
                    <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                  </b-button>
                </div>
              </template>
              <template #cell(data.name)="row">
                <p v-if="row.item.type == 'arrayOfObject'">-</p>
                <p v-if="row.item.type != 'arrayOfObject'">
                  {{ row.item.data.name }}
                </p>
              </template>
            </b-table>
          </b-row>

          <b-row
            align-h="between"
            class="g-0 mb-0 p-2 border border-top-0 rounded-bottom"
          >
            <b-col lg="2" sm="4" md="3" class="my-1">
              <component
                :is="'DropDownList'"
                :key="'per_page_585'"
                :id="'per_page_585'"
                :dataSource="mapGettingItemsTable.pageOptions"
                :value="mapGettingItemsTable.perPage"
                :fields="{ text: 'text', value: 'value' }"
                :placeholder="dict.general.perPage[lang]"
                :enableRtl="lang === 'per'"
                @change="
                  (value) => {
                    mapGettingItemsTable.perPage = value;
                  }
                "
              ></component>
            </b-col>

            <b-col lg="4" sm="6" md="5" class="my-1">
              <b-pagination
                v-model="mapGettingItemsTable.currentPage"
                :total-rows="mapGettingItemsTable.totalRows"
                :per-page="mapGettingItemsTable.perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
    <b-modal
      id="modal-show-telegram"
      size="lg"
      hide-header-close
      content-class="shadow"
      title="telegram"
      hide-footer
      :dir="dict.dir[lang]"
      centered
    >
      <div>
        <p :key="index" v-for="(item, index) in telegramValue">
          <span class="text-bold mx-2">{{ index }}</span
          >:{{ item || null }}
        </p>
      </div>
    </b-modal>
    <set-get-mapping-json :is-http="true" :current-tab="activeTab" />
    <set-cont-http-server-status-json :is-http="true" />
  </div>
</template>

<script>
/* eslint-disable */

import jsonItemEditor from "./setContHttpsModalWrRd.vue";
import * as crypto from "crypto";
import { FormValidator } from "@syncfusion/ej2-inputs";
import SetGetMappingUrl from "@/components/setGetMappingUrl.vue";
import SetGetMappingJson from "@/components/setGetMappingJson.vue";
import { v4 } from "uuid";
import SetContHttpServerStatusJson from "@/components/setContHttpServerStatusJson.vue";
import {
  Page,
  Sort,
  Toolbar,
  PdfExport,
  ExcelExport,
  Edit,
  Search
} from "@syncfusion/ej2-vue-grids";

export default {
  name: "httpsEdit",
  components: {
    SetContHttpServerStatusJson,
    SetGetMappingJson,
    SetGetMappingUrl,
    jsonItemEditor
  },
  data() {
    return {
      selectionOptions: {
        type: "Multiple",
        persistSelection: false,
        checkboxOnly: true
      },
      editSettings: {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: false,
        mode: "Dialog"
      },
      pageMode: null,
      telegramValue: {},
      reRen: false,
      renderTime: 0,
      reRender: 0,
      dict: {
        isExist: { per: "پیوند انتخاب شده در سیستم وجود دارد. لطفا پیوند دیگری انتخاب کنید.", eng: "The URL already exists in the list. Please change it." },
        emptyStr: { per: "هیچ رکوردی برای نمایش وجود ندارد", eng: "No records to display" },
        general: {
          nextBtn: { "per": "ادامه", "eng": "Next" },
          pageTitle: { "per": "ایجاد اتصال Http server", "eng": "Add http server Connection" },
          perPage: { per: "تعداد در هر صفحه", eng: "per page" },
          searchBox: { per: "جستجو", eng: "search" }
        },
        titleTranslateAdd: { eng: "New Item", per: "افزودن آیتم" },
        titleTranslateEdit: { eng: "Edit Item", per: "ویرایش آیتم" },
        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        dir: { eng: "ltr", per: "rtl" },
        transition: { eng: "slide-fade-ltr", per: "slide-fade-rtl" },
        Add: { eng: "Add Https connection", per: "افزودن اتصال جدید Https" },
        Edit: { eng: "Edit Https connection", per: "ویرایش اتصال  Https" },
        tabs: {
          ConnectionData: { eng: "Connection data", per: "اطلاعات پایه" },
          security: { eng: "Security", per: "امنیت" },
          getMapping: { eng: "Get Mapping", per: "دریافت اطلاعات" },
          postMapping: { eng: "Post Mapping", per: "ارسال اطلاعات" },
          status: { eng: "Status", per: "وضعیت" },
          attribute: { eng: "Attribute", per: "ارسال اطلاعات" },
          event: { eng: "Events", per: "گزارشات سیستمی" },
          telemetry: { eng: "Telemetry", per: "گزارشات داده ای" }
        },
        ConnectionData: {
          name: { eng: "name", per: "نام" },
          mapType: { eng: "mapType", per: "نوع داده" },
          route: { eng: "API route", per: "مسیریابی" },
          clientId: { eng: "client Id", per: "شناسه کاربری" }
        },
        security: {
          user: { per: "نام کاربری", eng: "user" },
          pass: { per: "رمز عبور", eng: "password" },
          token: { per: "زمان انقضای توکن (دقیقه)", eng: "token time expiration (min)" }
        },
        events: {
          limit: { per: "حداکثر تعداد پیام های ذخیره شده", eng: "limit" }
        },
        key: { per: "کلید", eng: "key" },
        type: { per: "نوع", eng: "type" },
        data: { per: "داده", eng: "data" }

      },
      activeTab: "ConnectionData",
      tabs: [
        { value: "ConnectionData", name: "Connection data" },
        { value: "security", name: "security" },
        { value: "getMapping", name: "getMapping" },
        { value: "postMapping", name: "postMapping" },
        { value: "status", name: "status" },
        // { value: "attribute", name: "attribute" },
        { value: "event", name: "event" },
        { value: "telemetry", name: "telemetry" }
      ],
      id: "",
      name: "",
      parent: "",
      title: "",
      newGenerate: false,
      registred: false,
      mapType: null,
      events: {
        type: "JSON",
        limit: Number(10),
        JSON: {
          items: []
        }
      },
      security: {
        type: "",
        basic: {
          user: "",
          pass: "",
          tokenExpireTime: ""
        },
        cert: {
          caCert: "",
          privateKey: "",
          cert: ""
        }
      },
      telemetry: {
        type: "JSON",
        limit: Number(10),
        JSON: {
          items: []
        }
      },
      getMapping: {
        type: "",
        JSON: {
          items: []
        }
      },
      mappingPost: {
        type: "",
        JSON: {
          items: []
        }
      },
      getMapTable: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "topic",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        fields: [
          { key: "url", label: "url", per: "لینک", eng: "url", hasTemplate: false },
          { key: "interval", label: "interval", per: "فاصله زمانی", eng: "interval", hasTemplate: false },
          { key: "uponChange.value", label: "uponChange", per: "به محض تغییر", eng: "uponChange", hasTemplate: false },
          { key: "modify", label: "", per: "", eng: "", hasTemplate: true }
        ]
      },
      postMapTable: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "topic",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        fields: [
          { key: "url", label: "url", per: "لینک", eng: "url", hasTemplate: false },
          { key: "interval", label: "interval", per: "فاصله زمانی", eng: "interval", hasTemplate: false },
          { key: "uponChange.value", label: "uponChange", per: "به محض تغییر", eng: "uponChange", hasTemplate: false },
          { key: "modify", label: "", per: "", eng: "", hasTemplate: true }
        ]
      },

      mappingWrite: {
        type: "",
        JSON: {
          items: []
        }
      },

      status: {
        type: "",
        JSON: {
          items: []
        }
      },
      statusHttpJsonFields: [
        // { key: '_id', label: 'id', sortable: true},
        { key: "condition", label: "", per: "شرط", eng: "condition", sortable: true },
        { key: "data.name", label: "", per: "داده", eng: "data", sortable: true },
        { key: "value", label: "", per: "مقدار", eng: "value", sortable: true },

        { key: "modify", label: "", per: "", eng: "" }
      ],
      telemetryTableOptions: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "time",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        fields: [
          { key: "time", per: "زمان", eng: "time", label: "time", sortable: true },
          { key: "telegram", per: "تلگرام", eng: "telegram", label: "telegram", sortable: true },
          { key: "source", per: "منبع", eng: "source", label: "source", sortable: true },
          { key: "type", per: "نوع", eng: "type", label: "type", sortable: true }
        ]
      },
      connection: {
        host: "",
        port: "",
        clientId: "",
        idPrefix: false,
        onOffStatus: false
      },
      newGenerated: false,
      submitted: false,
      successful: false,
      message: "",
      validator: null,
      validator1: null,
      validator3: null,
      mapTypeTextList: {
        JSON: { eng: "JSON Mapping", per: "نگاشت جیسون" },
        BYTE: { eng: "Byte Mapping", per: "نگاشت Byte" },
        CUST: { eng: "Custom mapping", per: "نگاشت شخصی سازی شده" },
        "null": { eng: "", per: "" }
      },
      mapTypeList: [
        { value: null, text: "select a type" },
        { value: "JSON", text: "Json Mapping" }
        // { value: 'BYTE', text: "Byte Mapping" },
        // { value: 'CUST', text: "Custom mapping" }
      ],
      mappingItems: [],
      urlModel: {},
      mapGettingItemsTable: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "topic",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        fields: [
          { key: "key", per: "کلید", eng: "Key", label: "Key", sortable: true },
          { key: "type", per: "نوع", eng: "type", label: "type", sortable: true },
          { key: "data.name", per: "داده", eng: "data", label: "data", sortable: true },
          { key: "modify", per: "", eng: "", label: "" }
        ]
      },
      statTableOptions: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "topic",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      eventsTableOptions: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "topic",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        fields: [
          { key: "time", per: "زمان", eng: "time", label: "time", sortable: true },
          { key: "message", per: "پیام", eng: "message", label: "message", sortable: true }
        ]
      },
      isSecClpsd1: false,
      isSecClpsd2: false,
      isSecClpsd3: false,

      isMapClpsd1: false,
      isMapClpsd2: false,
      isMapClpsd3: false,
      isMapClpsd4: false

    };
  },
  watch: {
    lang: {
      handler(val) {
        this.renderTime = this.renderTime + 1;
      }, deep: true
    },
    secType(newVal) {
      // this.isSecClpsd1 = newVal === 'anony';
      this.isSecClpsd2 = newVal === "basic";
      this.isSecClpsd3 = newVal === "cert";
    },
    mapType(newVal) {
      this.mappingRead.type = newVal;
      this.mappingWrite.type = newVal;
      this.status.type = newVal;

      this.isMapClpsd1 = newVal === "MTR22";
      this.isMapClpsd2 = newVal === "JSON";
      this.isMapClpsd3 = newVal === "BYTE";
      this.isMapClpsd4 = newVal === "CUST";
    },
    child2parent(newValue, oldValue) {
      if (newValue.task == "addHttpServerStat") {
        if (newValue.type == "New") {
          this.status.JSON.items.push(newValue.item);
          this.$root.$emit("bv::hide::modal", "modal-httpServer-status-json-item");
        }
      }


      if ((newValue.task === "editMapJson/identify") && (newValue.to.type === "httpsConnection")) {
        var map = newValue.to.map;
        if (newValue.type === "New") {
          if (this.getMapping[map].items) {
            this.mappingItems.push(JSON.parse(JSON.stringify(newValue.item)));
          } else {
            // this.mappingGet[map]["items"] = [JSON.parse(JSON.stringify(newValue.item))];
          }
        } else if (newValue.type === "Edit") {
          this.urlModel = this.getMapping[map].items;
          var indx = this.getMapping[map].items.findIndex(x => x._id === newValue.item._id);
          if (indx != -1) {
            this.getMapping[map].items[indx] = JSON.parse(JSON.stringify(newValue.item));
          }
        }
        this.$refs.mapSubJsonTable.refresh();
      }
    },
    parent2child(newValue, moldValue) {
      // this.modalTitle = newValue.type + " Item";
      // this.modalTitleUrl = newValue.type + " Item";
      if (newValue.type == "Edit" && newValue.task == "editMapJson") {
        this.mappingItems = newValue.item.items;
        this.urlModel = newValue.item;
      }
    }
  },
  computed: {
    type() {
      return this.$store.state.chpge.type;
    },
    fieldTableGenerate() {
      return [
        { key: "key", label: this.dict.key(this.lang), sortable: true },
        { key: "type", label: this.dict.type(this.lang), sortable: true },
        { key: "data.name", label: this.dict.data(this.lang), sortable: true },
        { key: "modify", label: "" }
      ];
    },
    db() {
      return this.$store.state.chpge.db;
    },
    index() {
      return this.$store.state.chpge.index;
    },
    secType() {
      return this.security.type;
    },
    child2parent() {
      return this.$store.state.chpge.child2parent;
    },
    parent2child() {
      return this.$store.state.chpge.parent2child;
    },
    mapTypeText() {
      if (this.registred)
        return this.mapTypeTextList[this.mapType];
      else return "null";
    },
    lang() {
      return this.$store.state.chpge.lang;
    },
    toolbarGenerator() {
      return [
        {
          text: this.dict.titleTranslateAdd[this.lang],
          id: "add_mapGetting",
          prefixIcon: "e-icons e-add"
        },
        "Search"
      ];
    }
  },
  methods: {
    toolbarMagGettingClick(args) {
      if (args.item.id == "add_mapGetting") {
        this.openNewUrl();
      }
    },
    actionBegin(args) {
      if (args.requestType != "paging" && args.requestType != "refresh")
        args.cancel = true;
    },
    actionComplete(args) {
      if (args.requestType != "refresh")
        args.cancel = true;
    },
    showVal(val) {
      this.$bvModal.show("modal-show-telegram");
      this.telegramValue = val.telegram;
    },
    addStatJsonItem() {
      this.$store.dispatch("chpge/parent2child", { task: "editStatusJson", type: "New", item: {} });
      this.$root.$emit("bv::show::modal", "modal-httpServer-status-json-item");
    },
    editStatJsonItem(item) {
      this.$store.dispatch("chpge/parent2child", { task: "editStatusJson", type: "Edit", item: item });

      this.$root.$emit("bv::show::modal", "modal-httpServer-status-json-item");
    },
    telemetryTableOnFiltered(filteredItems) {
      this.telemetryTableOptions.totalRows = filteredItems.length;
      this.telemetryTableOptions.currentPage = 1;
    },
    removeStatJsonItem: function(item, event) {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete it.")
        .then(value => {
          if (value === true) {
            var indx = this.status.JSON.items.findIndex(x => x._id === item._id);
            if (indx != -1) {
              this.status.JSON.items.splice(indx, 1);
            }
          }
        })
        .catch(err => {
          // An error occurred
        });
    },
    statusTableOnFiltered(filteredItems) {
      this.statTableOptions.totalRows = filteredItems.length;
      this.statTableOptions.currentPage = 1;
    },
    initGetMapModal() {
      this.validator3 = new FormValidator("#for_firts_2", {
        rules: {},
        customPlacement: (element, error) => {
          element.parentElement.parentElement.parentElement.insertBefore(error, element.parentElement.parentElement.nextSibling);
        }
      });
      this.reRen = !this.reRen;
    },
    checkUniqueUrl(param) {
      let urlExists = param.some(item => item.url === this.urlModel.url);
      if (urlExists && this.pageMode != "Edit") {
        alert(this.dict.isExist[this.lang]);
        return false;
      } else return true;

    },
    initModal() {
      this.title = this.type + " Https connection";

      if (this.type === "Add") {
        this.parent = { ptype: this.db.ptype, pid: this.db.pid };
      } else if (this.type === "Edit") {
        // this.subroute = this.db.subroute;
        this.registred = true;
        this.id = this.db.id;
        this.name = this.db.name;
        this.mapType = this.db.mapType;
        this.parent = this.db.parent;
        this.security = this.db.security;
        this.status = this.db.status;
        this.connection = this.db.connection;

        this.mappingPost = this.db.mappingPost;
        this.getMapping = this.db.mappingGet;
        this.status = this.db.status;
        this.telemetry = this.db.telemetry;
        this.events = this.db.events;

        this.telemetryTableOptions.totalRows = this.telemetry.JSON.items.length;
        this.eventsTableOptions.totalRows = this.events.JSON.items.length;
      }
    },
    onReset() {
      // this.subroute = null
      this.id = null,
        this.type = "",
        this.name = "",
        this.mapType = "",
        this.parent = {},
        this.security = {},
        this.mappingRead = {},
        this.mappingWrite = {},
        this.status = {},
        this.connection = {},
        this.newGenerated = false;

      this.$store.dispatch("chpge/changePage", "controllerSettings");
    },
    registerClient() {
      this.message = "";
      this.submitted = true;
      const isValid = this.validator1.validate();
      if (isValid) {
        var data = {
          // id:this.id,
          // type:"Master",
          // name: this.name,
          // parent:this.parent,
          // security:this.security,
          // mappingRead:this.mappingRead,
          // status:this.status,
          // connection: this.connection,

          type: "Https",
          name: this.name,
          parent: this.parent,
          mapType: this.mapType
        };
        this.$store.dispatch("data/createHttps", data).then(
          data => {

            this.registred = true;
            this.id = data.message._id;
            this.name = data.message.name;
            this.mapType = data.message.mapType;
            this.parent = data.message.parent;
            this.security = data.message.security;
            this.getMapping = data.message.mappingGet;
            this.mappingPost = data.message.mappingPost;
            this.status = data.message.status;
            this.connection = data.message.connection;
            this.events = data.message.events;
            this.telemetry = data.message.telemetry;

          },
          error => {
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            // alert(this.message);
          }
        );
      }


    },
    generateRandomString(length) {
      this.newGenerate = true;
      this.newGenerated = true;
      const newStr = crypto.randomBytes(length).toString("base64");
      this.security.basic.pass = newStr.replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, "");
    },
    handleRegister() {
      this.message = "";
      this.submitted = true;
      if (true) {
        var data = {
          id: this.id,
          type: "Https",
          name: this.name,
          mapType: this.mapType,
          parent: this.parent,
          security: {
            type: this.security.type,
            basic: {
              user: this.security.basic.user,
              pass: this.security.basic.pass != "" ? this.security.basic.pass : undefined,
              tokenExpireTime: this.security.basic.tokenExpireTime != "" ? Number(this.security.basic.tokenExpireTime) : undefined
            },
            cert: {
              caCert: this.security.cert.caCert,
              privateKey: this.security.cert.privateKey,
              cert: this.security.cert.cert
            }
          },
          connection: this.connection,
          mappingPost: this.mappingPost,
          mappingGet: this.getMapping,
          status: this.status,
          telemetry: {
            type: this.telemetry.type,
            limit: this.telemetry.limit || 10,
            JSON: this.telemetry.JSON
          },
          events: {
            type: this.events.type,
            limit: this.events.limit || 10,
            JSON: this.events.JSON
          }
        };

        // if (this.type==='Add'){
        //     this.$store.dispatch('data/createHttps', data).then(
        //         data => {
        //             this.message = data.message;
        //             this.successful = true;
        //             // alert(this.message)
        //             this.getContConf();
        //             this.$store.dispatch('chpge/changePage','controllerSettings')
        //         },
        //         error => {
        //             this.message =
        //                 (error.response && error.response.data && error.response.data.message) ||
        //                 error.message ||
        //                 error.toString();
        //             this.successful = false;
        //             alert(this.message)
        //         }
        //     );
        // }else if (this.type==='Edit'){

        this.$store.dispatch("data/editHttps", data).then(
          data => {
            this.message = data.message;
            this.successful = true;
            //alert(this.message)
            this.getContConf();
            this.$store.dispatch("chpge/changePage", "controllerSettings");
          },
          error => {
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            // alert(this.message);
          }
        );
        // }
      }

    },
    getContConf() {
      this.$store.dispatch("data/getContConf").then(
        data => {
          // console.log("Https: ", JSON.stringify(data));
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    downloadFile(type) {
      this.$store.dispatch("data/getFileHttps", { id: this.id, type: type })

        .then((response) => {
          // Create a download link for the received file
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;

          let fileName = "";
          if (type === "CA")
            fileName = "ca.crt";
          else if (type === "PK")
            fileName = "client.key";
          else if (type === "CF")
            fileName = "client.crt";

          link.setAttribute("download", fileName);
          document.body.appendChild(link);

          // Click the download link to initiate the file download
          link.click();

          // Clean up the temporary download link
          document.body.removeChild(link);
        })
        .catch(error => {
          // alert("Error downloading file:", error);
        });
    },
    copyToClipboard(value) {
      // Create a temporary textarea element to copy the text to the clipboard
      const textarea = document.createElement("textarea");
      textarea.value = value;
      textarea.style.position = "fixed"; // Set the textarea off-screen
      document.body.appendChild(textarea);
      textarea.select();

      try {
        // Use the document.execCommand('copy') method to copy the text to the clipboard
        const success = document.execCommand("copy");
        if (success) {
          this.$bvModal.show("modal-text-coppied");
          setTimeout(() => {
            this.$bvModal.hide("modal-text-coppied");
          }, 2000);
        } else {
          console.error("Failed to copy text to clipboard.");
        }
      } catch (err) {
        console.error("Unable to copy text: ", err);
      }

      // Remove the temporary textarea element
      document.body.removeChild(textarea);
      this.newGenerate = false;
    },
    openNewUrl() {
      var channels = [];
      this.$store.dispatch("chpge/parent2child", {
        task: "editHttpsJson",
        from: { type: "httpsRead", map: "JSON", id: this.id, extra: channels },
        type: "New",
        item: {}
      });
      this.$root.$emit("bv::show::modal", "modal-edit-get-map");
      this.pageMode = "New";
    },
    addItemLev2() {
      var channels = [];
      this.$store.dispatch("chpge/parent2child", {
        task: "editGetMappingMain",
        from: { type: "httpsConnection", map: "JSON", id: this.id, extra: channels },
        type: "New",
        item: {}
      });
      this.$root.$emit("bv::show::modal", "modal-get-mapping");
    },
    resetModal() {
      this.reRen = false;
      this.mappingItems = [];
      this.urlModel = {};
      this.$nextTick(() => {
        this.$root.$emit("bv::hide::modal", "modal-edit-get-map");
      });
    },
    handleOk($bvModal) {
      $bvModal.preventDefault();
      const isValid = new FormValidator("#for_firts_2").validate();
      if (isValid) {
        if (this.checkUniqueUrl(this.activeTab != "postMapping" ? this.getMapping.JSON.items : this.mappingPost.JSON.items)) {
          if (this.pageMode != "Edit") {
            if (this.activeTab != "postMapping") {
              this.getMapping.JSON.items.push({
                _id: v4(),
                url: this.urlModel.url,
                interval: this.urlModel.interval,
                uponChange: this.urlModel.uponChange,
                items: this.mappingItems
              });
              this.$nextTick(() => {
                this.$root.$emit("bv::hide::modal", "modal-edit-get-map");
              });
            } else if (this.activeTab == "postMapping") {
              this.mappingPost.JSON.items.push({
                _id: v4(),
                url: this.urlModel.url,
                interval: this.urlModel.interval,
                uponChange: this.urlModel.uponChange,
                items: this.mappingItems
              });
              this.$nextTick(() => {
                this.$root.$emit("bv::hide::modal", "modal-edit-get-map");
              });
            }
          } else
            this.$nextTick(() => {
              this.$root.$emit("bv::hide::modal", "modal-edit-get-map");
            });

        } else $bvModal.preventDefault();
      }
    },
    editItem(item) {
      var channels = [];
      this.$store.dispatch("chpge/parent2child", {
        task: "editGetMappingMain",
        from: { type: "httpsConnection", map: "JSON", id: this.id, extra: channels },
        type: "Edit",
        item: item
      });
      this.$root.$emit("bv::show::modal", "modal-get-mapping");
    },
    removeMapGetItem: function(item, event) {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete it.")
        .then(value => {
          if (value === true) {
            this.mappingItems.splice(this.mappingItems.indexOf(item._id), 1);
          }
        })
        .catch(err => {
          // An error occurred
        });
    },
    editMapPubJsonItem(item) {
      var channels = [];
      this.$store.dispatch("chpge/parent2child", {
        task: "editMapJson",
        from: { type: "httpsConnection", map: "JSON", id: this.id, extra: channels },
        type: "Edit",
        item: item
      });
      this.$root.$emit("bv::show::modal", "modal-edit-get-map");
      this.pageMode = "Edit";
      //do3a
    },
    removeMapPubJsonItem: function(item, event) {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete it.")
        .then(value => {
          if (value === true) {
            if (this.activeTab != "postMapping") {
              var indx = this.getMapping.JSON.items.findIndex(x => x._id === item._id);
              if (indx != -1) {
                this.getMapping.JSON.items.splice(indx, 1);
              }
            } else if (this.activeTab == "postMapping")
              var indx = this.mappingPost.JSON.items.findIndex(x => x._id === item._id);
            if (indx != -1) {
              this.mappingPost.JSON.items.splice(indx, 1);
            }
          }
        })
        .catch(err => {
          // An error occurred
        });
    },
    eventsTableOnFiltered(filteredItems) {
      this.eventsTableOptions.totalRows = filteredItems.length;
      this.eventsTableOptions.currentPage = 1;
    }
  },
  mounted() {
    this.initModal();

    this.validator = new FormValidator("#setContHttps", {
      rules: {},
      customPlacement: (element, error) => {
        element.parentElement.parentElement.parentElement.insertBefore(error, element.parentElement.parentElement.nextSibling);
      }
    });
    if (this.registred == false) {
      this.reRender += 1;
      this.validator1 = new FormValidator("#firts_step_info", {
        rules: {},
        customPlacement: (element, error) => {
          element.parentElement.parentElement.parentElement.insertBefore(error, element.parentElement.parentElement.nextSibling);
        }
      });
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, Edit, Search]
  }
};
</script>

<style scoped>
.apply {
  color: #198754;
  cursor: pointer;
}

.apply :hover {
  color: #9b9b9b;
}

.reset {
  color: #dc3545;
  cursor: pointer;
}

.reset :hover {
  color: #9b9b9b;
}

.download-link {
  margin-bottom: 10px;
}

.my-modal-text {
  text-align: center;
  font-weight: bold;
  color: #198754;
  /* background-color: aliceblue !important; */
  padding: 20px;
}
</style>
