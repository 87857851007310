<template>
  <div ref="mainCont" class="card-body p-1 d-flex justify-content-center align-items-center"
       style="height:calc(100%); width: 100%">
    <div v-if="mapinit" :ref="plotId" :id="plotId"
         style="height:calc(100%);width: 100%"
    ></div>
    <!--    <div>-->
    <!--      <ul-->
    <!--        class="pagination"-->
    <!--        style="-->
    <!--            flex-direction: column;-->
    <!--            width: 100%;-->
    <!--            text-align: center;-->
    <!--            padding: 15px;-->
    <!--            overflow-y: scroll;-->
    <!--            min-height: 5rem;-->
    <!--            max-height: 10rem;-->
    <!--          "-->
    <!--      >-->
    <!--        <li-->
    <!--          v-for="(item, i) in layerList"-->
    <!--          :key="i"-->
    <!--          class="page-item"-->
    <!--          @click="changeLayer(item)"-->
    <!--        >-->
    <!--          <a class="page-link" href="#">{{ item.title }}</a>-->
    <!--        </li>-->
    <!--      </ul>-->
    <!--    </div>-->

    <b-modal
      :id="plotId"
      size="lg"
      centered
      body-class="my-body-class"
      hide-header-close
      :title="modalTitle[lang]"
      :cancel-title="dict.cancel[lang]"
      :ok-title="dict.ok[lang]"
      :dir="dict.dir[lang]"
      @shown="initModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <div>
        <b-container fluid="md" class="p-0">
          <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs">
              <li v-for="(tab, index) in tabList" :key="index" class="nav-item" @click="activeTab = tab">
                <p class="nav-link"
                   :class="{ active: activeTab === tab }"
                   :id=tab
                   data-toggle="tab"
                   role="tab"
                   aria-controls="tab1"
                   aria-selected="true"
                >
                  {{ dict[tab][lang] }}
                </p>
              </li>
            </ul>
          </div>
          <div class="card-body h-100 p-3 " style="overflow: hidden;">
            <transition name="slide-fade" mode="out-in">
              <div class="tab-content h-100" :key="activeTab">
                <div v-if="activeTab === 'data'" class="h-100 ">
                  <div fluid class="container-fluid p-0 h-100 " :key="reRender">
                    <div class="row mx-auto">
                      <div class="col-md-6 col-12 my-2">
                        <ejs-textbox
                          :placeholder="dict.lat[lang]"
                          :value="myData.attribute.center[0]"
                          :enableRtl="lang === 'per'"
                          floatLabelType="Auto"
                          v-model="myData.attribute.center[0]"
                        ></ejs-textbox>
                      </div>
                      <div class="col-md-6 col-12 my-2">
                        <ejs-textbox
                          :placeholder="dict.lng[lang]"
                          :value="myData.attribute.center[1]"
                          :enableRtl="lang === 'per'"
                          floatLabelType="Auto"
                          v-model="myData.attribute.center[1]"
                        ></ejs-textbox>
                      </div>
                      <div class="col-md-6 col-12 my-2">
                        <ejs-dropdownlist
                          :placeholder="dict.mapTile[lang]"
                          :enableRtl="lang === 'per'"
                          :dataSource="tileIdList"
                          floatLabelType="Auto"
                          :fields="{ text: 'text', value: 'value' }"
                          v-model="myData.attribute.tileId"
                          @change="(val)=>{myData.attribute.tileId=val.value}"
                        ></ejs-dropdownlist>
                      </div>
                      <div class="col-md-6 col-12 my-3">
                        <span>{{ dict.minZoom[lang] }}</span>
                        <ejs-slider
                          :showButtons="true"
                          v-model.number="myData.attribute.minZoom"
                          :step="1"
                          :enableRtl="lang=='per'"
                          :min="0"
                          :max="25"
                          :value="myData.attribute.minZoom"
                          v-model="myData.attribute.minZoom"
                          :tooltip="{ placement: 'Before', isVisible: true, showOn: 'Auto' }"
                          :ticks="{ placement: 'After', largeStep: 5, showSmallTicks: false }"
                          :type="'MinRange'"></ejs-slider>
                      </div>
                      <div class="col-md-6 col-12 my-3">
                        <span>{{ dict.maxZoom[lang] }}</span>
                        <ejs-slider
                          :showButtons="true"
                          v-model.number="myData.attribute.maxZoom"
                          :step="1"
                          :enableRtl="lang=='per'"
                          :min="0"
                          :max="25"
                          :value="myData.attribute.maxZoom"
                          v-model="myData.attribute.maxZoom"
                          :tooltip="{ placement: 'Before', isVisible: true, showOn: 'Auto' }"
                          :ticks="{ placement: 'After', largeStep: 5, showSmallTicks: false }"
                          :type="'MinRange'"></ejs-slider>
                      </div>
                      <div class="col-md-6 col-12 my-3">
                        <span>{{ dict.nativeZoom[lang] }}</span>
                        <ejs-slider
                          :showButtons="true"
                          :step="1"
                          :enableRtl="lang=='per'"
                          :min="0"
                          :max="25"
                          :value="myData.attribute.maxNativeZoom"
                          v-model="myData.attribute.maxNativeZoom"
                          :tooltip="{ placement: 'Before', isVisible: true, showOn: 'Auto' }"
                          :ticks="{ placement: 'After', largeStep: 5, showSmallTicks: false }"
                          :type="'MinRange'"></ejs-slider>
                      </div>
                      <div class="col-md-6 col-12 my-3">
                        <span>{{ dict.viewZoom[lang] }}</span>
                        <ejs-slider
                          :showButtons="true"
                          :step="1"
                          :enableRtl="lang=='per'"
                          :min="0"
                          :max="25"
                          :value="myData.attribute.viewZoom"
                          v-model="myData.attribute.viewZoom"
                          :tooltip="{ placement: 'Before', isVisible: true, showOn: 'Auto' }"
                          :ticks="{ placement: 'After', largeStep: 5, showSmallTicks: false }"
                          :type="'MinRange'"></ejs-slider>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="activeTab === 'basic'" class="h-100 ">
                  <div fluid class="container-fluid p-0 h-100 ">
                    <ul class="nav nav-tabs card-header-tabs">
                      <li v-for="(tab, index) in layerTyps" :key="index" class="nav-item"
                          @click="activeLayersTab = tab">
                        <p class="nav-link"
                           :class="{ active: activeLayersTab === tab }"
                           :id=tab
                           data-toggle="tab"
                           role="tab"
                           aria-controls="tab1"
                           aria-selected="true"
                        >
                          {{ dict[tab][lang] }}
                        </p>
                      </li>
                    </ul>
                    <div class="mt-4" v-show="activeLayersTab=='DB'">
                      <b-table ref="table1" :items="myData.datas.filter(item=>item.category=='DB')"
                               :fields="groupFields" thead-class="hidden_header"
                               responsive="sm" small>
                        <template #cell(modify)="row">
                          <div style="display:flex; justify-content:flex-end;">
                            <b-button pill size="sm" variant="outline-danger"
                                      @click="removeItem(row.item.id,row.item.category)"
                                      class="mr-2"
                                      style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                              <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            </b-button>
                          </div>
                        </template>
                        <!-- <template #cell(color)="row" >
                          <div class="d-flex align-items-center p-2">
                            <input type="color" v-model="row.item.color" class="w-100"/>
                          </div>
                        </template> -->

                        <!-- <template #cell(title)="row" >
                          <div class="d-flex align-items-center p-2">
                            <input type="text" v-model="row.item.title" class="w-100"/>
                          </div>
                        </template> -->

                        <!-- <template #cell(mode)="row" >
                          <select id="mySelect" v-model="row.item.mode" class="form-select" aria-label="Default select example" >
                            <option v-for="(option, index) in modeList" :key="index" :value="option.value">{{ option.text }}</option>
                          </select>
                        </template>
                        <template #cell(shape)="row" >
                          <select id="mySelect" v-model="row.item.shape" class="form-select" aria-label="Default select example" >
                            <option v-for="(option, index) in shapeList" :key="index" :value="option.value">{{ option.text }}</option>
                          </select>
                        </template>
                        <template #cell(dash)="row" >
                          <select id="mySelect" v-model="row.item.dash" class="form-select" aria-label="Default select example" >
                            <option v-for="(option, index) in dashList" :key="index" :value="option.value">{{ option.text }}</option>
                          </select>
                        </template> -->


                      </b-table>
                      <div style="display:flex; justify-content: right; align-items: center;">
                        <b-button @click="addItem" size="sm" variant="outline-danger"
                                  style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                          <i>
                            <font-awesome-icon icon="fas fa-plus" />
                          </i>
                        </b-button>
                      </div>
                    </div>
                    <div v-if="activeLayersTab=='Controller'">

                      <b-table ref="table1" :items="myData.datas.filter(item=>item.category!='DB')"
                               :fields="groupFields" thead-class="hidden_header"
                               responsive="sm" small>
                        <template #cell(key)>
                          <div class="d-flex align-items-center">
                            <ejs-textbox
                              :placeholder="dict.pickData[lang]"
                              :enableRtl="lang == 'per'"
                              floatLabelType="Auto"
                              v-model="selectedData['name']"
                              :showClearButton="true"
                            ></ejs-textbox>
                          </div>
                        </template>
                        <template #cell(modify)="row">
                          <div style="display:flex; justify-content:flex-end;">
                            <b-button pill size="sm" variant="outline-danger"
                                      @click="removeItem(row.item.id,row.item.category)"
                                      class="mr-2"
                                      style="margin-inline: 10px; outline: none !important; border: none; box-shadow: none;">
                              <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                            </b-button>
                          </div>
                        </template>

                      </b-table>
                      <div style="display:flex; justify-content: right; align-items: center;">
                        <b-button @click="pickData" size="sm" variant="outline-danger"
                                  style="width:20px; padding: 0px;  outline: none !important; border: none; box-shadow: none;">
                          <i>
                            <font-awesome-icon icon="fas fa-plus" />
                          </i>
                        </b-button>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </b-container>
      </div>
    </b-modal>

    <b-modal
      :id="'modal-widget-pick-map'+plotId"
      size="lg"
      centered
      body-class="MWS-body-class"
      body-text-variant="dark"
      content-class="shadow"
      @shown="initPickMapModal"
      :dir="dict.dir[lang]"
      @hidden="resetPickMapModal"
      hide-footer
    >
      <template #modal-header>
        <h5>{{ pickMap.modalTitle[lang] }}</h5>
        <i>
          <font-awesome-icon icon="fas fa-xmark" class="close" @click="pickMapHandleClose" />
        </i>
      </template>

      <div>
        <b-container fluid>
          <!-- <b-form-group>
              <b-form-radio-group
                  v-model="type"
                  class="d-flex"
                  style="justify-content:space-around"
              >
                  <b-form-radio value="data">Data</b-form-radio>
                  <b-form-radio value="config" class="ml-5">Config</b-form-radio>
              </b-form-radio-group>
          </b-form-group> -->

          <b-card>
            <b-row align-h="between" class="mb-2">
              <b-col lg="4" sm="6" md="5" class="my-1">
                <ejs-textbox
                  :value="pickMap.filter"
                  :placeholder="dict.search[lang]"
                  :enableRtl="lang === 'per'"
                  floatLabelType="Auto"
                  :showClearButton="true"
                  v-model="pickMap.filter"
                ></ejs-textbox>
              </b-col>
            </b-row>

            <b-table
              :items="pickMap.items"
              :fields="pickMap.fields"
              :current-page="pickMap.currentPage"
              :per-page="pickMap.perPage"
              :filter="pickMap.filter"
              :filter-included-fields="pickMap.filterOn"
              :sort-by.sync="pickMap.sortBy"
              :sort-desc.sync="pickMap.sortDesc"
              :sort-direction="pickMap.sortDirection"
              stacked="md"
              show-empty
              small
              hover
              @filtered="pickMapOnFiltered"
              @row-clicked="pickMapOnRowClicked"
            >
              <template #head(title)="row">
                {{ row.field[lang] }}
              </template>
              <template #head(type)="row">
                {{ row.field[lang] }}
              </template>
            </b-table>

            <b-row align-h="between">
              <b-col lg="3" sm="4" md="4" class="my-1">
                <ejs-dropdownlist
                  :dataSource="pickMap.pageOptions"
                  :fields="{ text: lang, value: 'value' }"
                  v-model="pickMap.perPage"
                  @change="(val)=>{pickMap.perPage=val.value}"
                ></ejs-dropdownlist>
              </b-col>

              <b-col lg="7" sm="8" md="8" class="my-1">
                <b-pagination
                  v-model="pickMap.currentPage"
                  :total-rows="pickMap.totalRows"
                  :per-page="pickMap.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-container>
      </div>
    </b-modal>
    <!--    <b-modal-->
    <!--      id="modal_for_Form"-->
    <!--      size="xl"-->
    <!--      centered-->
    <!--      :title="markerName"-->
    <!--      hide-header-close-->
    <!--      :ok-title="dict.ok[lang]"-->
    <!--      :cancel-title="dict.cancel[lang]"-->
    <!--      :dir="dict.dir[lang]"-->
    <!--      hide-footer-->
    <!--      header-border-variant="primary"-->
    <!--      header-class="my-modal-header "-->
    <!--    >-->
    <!--      <div>-->
    <!--        <form-widget />-->
    <!--      </div>-->
    <!--    </b-modal>-->
    <data-picker-modal />
  </div>
</template>

<script>
/* eslint-disable */
import { latLng } from "leaflet";
import L from "leaflet";
import { DialogUtility } from "@syncfusion/ej2-popups";
import FormWidget from "@/components/formWidget.vue";
import DataPickerModal from "@/components/dataManagerModalDataPicker.vue";

// import light1Off from '../assets/images/light1-off.png';
// import light1On from  '../assets/images/light1-on.png';
// import light2Off from '../assets/images/light2-off.png';
// import light2On from  '../assets/images/light2-on.png';
// import light3Off from '../assets/images/light3-off.png';
// import light3On from  '../assets/images/light3-on.png';
// import light4Off from '../assets/images/light4-off.png';
// import light4On from  '../assets/images/light4-on.png';
import ngeohash from "ngeohash";
import polyline from "@mapbox/polyline";
import { debounce } from "@/services/data.service";
import { v4 } from "uuid";
import { list } from "postcss";

export default {
  name: "CustomPath",
  components: { FormWidget, DataPickerModal },
  props: {
    plotId: String,
    data: Object,
    editable: Boolean,
    resized: Number,
    setting: Boolean
  },
  data() {
    return {
      activeLayers: [],
      categories: [],
      tempTriggered: false,
      container: null,
      controllerContainer: null,
      markerTypes: {},
      customControl: null,
      customController: null,
      selectedData: {
        id: "",
        name: "",
        type: ""
      },
      activeLayer: {},
      layerList: [],
      tempLayerList: [],
      reRender: 0,
      reRenderMap: 0,
      activeTab: "data",
      activeLayersTab: "DB",
      tabList: ["data", "basic"],
      layerTyps: ["DB", "Controller"],
      markerName: "",
      mapinit: false,

      hhh: 300,

      modalTitle: { eng: "Edit layers widget", per: "ویرایش ویجت لایه ها" },
      myData: {
        datas: [],
        attribute: {
          activeLayers: [],
          center: [0, 0],
          minZoom: 1,
          maxZoom: 25,
          maxNativeZoom: 22,
          tileId: 1,
          viewZoom: 18,
          mapHeight: 100,
          mapWidth: 100,
          dataType: "DB"
        }
      },
      watchlist: [],
      groupFields: [
        { key: "name", label: "" },
        // { key: 'type', label: ''},
        { key: "modify", label: "" }
      ],
      myDataTmp: { datas: [] },

      activeZone: null,
      carousel: false,
      schedule: false,
      group: false,
      control: false,
      carouselData: [],
      levels: null,
      markerLayer: null,
      markers: [],
      mymap: [],
      popup: L.popup(),
      map: {
        center: [0, 0],
        minZoom: "",
        maxZoom: "",
        maxNativeZoom: "",
        tileId: "",
        viewZoom: ""

      },
      tileIdList: [{ text: "Select One", value: null },
        { text: "outdoors", value: 0 },
        { text: "streets", value: 1 },
        { text: "dark", value: 2 },
        { text: "light", value: 3 },
        { text: "satellite", value: 4 },
        { text: "satellite streets", value: 5 },
        { text: "navigation day", value: 6 },
        { text: "navigation night", value: 7 }],
      dataLayersType: [{ per: "", eng: "Select One", value: null },
        { per: "لایه های ثابت", eng: "static", value: "static" },
        { per: "لیست لایه های متغیر", eng: "dynamic", value: "dynamic" }
      ],
      tiles: ["outdoors-v11",
        "streets-v11",
        "dark-v10",
        "light-v10",
        "satellite-v9",
        "satellite-streets-v11",
        "navigation-day-v1",
        "navigation-night-v1"],
      equipments: [],
      pickMap: {
        to: "",
        type: "data",
        items: [],
        fields: [
          { key: "type", eng: "type", per: "نوع", label: "type", sortable: true, sortDirection: "asc" },
          { key: "title", eng: "name", per: "نام", label: "name", sortable: true, sortDirection: "asc" }
          // { key: '_id', label: 'id', sortable: true },
        ],
        contFields: [
          {
            key: "key",
            eng: "choose data",
            per: "انتخاب داده لایه",
            label: "data",
            sortable: true,
            sortDirection: "asc"
          },
          { key: "type", eng: "type", per: "نوع", label: "type", sortable: true, sortDirection: "asc" },
          { key: "title", eng: "name", per: "نام", label: "name", sortable: true, sortDirection: "asc" }
          // { key: '_id', label: 'id', sortable: true },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [{ value: 5, per: "5 ردیف", eng: "5 rows" },
          { value: 10, per: "10 ردیف", eng: "10 rows" },
          { value: 15, per: "15 ردیف", eng: "15 rows" },
          { value: 100, per: "100 ردیف", eng: "Show a lot" }],
        sortBy: "name",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        filterData: {},
        modalTitle: { eng: "select layers", per: "انتخاب لایه ها" }
      },
      selectedForm: {},
      dict: {
        pickData: { per: "انتخاب داده", eng: "Pick Data" },
        showMarkerInfo: { eng: "more details", per: "اطلاعات بیشتر" },
        dir: { eng: "ltr", per: "rtl" },
        deleteConfirm: { eng: "Confirmation Delete", per: "تاییدیه حذف" },
        deleteConfirmContent: {
          eng: "Are you sure to remove the data ?",
          per: "آیا از حذف این رکورد اطمینان دارید ؟"
        },
        search: { per: "جستجو", eng: "search" },
        title: { eng: "title", per: "عنوان" },
        data: { eng: "data", per: "داده" },
        dynamic: { eng: "layers type", per: "نوع لایه ها" },
        basic: { eng: "basic", per: "تنظیمات پایه" },
        cancel: { eng: "cancel", per: "لغو" },
        ok: { eng: "ok", per: "تایید" },
        lat: { eng: "latitude", per: "عرض جغرافیایی" },
        lng: { eng: "longitude", per: "طول جغرافیایی" },
        mapTile: { eng: "map tile", per: "تایل نقشه" },
        minZoom: { eng: "Minimum Zoom", per: "حداقل بزرگنمایی" },
        maxZoom: { eng: "Maximum Zoom", per: "حداکثر بزرگنمایی" },
        nativeZoom: { eng: "Max. Native Zoom", per: "حداکثر بزرگنمایی نقشه" },
        viewZoom: { eng: "View Zoom", per: "بزرگنمایی نقشه" },
        DB: { eng: "permanent layers", per: "لایه های دائمی " },
        Controller: { eng: "temporary layer", per: "لایه های موقت" }
      },
      submitted: false,
      successful: false,
      message: "null"
    };
  },
  methods: {
    widthUpdated() {
      debounce(() => {
        this.reRender += 1;
      }, 2000);
    },
    updateDropdown() {
      const vm = this;
      var dropdown = vm.container;
      dropdown.innerHTML = "";
      vm.layerList.forEach(function(item, index) {
        var option = L.DomUtil.create("div", "", dropdown);
        option.style.width = "auto";
        option.style.height = "auto";
        option.style.backgroundColor = "white";
        option.style.boxShadow = "0 2px 5px rgba(0,0,0,0.2)";
        option.style.display = "block";
        option.style.alignItems = "right";
        option.style.justifyContent = "center";
        option.style.cursor = "pointer";
        option.style.textAlign = "right";
        option.style.marginTop = "5px";
        option.style.padding = "5px";
        option.innerHTML = item.title;
        option.onmouseover = function() {
          option.style.backgroundColor = "#f0f0f0";
          if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1) {
            option.style.backgroundColor = "rgba(129,131,232,0.8)";
            option.style.color = "white";
          } else {
            option.style.backgroundColor = "#f0f0f0";
            option.style.color = "black";
          }
        };
        option.onmouseout = function() {
          option.style.backgroundColor = "white";
          if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1) {
            option.style.backgroundColor = "rgba(129,131,232,0.8)";
            option.style.color = "white";
          } else {
            option.style.backgroundColor = "#f0f0f0";
            option.style.color = "black";
          }
        };
        option.onclick = function(e) {
          e.stopPropagation();
          vm.tempTriggered = false;
          if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1)
            vm.myData.attribute.activeLayers.splice(vm.myData.attribute.activeLayers.indexOf(item._id), 1);
          else {
            vm.tempTriggered = false;
            vm.myData.attribute.activeLayers.push(item._id);
            option.style.backgroundColor = "rgba(129,131,232,0.8)";
            option.style.color = "white";
          }
        };
        dropdown.appendChild(option);
      });
    },
    updateDropdownCont() {
      const vm = this;
      var dropdown = vm.controllerContainer;
      dropdown.innerHTML = "";
      vm.tempLayerList.forEach(function(item, index) {
        var option = L.DomUtil.create("div", "", dropdown);
        option.style.width = "auto";
        option.style.height = "auto";
        option.style.backgroundColor = "white";
        option.style.boxShadow = "0 2px 5px rgba(0,0,0,0.2)";
        option.style.display = "block";
        option.style.alignItems = "right";
        option.style.justifyContent = "center";
        option.style.cursor = "pointer";
        option.style.textAlign = "right";
        option.style.marginTop = "5px";
        option.style.padding = "5px";
        option.innerHTML = item.title;
        option.onmouseover = function() {
          option.style.backgroundColor = "#f0f0f0";
          if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1) {
            option.style.backgroundColor = "rgba(129,131,232,0.8)";
            option.style.color = "white";
          } else {
            option.style.backgroundColor = "#f0f0f0";
            option.style.color = "black";
          }
        };
        option.onmouseout = function() {
          option.style.backgroundColor = "white";
          if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1) {
            option.style.backgroundColor = "rgba(129,131,232,0.8)";
            option.style.color = "white";
          } else {
            option.style.backgroundColor = "#f0f0f0";
            option.style.color = "black";
          }
        };
        option.onclick = function(e) {
          e.stopPropagation();
          vm.tempTriggered = true;
          if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1)
            vm.myData.attribute.activeLayers.splice(vm.myData.attribute.activeLayers.indexOf(item._id), 1);
          else {
            vm.tempTriggered = true;
            vm.myData.attribute.activeLayers.push(item._id);
            option.style.backgroundColor = "rgba(129,131,232,0.8)";
            option.style.color = "white";
          }
        };
        dropdown.appendChild(option);
      });
    },
    createCustomCtrl() {
      const vm = this;
      this.customControl = L.Control.extend({
        options: {
          position: "topright"
        },

        onAdd: (map) => {
          var container = L.DomUtil.create("div", "leaflet-bar leaflet-control leaflet-control-custom");
          container.id = this.plotId;
          container.style.position = "relative";
          container.style.backgroundColor = "white";
          container.style.width = "auto";
          container.style.height = "auto";
          container.style.padding = "3px 6px";
          container.style.borderRadius = "5%";
          container.style.boxShadow = "0 2px 5px rgba(0,0,0,0.3)";
          container.style.cursor = "pointer";
          container.style.display = "flex";
          container.style.alignItems = "center";
          container.style.justifyContent = "center";

          container.innerHTML = `<span style=\"font-size: 12px;\">${this.dict.DB[this.lang]}</span>`;

          var dropdown = L.DomUtil.create("div", "", container);
          this.container = dropdown;
          dropdown.style.display = "none";
          dropdown.style.position = "absolute";
          dropdown.style.top = "40px";
          dropdown.style.right = "0px";
          dropdown.style.alignItems = "left";
          dropdown.style.backgroundColor = "transparent";
          dropdown.style.transition = "opacity 0.3s ease-in-out, transform 0.3s ease-in-out";
          dropdown.style.opacity = "0";
          dropdown.style.whiteSpace = "nowrap";

          this.layerList.filter(x => x.type == "marker").forEach(function(item, index) {
            var option = L.DomUtil.create("div", "", dropdown);
            option.style.width = "auto";
            option.style.height = "auto";
            option.style.backgroundColor = "white";
            option.style.boxShadow = "0 2px 5px rgba(0,0,0,0.2)";
            option.style.display = "block";
            option.style.alignItems = "right";
            option.style.justifyContent = "center";
            option.style.cursor = "pointer";
            option.style.textAlign = "right";
            option.style.marginTop = "0px";
            option.style.padding = "5px";
            option.innerHTML = item.title;

            var checkbox = L.DomUtil.create("input", "", option);
            checkbox.type = "checkbox";
            checkbox.style.marginRight = "10px";
            checkbox.style.marginLeft = "10px";
            checkbox.style.marginTop = "5px";
            checkbox.checked = vm.myData.attribute.activeLayers.indexOf(item._id) > -1 ? true : false;


            // option.onmouseover = function() {
            //   if (vm.activeLayers.indexOf(item._id) > -1) {
            //   } else {
            //     option.style.backgroundColor = "#f0f0f0";
            //     option.style.color = "black";
            //   }
            // };
            // option.onmouseout = function() {
            //   if (vm.activeLayers.indexOf(item._id) > -1) {
            //     option.style.backgroundColor = "rgba(129,131,232,0.8)";
            //     option.style.color = "white";
            //   } else {
            //     option.style.backgroundColor = "#f0f0f0";
            //     option.style.color = "black";
            //   }
            // };

            option.onclick = function(e) {
              e.stopPropagation();
              e.stopImmediatePropagation();
              vm.tempTriggered = false;
              if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1) {
                vm.myData.attribute.activeLayers.splice(vm.myData.attribute.activeLayers.indexOf(item._id), 1);
                checkbox.checked = false;
              } else {
                vm.tempTriggered = false;
                checkbox.checked = true;
                vm.myData.attribute.activeLayers.push(item._id);
                // option.style.backgroundColor = "rgba(129,131,232,0.8)";
                // option.style.color = "white";
              }
            };

            dropdown.appendChild(option);
          });

          container.onclick = function(e) {
            e.stopPropagation();
            if (dropdown.style.display === "none" || dropdown.style.opacity === "0") {
              dropdown.style.display = "block";
              setTimeout(() => {
                dropdown.style.opacity = "1";
                dropdown.style.transform = "translateY(0)";
              }, 10);
            } else {
              dropdown.style.opacity = "0";
              dropdown.style.transform = "translateY(-10px)";
              setTimeout(() => {
                dropdown.style.display = "none";
              }, 300);
            }
          };

          var submitButton = L.DomUtil.create("button", "", dropdown);
          submitButton.style.width = "100%";
          submitButton.style.padding = "5px";
          submitButton.style.marginTop = "10px";
          submitButton.style.backgroundColor = "#007bff";
          submitButton.style.color = "white";
          submitButton.style.border = "none";
          submitButton.style.cursor = "pointer";
          submitButton.innerHTML = "ذخیره لایه ها";

          submitButton.onclick = function(e) {
            e.stopPropagation();
            e.stopImmediatePropagation();
            vm.handleSubmit();
          };

          container.onclick = function(e) {
            e.stopPropagation();
            if (dropdown.style.display === "none" || dropdown.style.opacity === "0") {
              dropdown.style.display = "block";
              setTimeout(() => {
                dropdown.style.opacity = "1";
                dropdown.style.transform = "translateY(0)";
              }, 10);
            } else {
              dropdown.style.opacity = "0";
              dropdown.style.transform = "translateY(-10px)";
              setTimeout(() => {
                dropdown.style.display = "none";
              }, 300);
            }
          };

          // Close the dropdown when clicking outside
          map.on("click", function() {
            dropdown.style.opacity = "0";
            dropdown.style.transform = "translateY(-10px)";
            setTimeout(() => {
              dropdown.style.display = "none";
            }, 300);
          });

          return container;
        }
      });

      setTimeout(() => {
        this.mymap.addControl(new this.customControl());
        // vm.changeLayer(this.layerList[0]);
      }, 300);
    },
    createContCustomCtrl() {
      const vm = this;
      this.customController = L.Control.extend({
        options: {
          position: "bottomright"
        },

        onAdd: (map) => {
          var container = L.DomUtil.create("div", "leaflet-bar leaflet-control leaflet-control-custom");
          container.id = this.plotId + "cont";
          container.style.position = "relative";
          container.style.backgroundColor = "white";
          container.style.width = "auto";
          container.style.height = "auto";
          container.style.padding = "3px 6px";
          container.style.borderRadius = "5%";
          container.style.boxShadow = "0 2px 5px rgba(0,0,0,0.3)";
          container.style.cursor = "pointer";
          container.style.display = "flex";
          container.style.alignItems = "center";
          container.style.justifyContent = "center";
          container.style.animation = "blinking 1s infinite";

          var style = document.createElement("style");
          style.innerHTML = `
@keyframes blinking {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
`;
          document.head.appendChild(style);
          container.innerHTML = `<span style=\"font-size: 12px;\">${this.dict.Controller[this.lang]}</span>`;

          var dropdown = L.DomUtil.create("div", "", container);
          this.controllerContainer = dropdown;
          dropdown.style.display = "none";
          dropdown.style.position = "absolute";
          dropdown.style.bottom = "40px";
          dropdown.style.right = "0px";
          dropdown.style.alignItems = "left";
          dropdown.style.backgroundColor = "transparent";
          dropdown.style.transition = "opacity 0.3s ease-in-out, transform 0.3s ease-in-out";
          dropdown.style.opacity = "0";
          dropdown.style.whiteSpace = "nowrap";

          // dropdown.onclick = function() {
          // };

          this.tempLayerList.forEach(function(item, index) {
            var option = L.DomUtil.create("div", "", dropdown);
            option.style.width = "auto";
            option.style.height = "auto";
            option.style.backgroundColor = "white";
            option.style.boxShadow = "0 2px 5px rgba(0,0,0,0.2)";
            option.style.display = "block";
            option.style.alignItems = "right";
            option.style.justifyContent = "center";
            option.style.cursor = "pointer";
            option.style.textAlign = "right";
            option.style.marginTop = "5px";
            option.style.padding = "5px";
            option.innerHTML = item.title;

            option.onmouseover = function() {
              if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1) {
                option.style.backgroundColor = "rgba(129,131,232,0.8)";
                option.style.color = "white";
              } else {
                option.style.backgroundColor = "#f0f0f0";
                option.style.color = "black";
              }
            };
            option.onmouseout = function() {
              if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1) {
                option.style.backgroundColor = "rgba(129,131,232,0.8)";
                option.style.color = "white";
              } else {
                option.style.backgroundColor = "#f0f0f0";
                option.style.color = "black";
              }
            };

            option.onclick = function(e) {
              e.stopPropagation();
              vm.tempTriggered = true;
              container.style.animation = "none";
              if (vm.myData.attribute.activeLayers.indexOf(item._id) > -1)
                vm.myData.attribute.activeLayers.splice(vm.myData.attribute.activeLayers.indexOf(item._id), 1);
              else {
                vm.tempTriggered = true;
                vm.myData.attribute.activeLayers.push(item._id);
                option.style.backgroundColor = "rgba(129,131,232,0.8)";
                option.style.color = "white";
              }
            };

            dropdown.appendChild(option);
          });

          container.onclick = function(e) {
            e.stopPropagation();
            container.style.animation = "none";

            if (dropdown.style.display === "none" || dropdown.style.opacity === "0") {
              dropdown.style.display = "block";
              setTimeout(() => {
                dropdown.style.opacity = "1";
                dropdown.style.transform = "translateY(0)";
              }, 10);
            } else {
              dropdown.style.opacity = "0";
              dropdown.style.transform = "translateY(-10px)";
              setTimeout(() => {
                dropdown.style.display = "none";
              }, 300);
            }
          };

          // Close the dropdown when clicking outside
          map.on("click", function() {
            dropdown.style.opacity = "0";
            dropdown.style.transform = "translateY(-10px)";
            setTimeout(() => {
              dropdown.style.display = "none";
            }, 300);
          });

          return container;
        }
      });

      setTimeout(() => {
        this.mymap.addControl(new this.customController());
      }, 300);
    },
    pickData() {
      this.$store.dispatch("chpge/parent2child", {
        task: "pickedData",
        from: "widgetMap" + this.plotId,
        filter: {
          dataType: ["Object", "freeObject", "freeArray"],
          usedBy: [],
          parents: [],
          archive: null,
          perPage: 5000,
          page: 1,
          withChild: true
        }
      });
      this.$root.$emit("bv::show::modal", "modal-data-picker");
    },
    getData(id) {
      this.$store.dispatch("panel/getData", { id: id }).then((res) => {
        res.forEach((item => {
          if (Array.isArray(item.value) && item.value.length > 0) {
            item.value.forEach(subValue => {
              // this.createMarkers(subValue);
              if (this.tempLayerList.length > 0) {
                let foundedItem = this.tempLayerList.find(x => x._id == subValue._id);
                if (foundedItem != undefined)
                  foundedItem = subValue;
                else  this.tempLayerList.push(subValue);
              } else this.tempLayerList.push(subValue);

            });
          } else if (Object.keys(item.value).length > 0) {
            // this.createMarkers(item.value);
            this.tempLayerList.push(item.value);
          }
        }));
      });
    },
    async getMarkerData(id) {
      try {
        const res = await this.$store.dispatch("panel/getData", { id: id });
        return res;
      } catch (error) {
        console.error("An error occurred:", error);
        return null;
      }
    },
    async getCategoryData(id) {
      try {
        const res = await this.categories.find(item => item._id == id).value;
        return res;
      } catch (error) {
        console.error("An error occurred:", error);
        return null;
      }
    },
    async getMarkerTypes(id) {
      try {
        const res = await this.$store.dispatch("data/getMarkerTypeById", { _id: id });
        return res;
      } catch (error) {
        console.error("An error occurred:", error);
        return null;
      }
    },
    changeLayer(item) {
      let tileLayers = [];
      this.mymap.eachLayer((layer) => {
        if (layer instanceof L.Marker)
          this.mymap.removeLayer(layer);
        if (layer instanceof L.TileLayer) {
          tileLayers.push(layer);
        }
        if (layer instanceof L.Polyline) {
          this.mymap.removeLayer(layer);
        }
      });
      if (tileLayers.length >= 1 && item != "none") {
        this.createMarkers(item);
        this.mymap.setView(item.details.map.center, item.details.map.viewZoom);
      }
    },
    runPolyLine(routes) {
      const encodedPolyline = [];

      routes.forEach((route) => {
        route.legs.forEach(step => {
          step.steps.forEach((line) => {
            encodedPolyline.push(line.polyline);
          });
        });
      });

      const polylineOptionsBorder = {
        opacity: 0.8,
        lineJoin: "round",
        lineCap: "round",
        smoothFactor: 0.1,
        interactive: false,
        stroke: true,
        color: "#3a41f3",
        dashArray: null,
        weight: 10
      };

      const polylineOptions = {
        color: "white",           // Line color
        weight: 5,               // Line width in pixels
        opacity: 1,            // Line opacity (0 to 1)
        lineJoin: "round",       // Corner style of the polyline
        lineCap: "round",        // Ending style of the polyline
        smoothFactor: 0.1,       // How much to simplify the polyline
        interactive: true        // Enable popups and tooltips
      };

      const coordinates = [];
      encodedPolyline.forEach((item) => {
        coordinates.push(polyline.decode(item));
      });

      L.polyline(coordinates, polylineOptionsBorder).addTo(this.mymap);
      L.polyline(coordinates, polylineOptions).addTo(this.mymap);
      this.mymap.fitBounds(L.polyline(coordinates).getBounds());

    },
    zoneOver: function(e) {
      e.target.setStyle({ fillColorPrev: e.target.options.fillColor });
      e.target.setStyle({ fillColor: "#96ceb4" });
    },
    zoneLeft: function(e) {
      e.target.setStyle({ fillColor: e.target.options.fillColorPrev });
    },
    zoneClick: function(e) {

      if (e.target.options.onClick.type === "page") {
      }

      this.$store.dispatch("chpge/changeDashPage", e.target.options.onClick.value);
    },
    async processMarkers(markerList) {
      for (const item of markerList) {
        try {
          const value = await this.getMarkerData(item.data);
          let coor = [];
          value.forEach(x => {
            if (x.name == "lat") {
              coor[0] = x.value;
            } else if (x.name == "lng")
              coor[1] = x.value;
          });

          // if (item.variable) {
          //   this.watchlist.push({
          //     type: "variable",
          //     icon: item.id,
          //     data: item.data,
          //     values: []
          //   });
          // }

          let createIcon = this.markerTypes[item.type]["icon"];
          let marker = L.marker(coor, {
            icon: L.icon(createIcon),
            draggable: false,
            autoPanOnFocus: true,
            id: item.id
          }).bindPopup(item.name).openPopup();
          this.markerLayer.addLayer(marker);
          if (item.movable == true) {
            this.watchlist.push({
              type: "movable",
              icon: item.id,
              lat: item.data,
              lng: item.data
            });
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      }
      // if (this.watchlist.length > 0) {
      //   var datas = [];
      //   this.watchlist.forEach((item) => {
      //     if (item.type != "movable")
      //       datas.push(item.data);
      //     else {
      //       datas.push(item.lat);
      //     }
      //   });
      //   this.sendDataSSE(datas);
      // }
    },
    async getMarkerTypeList(markerList) {
      let ID = [];
      markerList.forEach((item) => ID.push(item.typeId));
      const typeList = await this.getMarkerTypes(ID);
      return typeList;
    },
    async getDataList(markerList) {
      let ID = [];
      let catsID = [];

      markerList.forEach((item) => {
        if (!("isCategory" in item) || item.isCategory === false) {
          ID.push(item.data.id);
        }
      });


      let dataList = await this.getMarkerData(ID);
      return dataList;
    },
    async getDataCategory(markerList) {
      let ID = [];
      let lists = markerList.filter((item) => item.isCategory);

      if (lists.length > 0) {
        ID = await Promise.all(lists.map((item) => this.getCategoryData(item.data)));
      }
      if (ID.length > 0) {
        ID = ID.reduce((acc, val) => acc.concat(val), []);
      }

      const result = await this.getMarkerData(ID);
      return result;
    },

    async processMarkersForLayer(markerList, layerId) {
      var datas = [];
      const markerTypeList = await this.getMarkerTypeList(markerList);
      // const dataList = await this.getDataList(markerList);
      // let list = await this.getDataCategory(markerList);

      for (const item of markerList) {
        // if (Array.isArray(markerTypeList))
        const markerType = markerTypeList.find(x => x._id == item.typeId);
        // console.log(markerType);
        // else

        let createIcon = {
          "iconUrl": markerType.details.properties.icon,
          "iconSize": [markerType.details.properties.width || 30, markerType.details.properties.height || 30],
          "iconAnchor": [markerType.details.properties.width / 2, markerType.details.properties.height / 2],
          "popupAnchor": [0, -10],
          "shadowAnchor": [0, 0],
          "shadowSize": [10, 10]
        };

        if (!("isCategory" in item) || !item.isCategory) {
          try {
            let ID = typeof item.data != "string" ? item.data.id : item.data;
            const value = await this.getMarkerData([ID]);
            let marker;
            value[0].value.forEach(x => {
              let foundedItem2 = value[0].value.find(x => x.name == markerType.details.properties.name);
              if (x.name == markerType.details.properties.locationProperty && x.value.length > 0) {
                datas.push(ID + "_" + x.id);

                marker = L.marker(x.value.split(","), {
                  icon: L.icon(createIcon),
                  draggable: false,
                  id: item.id
                }).bindPopup(foundedItem2 ? foundedItem2.value : "").openPopup();
                marker.addTo(this.mymap);
                this.watchlist.push({
                  type: "movable",
                  icon: item.id,
                  parent: ID,
                  data: x.id
                });
                this.markerLayer.addLayer(marker);
              }
            });
            if (markerType.details.variableIcon.enable) {
              let foundedItem;
              if (Array.isArray(value)) {
                value.forEach((x) => {
                  foundedItem = x.value.find(y => y.name == markerType.details.variableIcon.propertyName);
                });
              } else
                foundedItem = value.find(x => x.name == markerType.details.variableIcon.propertyName);
              this.watchlist.push({
                type: "variable",
                icon: item.id,
                parent: ID,
                data: foundedItem.id,
                values: markerType.details.variableIcon.shapes
              });
              datas.push(ID + "_" + foundedItem.id);
            }
            if ("onClick" in markerType.details && markerType.details.onClick.enable == true) {
              if (markerType.details.onClick.type != "form") {
                marker.bindPopup(`
                <div class="d-flex">
                  <i class="text-info mx-2 fas fa-plus-square" id="detail-${item.id}" data-id="${item.id}">
                    <p class="text-dark">${markerType.details.properties.name}</p>
                    ${this.dict.showMarkerInfo[this.lang]}
                  </i>
           </div>

          `).on("popupopen", () => {
                  document.getElementById(`detail-${item.id}`).addEventListener("click", (e) => {
                    console.log("click");
                    // this.$store.dispatch("chpge/changeDashPage", item.onClick.value);
                  });
                });
              } else {
                marker.bindPopup(`
          <div class="col-12">
            <i class="text-info mx-2 fas fa-plus-square" id="form-${item.id}">
            ${markerType.details.onClick.type}
            </i>
            </div>

          `).on("popupopen", () => {
                  document.getElementById(`form-${item.id}`).addEventListener("click", (e) => {
                    console.log("form clicked");
                    // this.$bvModal.show("modal_for_Form");
                    // this.markerName = item.name;
                    // this.getForm(item.onClick.form.id, item.onClick.form.value);
                  });
                });
              }
            }
          } catch (error) {
            console.error("An error occurred:", error);
          }
        }
        if (item.isCategory) {
          try {
            let list;
            const values = await this.getCategoryData(item.data);
            if (values.length > 0) {
              list = await this.getMarkerData(values);
            }
            list.forEach((value) => {
              let marker;
              value.value.forEach(x => {
                let foundedItem2 = value.value.find(x => x.name == markerType.details.properties.name);
                if (x.name == markerType.details.properties.locationProperty && x.value.length > 0) {
                  datas.push(value.id + "_" + x.id);

                  marker = L.marker(x.value.split(","), {
                    icon: L.icon(createIcon),
                    draggable: false,
                    id: value.id
                  }).bindPopup(foundedItem2 ? foundedItem2.value : "").openPopup();
                  marker.addTo(this.mymap);
                  this.watchlist.push({
                    type: "movable",
                    icon: value.id,
                    parent: value.id,
                    data: x.id
                  });
                  this.markerLayer.addLayer(marker);
                }
              });
              if (markerType.details.variableIcon.enable) {
                let foundedItem = value.value.find(x => x.name == markerType.details.variableIcon.propertyName);
                this.watchlist.push({
                  type: "variable",
                  icon: value.id,
                  parent: value.id,
                  data: foundedItem.id,
                  values: markerType.details.variableIcon.shapes
                });
                datas.push(value.id + "_" + foundedItem.id);
              }
              if ("onClick" in markerType.details && markerType.details.onClick.enable == true) {
                if (markerType.details.onClick.type != "form") {
                  marker.bindPopup(`
                    <div class="d-flex">
                      <i class="text-info mx-2 fas fa-plus-square" id="detail-${item.id}" data-id="${item.id}">
                        <p class="text-dark">${markerType.details.properties.name}</p>
                        ${this.dict.showMarkerInfo[this.lang]}
                      </i>
               </div>

              `).on("popupopen", () => {
                    document.getElementById(`detail-${item.id}`).addEventListener("click", (e) => {
                      console.log("click");
                      // this.$store.dispatch("chpge/changeDashPage", item.onClick.value);
                    });
                  });
                } else {
                  marker.bindPopup(`
              <div class="col-12">
                <i class="text-info mx-2 fas fa-plus-square" id="form-${item.id}">
                ${markerType.details.onClick.type}
                </i>
                </div>

              `).on("popupopen", () => {
                    document.getElementById(`form-${item.id}`).addEventListener("click", (e) => {
                      console.log("form clicked");
                      // this.$bvModal.show("modal_for_Form");
                      // this.markerName = item.name;
                      // this.getForm(item.onClick.form.id, item.onClick.form.value);
                    });
                  });
                }
              }
            });


          } catch (error) {
            console.error("An error occurred:", error);
          }
        }
      }

      if (this.watchlist.length > 0) {
        await this.sendDataSSE(datas);
      }
    },
    createMarkers: function(layer) {
      this.markerLayer = new L.layerGroup;

      this.markerLayer["layerId"] = layer._id;

      this.markerLayer.addTo(this.mymap);

      var markerList = layer.details.markers;

      //
      // locations.forEach((location) => {
      //
      //   var marker = L.marker([location.lat, location.lng]).bindPopup(location.popup).openPopup();
      //   this.markerLayer.addLayer(marker);
      //
      // });

      this.processMarkersForLayer(markerList, layer._id);


      if ("routes" in layer) {
        this.runPolyLine(layer.routes);
      }

    },
    removeMarkers: function(spaces) {
      var keys = Object.keys(this.levels),
        i = keys.length;

      for (i in keys) {
        for (var j = 0; j < this.markers.length; j++) {
          if (keys[i] === this.markers[j].options.id.floor)
            this.levels[keys[i]].removeLayer(this.markers[j]);
        }
      }
    },
    onMapZoom: function(e) {
      var zoomlevel = this.mymap.getZoom();
      if (zoomlevel > 21) {
        this.addMarkers();
      } else {
        this.removeMarkers();
      }
    },
    setupLeafletMap: function() {
      this.mymap = L.map(this.plotId);
      this.map.center = this.myData.attribute.center;
      this.map.minZoom = this.myData.attribute.minZoom;
      this.map.maxZoom = this.myData.attribute.maxZoom;
      this.map.maxNativeZoom = this.myData.attribute.maxNativeZoom;
      this.map.tileId = this.myData.attribute.tileId;
      this.map.viewZoom = this.myData.attribute.viewZoom;
      this.createMap();
      if (this.myData.datas.length > 0) {
        let counter = 0;
        let layer = [];

        for (var i = 0; i < this.myData.datas.length; i++) {
          if (this.myData.datas[i].category == "DB") {
            this.getfloorMap(this.myData.datas[i].id);
            counter++;
          } else if (this.myData.datas[i].category != "DB") {
            counter++;
            layer.push(this.myData.datas[i].id);
          }
        }

        if (counter === this.myData.datas.length) {
          this.getData(layer); // Call this.getData() after loop finishes
        }
      }
    },
    addMarkers: function() {
      var keys = Object.keys(this.levels),
        i = keys.length;

      for (i in keys) {
        for (var j = 0; j < this.markers.length; j++) {
          if (keys[i] === this.markers[j].options.id.floor)
            this.levels[keys[i]].addLayer(this.markers[j]);
        }
      }
    },
    createMap: function() {
      this.mymap.setView(this.map.center, this.map.viewZoom);
      var layers = [];
      this.mymap.eachLayer(function(layer) {
        layers.push(layer);
      });
      layers.forEach((item) => this.mymap.removeLayer(item));
      // var mapOptions = {
      //   minZoom: this.map.minZoom,
      //   maxZoom: this.map.maxZoom,
      //   maxNativeZoom: this.map.maxNativeZoom,
      //   attribution: "&copy; <a href=\"http://www.openstreetmap.org/copyright\">OpenStreetMap</a>",
      //   id: "mapbox/" + this.tiles[this.map.tileId],//'mapbox/outdoors-v11', //dark-v10, light-v10, streets-v11, outdoors-v11, satellite-v9, satellite-streets-v11, navigation-day-v1, navigation-night-v1
      //   accessToken: "pk.eyJ1IjoiYWhtYWRzaGFmaWVpIiwiYSI6ImNrc2VwdHZ1bjB4aHEybnAyN282a29nMDEifQ.6uf31gUq1E3Em0x9MsdeRg"
      // };
      // L.tileLayer("https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}", mapOptions).addTo(this.mymap);

      L.tileLayer.wms("https://name.isfahan.ir/saeeserver/wms", {
        layers: "monam:boostan,monam:roads,monam:water",
        format: "image/png",
        transparent: true,
        version: "1.3.0",
        tiled: true,
        crs: L.CRS.EPSG32639,
        uppercase: true,
        styles: "",
        attribution: false,
        minZoom: this.map.minZoom,
        maxZoom: this.map.maxZoom,
        maxNativeZoom: this.map.maxNativeZoom
      }).addTo(this.mymap);


      // var pointList=[];
      // for (var j = 0; j < floor.floorPoints.length; j++){
      //     pointList[j] = latLng(floor.floorPoints[j].lat,floor.floorPoints[j].lng);
      // }

      // var geometry = new L.Polygon(pointList, floor.floorOptions);
      // this.levels = new L.layerGroup;
      // this.levels.addLayer(geometry);

      // floor.floorZones.sort((a,b)=>(+a.zoneOptions.order > +b.zoneOptions.order) ? 1 : ((+b.zoneOptions.order > +a.zoneOptions.order) ? -1 : 0))

      // for (var zone of floor.floorZones){
      //   var pointList=[];
      //   for (var j = 0; j < zone.zonePoints.length; j++){
      //       pointList[j] = latLng(zone.zonePoints[j].lat,zone.zonePoints[j].lng);
      //   }
      //   zone.zoneOptions['_id']={floor:floor._id, zone:zone._id};

      //   var geometry = new L.Polygon(pointList, zone.zoneOptions);
      //   if (zone.zoneOptions.type==="lightZone")// || space.properties.stairs!=="undefined")
      //   {
      //     geometry.on('click', this.zoneClick);
      //     geometry.on('mouseover', this.zoneOver);
      //     geometry.on('mouseout',  this.zoneLeft);
      //   }
      //   this.levels.addLayer(geometry);
      // }
      // this.levels.addTo(this.mymap);
    },
    createSpaces: function(floor) {
      // this.mymap.setView(this.map.center, this.map.viewZoom);
      // var layers=[];
      // this.mymap.eachLayer(function ( layer) {
      //     layers.push(layer)
      // });
      // layers.forEach((item)=>this.mymap.removeLayer(item))

      // var mapOptions = {
      //                 minZoom: this.map.minZoom,
      //                 maxZoom: this.map.maxZoom,
      //                 maxNativeZoom: this.map.maxNativeZoom,
      //                 attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      //                 id: 'mapbox/' + this.tiles[this.map.tileId],//'mapbox/outdoors-v11', //dark-v10, light-v10, streets-v11, outdoors-v11, satellite-v9, satellite-streets-v11, navigation-day-v1, navigation-night-v1
      //                 accessToken: 'pk.eyJ1IjoiYWhtYWRzaGFmaWVpIiwiYSI6ImNrc2VwdHZ1bjB4aHEybnAyN282a29nMDEifQ.6uf31gUq1E3Em0x9MsdeRg'
      // }

      // L.tileLayer("https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",mapOptions).addTo(this.mymap);

      var pointList = [];
      for (var j = 0; j < floor.details.floorPoints.length; j++) {
        pointList[j] = latLng(floor.details.floorPoints[j].lat, floor.details.floorPoints[j].lng);
      }

      var geometry = new L.Polygon(pointList, floor.details.floorOptions);
      this.levels = new L.layerGroup;
      this.levels.addLayer(geometry);

      floor.details.floorZones.sort((a, b) => (+a.zoneOptions.order > +b.zoneOptions.order) ? 1 : ((+b.zoneOptions.order > +a.zoneOptions.order) ? -1 : 0));

      for (var zone of floor.details.floorZones) {
        var pointList = [];
        for (var j = 0; j < zone.zonePoints.length; j++) {
          pointList[j] = latLng(zone.zonePoints[j].lat, zone.zonePoints[j].lng);
        }
        zone.zoneOptions["_id"] = { floor: floor._id, zone: zone._id };

        var geometry = new L.Polygon(pointList, zone.zoneOptions);
        if (zone.zoneOptions.onClick.type) {
          geometry.on("click", this.zoneClick);
          geometry.on("mouseover", this.zoneOver);
          geometry.on("mouseout", this.zoneLeft);
        }
        this.levels.addLayer(geometry);
      }
      this.levels.addTo(this.mymap);
    },
    getfloorMap(floor) {
      this.$store.dispatch("data/getSingleMapLayer", { id: floor }).then(
        data => {
          if (data._id === floor)
            // console.log('====>>> layer',data)
            if (data.type === "indoormap")
              this.createSpaces(data);
            else if (data.type === "marker") {
              // this.createMarkers(data);
              this.layerList.push(data);
              // this.changeLayer(this.layerList[0]);
            }
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          // this.successful = false;
        }
      );
    },
    removeWidget() {
      this.$bvModal.msgBoxConfirm("Please confirm that you want to delete this widget")
        .then(value => {
          if (value === true) {
            this.$store.dispatch("chpge/child2parent", { task: "rmDashItem", item: this.plotId });
          }
        })
        .catch(err => {
        });
    },
    editWidget() {
      // console.log('editwidget',this.plotId)
      this.$root.$emit("bv::show::modal", this.plotId);
    },
    getForm(id, assignedData) {
      this.$store.dispatch("data/formGetById", { _id: id }).then(res => {
        this.selectedForm = res;
        this.$store.dispatch("chpge/child2child", {
          task: "pick_form",
          to: "widget_Form",
          item: res,
          assignedData: assignedData
        });
      });
    },
    initModal: function() {
      // this.$refs.table1.refresh();
      this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
      this.reRender += 1;
    },
    resetModal: function() {
      this.myData = JSON.parse(JSON.stringify(this.myDataTmp));

      this.submitted = null;

      this.successful = null;
      this.message = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then(isValid => {
        if (isValid) {

          this.$store.dispatch("data/editDashboard", { _id: this.plotId, data: this.myData }).then(
            data => {
              this.$store.dispatch("chpge/child2parent", { task: "updateDash" });
              this.myDataTmp = JSON.parse(JSON.stringify(this.myData));
              this.$nextTick(() => {
                this.$bvModal.hide(this.plotId);
              });
            },
            error => {
              this.message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString();
              this.successful = false;
              // alert(this.message);
            }
          );
        }
      });
    },
    resize() {
      const divElement = this.$refs.mainCont;
      if (divElement) {
        this.myData.attribute.mapHeight = divElement.clientHeight - 58;
        this.myData.attribute.mapWidth = divElement.clientWidth - 16;
      }
      // setTimeout(() => {
      //   this.setupLeafletMap();
      // }, "1000");

    },
    addItem: function(type) {
      this.$root.$emit("bv::show::modal", "modal-widget-pick-map" + this.plotId);
    },
    removeItem: function(id, cat) {
      const DialogObj = DialogUtility.confirm({
        title: this.dict.deleteConfirm[this.lang],
        locale: this.lang,
        position: "center center",
        Enabletrl: this.lang == "per" ? true : false,
        content: this.dict.deleteConfirmContent[this.lang],
        okButton: {
          text: this.dict.ok[this.lang], click: () => {
            let founded = this.myData.datas.find(item => item.category == cat && item.id == id);
            this.myData.datas.splice(this.myData.datas.indexOf(founded), 1);
            DialogObj.hide();
          }
        },
        cancelButton: { text: this.dict.cancel[this.lang] },
        showCloseIcon: false,
        closeOnEscape: false,
        animationSettings: { effect: "Zoom" }
      });
      if (this.lang == "per") {
        DialogObj.element.classList.add("e-control-rtl");
      } else {
        DialogObj.element.classList.remove("e-control-rtl");
        DialogObj.element.classList.add("e-control-ltr");
      }

    },
    initPickMapModal: function() {
      this.getAllMaps();
    },
    resetPickMapModal: function() {
      this.pickMap.items = [];
    },
    getAllMaps() {
      this.$store.dispatch("data/getAllMaps").then(
        data => {
          this.pickMap.items = data.message;
          this.pickMap.totalRows = this.pickMap.items.length;
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    sendDataSSE(datas) {
      this.myData.datas.forEach(x => {
        if (datas.indexOf(x.id) <= -1)
          datas.push(x.id);
      });
      this.$store.dispatch("data/sendDataForSSE", { id: datas });
    },
    pickMapOnFiltered(filteredItems) {
      this.pickMap.totalRows = filteredItems.length;
      this.pickMap.currentPage = 1;
    },
    pickMapOnRowClicked(item, index, event) {
      this.myData.datas.push({ type: item.type, id: item._id, name: item.title, category: "DB" });
      this.$bvModal.hide("modal-widget-pick-map" + this.plotId);
    },
    pickMapHandleClose() {
      this.$bvModal.hide("modal-widget-pick-map");
    },
    getAllCategories() {
      this.$store.dispatch("data/getAllCategoryforList").then((data) => {
          this.categories = data;
        }
      );
    }
  },
  mounted() {
    window.addEventListener("resize", this.widthUpdated);
    this.getAllCategories();

    if (this.data && this.data.datas && this.data.attribute) {
      this.myData = this.data;
      if (!("activeLayers" in this.myData.attribute)) {
        this.myData.attribute["activeLayers"] = [];
      }


      setTimeout(() => {
        this.resize();
        this.mapinit = true;
        setTimeout(() => {
          this.setupLeafletMap();
          this.createCustomCtrl();
          // this.createContCustomCtrl();
        }, "50");
      }, "200");
    }
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    updatedData() {
      return this.$store.state.panel.data;
    },
    child2parent() {
      return this.$store.state.chpge.child2child;
    }
  },
  watch: {
    "myData.attribute.activeLayers": {
      handler(val) {
        setTimeout(() => {
          if (val.length > 0) {
            val.forEach(item => {
              this.tempLayerList.forEach(layer => {
                if (layer._id == item) {
                  this.changeLayer(layer);
                }
              });
              this.layerList.forEach(layer => {
                if (layer._id == item) {
                  this.changeLayer(layer);
                }
              });
            });
          } else if (val.length <= 0) {
            this.changeLayer("none");
          }
        }, 500);
      }, deep: true
    },

    // "myData.attribute.dataType": {
    //   handler(val) {
    //     if (val != "static")
    //       this.myData.datas = [];
    //   }, deep: true
    // },
    resized() {
      this.resize();
    },
    setting(newValue) {
      if (newValue) {
        this.$root.$emit("bv::show::modal", this.plotId);
      }
    },
    layerList(val) {
      if (val.length > 1)
        this.updateDropdown();
    },
    tempLayerList(val) {
      if (this.customController == null)
        this.createContCustomCtrl();
      else if (val.length > 1)
        this.updateDropdownCont();

    },
    updatedData(newValue) {
      let news = [];
      setTimeout(() => {

        for (var iData of newValue) {
          // let foundedLayer = this.myData.datas.find(item => item.id == iData.id);
          // if (foundedLayer != undefined) {
          //   if (foundedLayer.type == "freeArray") {
          //     iData.value.forEach(x => {
          //       let Layer = this.tempLayerList.find(layer => layer._id == x._id);
          //       if (this.tempLayerList.indexOf(Layer) <= -1) {
          //         this.tempLayerList.push(x);
          //       }
          //     });
          //   }
          // }


          this.myData.datas.forEach(item => {
            if (item.id == iData.id) {
              if (Array.isArray(iData.value)) {
                iData.value.forEach((res) => {
                  let foundedItem = this.tempLayerList.find(x => x._id == res._id);
                  if (foundedItem != undefined) {

                    foundedItem = res;
                  } else if (foundedItem == undefined) {

                    this.tempLayerList.push(res);
                  }
                });
              } else if (iData.value != null && !(Array.isArray(iData.value))) {
                let lay = this.tempLayerList.find(x => x._id == iData.value._id);
                if (lay == undefined && Object.keys(iData.value).length > 0) {
                  this.tempLayerList.push(iData.value);

                  if (this.tempLayerList.length == 1)
                    this.createContCustomCtrl();

                } else if (Object.keys(iData.value).length > 0) {
                  lay.details = iData.value.details;
                }
                if (iData.value == null || Object.keys(iData.value).length <= 0) {
                  if (this.tempLayerList.length == 1) {
                    // this.mymap.removeControl(this.customController);
                  }
                }
              }
            }
          });


          if (Array.isArray(iData.value)) {
            iData.value.forEach((x) => {
              this.watchlist.forEach(wData => {
                if (wData.type != "movable") {
                  if ((JSON.stringify(iData.id) === JSON.stringify(wData.parent))) {
                    for (var val of wData.values) {
                      if (val.value == x.value) {
                        var selIcon = val.icon;
                        this.markerLayer.eachLayer(function(marker) {
                          if (marker.options.id === wData.icon) {
                            let newIcon = L.icon({
                              iconUrl: selIcon.address,
                              iconSize: marker.options.icon.options.iconSize,
                              iconAnchor: marker.options.icon.options.iconAnchor,
                              popupAnchor: marker.options.icon.options.popupAnchor,
                              shadowAnchor: marker.options.icon.options.shadowAnchor
                            });
                            marker.setIcon(newIcon);
                          }
                        });
                        break;
                      }
                    }
                  }
                } else if (wData.type == "movable") {
                  if (x.id == wData.data && iData.id == wData.parent) {
                    this.markerLayer.eachLayer(function(marker) {
                      if (marker.options.id === wData.icon) {
                        if (x.value.length > 0)
                          marker.setLatLng(x.value.split(","));
                      }
                    });
                  }
                }
              });
            });
          } else {
            this.watchlist.forEach(wData => {
              let sentID = iData.id.split("_");
              if (wData.type != "movable") {
                if ((JSON.stringify(sentID[0]) === JSON.stringify(wData.parent))) {
                  for (var val of wData.values) {
                    if (val.value == iData.value) {
                      var selIcon = val.icon;
                      this.markerLayer.eachLayer(function(marker) {
                        if (marker.options.id === wData.icon) {
                          let newIcon = L.icon({
                            iconUrl: selIcon.address,
                            iconSize: marker.options.icon.options.iconSize,
                            iconAnchor: marker.options.icon.options.iconAnchor,
                            popupAnchor: marker.options.icon.options.popupAnchor,
                            shadowAnchor: marker.options.icon.options.shadowAnchor
                          });
                          marker.setIcon(newIcon);
                        }
                      });
                      break;
                    }
                  }
                }
              } else if (wData.type == "movable") {
                if (sentID[1] == wData.data && sentID[0] == wData.parent) {
                  this.markerLayer.eachLayer(function(marker) {
                    if (marker.options.id === wData.icon) {
                      if (iData.value.length > 0)
                        marker.setLatLng(iData.value.split(","));
                    }
                  });
                }
              }
            });
          }
        }
      }, 1);
    },
    child2parent(newValue) {
      if (newValue.to == "widgetMap" + this.plotId) {
        if (Object.keys(newValue.item).length > 0) {
          this.myData.datas.push({
            id: newValue.item.id,
            name: newValue.item.name,
            type: newValue.item.dataType,
            category: "controller"
          });
          // this.getData(newValue.item.id);
        }
      }
    }
  }
};
</script>


<style scoped>


.remove {
  cursor: pointer;
  color: rgb(175, 235, 11);
  font-size: 18px;
}

.edit {
  cursor: pointer;
  color: rgb(9, 180, 210);
  font-size: 18px;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(-100%);
}


</style>

<style>
.custom-polyline {
  stroke: blue; /* Border color */
  stroke-width: 10px; /* Border width */
}

.leaflet-control-attribution {
  display: none;

}
</style>






