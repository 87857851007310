<script>
import {
  AccordionComponent
} from "@syncfusion/ej2-vue-navigations";
import { FormValidator } from "@syncfusion/ej2-inputs";
import store from "@/store";
import { ToastUtility } from "@syncfusion/ej2-vue-notifications";
export default {
  name: "createRoles",
  components: {
    "ejs-accordion": AccordionComponent
  },
  props: {
    idWb: {
      default: ""
    }
  },
  data() {
    return {
      categoryList: [],
      selectedCats: [],
      curTableOptions: {
        items: [],
        name: "",
        fields: [
          {
            key: "name",
            label: "name",
            sortable: true,
            sortDirection: "asc"
          },
          { key: "modify", label: "" }
          // { key: 'source', label: 'Associated with', sortable: true },
          // { key: 'message', label: 'Message', sortable: true },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
          { value: 5, text: "5 rows" },
          { value: 10, text: "10 rows" },
          { value: 15, text: "15 rows" },
          { value: 100, text: "Show a lot" }
        ],
        sortBy: "active",
        sortDesc: true,
        sortDirection: "asc",
        filter: null,
        filterOn: []
      },
      dict: {
        err: { eng: "choose at least one permission.", per: "نقش کاربر حتما باید حداقل یک دسترسی داشته باشد." },
        dashboard: { eng: "dashboard", per: "داشبورد" },
        menu: { eng: "sidebar menu", per: "منوی سایدبار" },
        hardwareConfigure: {
          eng: "hardware Confiurator",
          per: "پیکره بندی سخت افزار"
        },
        controllers: { eng: "controllers", per: "کنترل کننده ها" },
        dataManager: { eng: "data manager", per: "مدیریت اطلاعات" },
        workbenchManager: { eng: "workbench manager", per: "مدیریت میزکار" },
        adminPanel: { eng: "admin panel", per: "پنل ادمین" },
        mapManager: { eng: "map manager", per: "مدیریت نقشه " },
        processflowManager: { eng: "process flow manager", per: "مدیریت چرخه فرآیند " },
        dataArchive: { eng: "data archive manager", per: "مدیریت آرشیو داده ها " },
        eventManager: { eng: "event manager", per: "مدیریت رویدادها " },
        dir: { eng: "ltr", per: "rtl" },
        title: { eng: "process flow diagram", per: "دیاگرام چرخه فرآیند" },
        fieldTitle: { eng: "role name", per: "عنوان نقش" },
        tab1: { eng: "process flow diagram", per: "چرخه فرآیند" },
        mapName: { eng: "map name", per: "نام نقشه" },
        enterMapName: { eng: "enter map name", per: "نام نقشه را وارد کنید" },
        modalTitle: { eng: "create role", per: "ایجاد نقش" },
        modalTitleEdit: { eng: "edit role", per: "ویرایش نقش" },
        confirmTitle: { eng: "close confirmation", per: "تایید بستن" },
        confirmRemoveTitle: { eng: "remove confirmation", per: "تایید حذف" },
        confirmText: {
          eng: "Are you sure to close this window?",
          per: "آیا از بستن این پنجره اطمینان دارید؟"
        },
        confirmRemoveText: {
          eng: "Are you sure to delete this role?",
          per: "آیا از حذف این نقش اطمینان دارید؟"
        },

        cancel: { eng: "Cancel", per: "لغو" },
        ok: { eng: "Ok", per: "قبول" },
        th_floorTitle: { eng: "name", per: "نام" },
        validName: { eng: "name is required!", per: "ورود نام الزامی است!" },
        search: { eng: "Type to Search", per: "برای جستجو تایپ کنید" },
        clear: { eng: "clear", per: "حذف" }
      },
      permissions: {
        dashboard: [
          {
            per: "ویرایش ویجت ها",
            eng: "edit widgets",
            value: "edit_widgets",
            permissionTab: null,
            _id: ""
          },
          {
            per: "حذف تکی ویجت",
            eng: "remove the widget",
            value: "remove_single_widget",
            permissionTab: null,
            _id: ""
          },
          {
            per: "ویرایش تکی ویجت",
            eng: "edit the widget",
            value: "edit_single_widget",
            permissionTab: null,
            _id: ""
          },
          {
            per: "افزودن ویجت",
            eng: "add widgets",
            value: "add_widgets",
            permissionTab: null,
            _id: ""
          },
          {
            per: "افزودن صفحه",
            eng: "add page",
            value: "add_page",
            permissionTab: null,
            _id: ""
          },
          {
            per: "نمایش صفحات",
            eng: "pagination show",
            value: "pagination_show",
            permissionTab: null,
            _id: ""
          },
          {
            per: "لیست ویجت ها",
            eng: "widgets list",
            value: "widgets_list",
            permissionTab: null,
            _id: ""
          }
        ],
        menu: [
          {
            per: "پیکره بندی سخت افزار",
            eng: "hardware configurator",
            value: "menu_hardware_config",
            permissionTab: null,
            _id: ""
          },
          {
            per: "مدیریت کنترلرها",
            eng: "controller manager",
            value: "menu_controller_manager",
            permissionTab: null,
            _id: ""
          },
          {
            per: "مدیریت داده",
            eng: "data manager",
            value: "menu_data_manager",
            permissionTab: null,
            _id: ""
          },
          {
            per: "مدیریت میزکار",
            eng: "workbench manager",
            value: "menu_workbench_manager",
            permissionTab: null,
            _id: ""
          },
          {
            per: "پنل ادمین",
            eng: "admin panel",
            value: "menu_admin_panel",
            permissionTab: null,
            _id: ""
          },
          {
            per: "مدیریت نقشه",
            eng: "map manager",
            value: "menu_map_manager",
            permissionTab: null,
            _id: ""
          },
          {
            per: "مدیریت رویداد",
            eng: "event manager",
            value: "menu_event_manager",
            permissionTab: null,
            _id: ""
          },
          {
            per: "مدیریت چرخه فرآیند",
            eng: "process flow manager",
            value: "menu_processflow_manager",
            permissionTab: null,
            _id: ""
          },
          {
            per: "آرشیو داده",
            eng: "data archive",
            value: "menu_data_archive",
            permissionTab: null,
            _id: ""
          }
        ],
        hardwareConfigure: [
          {
            per: "افزودن سرور جدید",
            eng: "add new server",
            value: "hardware_add_server",
            permissionTab: null,
            _id: ""
          },
          {
            per: "ویرایش سرور",
            eng: "edit server",
            value: "hardware_edit_server",
            permissionTab: null,
            _id: ""
          },
          {
            per: "حذف سرور",
            eng: "remove server",
            value: "hardware_remove_server",
            permissionTab: null,
            _id: ""
          },
          {
            per: "نمایش لیست سرورها",
            eng: "show servers list",
            value: "hardware_show_servers_list",
            permissionTab: null,
            _id: ""
          },
          {
            per: "بارگذاری فایل سخت افزاری",
            eng: "upload configure",
            value: "hardware_upload_configure",
            permissionTab: null,
            _id: ""
          }
        ],
        controllers: [
          {
            per: "افزودن کنترلر",
            eng: "add new node",
            value: "controller_new_node",
            permissionTab: null,
            _id: ""
          },
          {
            per: "ویرایش اتصال کنترلر",
            eng: "edit connection",
            value: "controller_edit_connection",
            permissionTab: null,
            _id: ""
          },
          {
            per: "ویرایش کنترلر",
            eng: "edit node",
            value: "controller_edit_node",
            permissionTab: null,
            _id: ""
          },
          {
            per: "حذف کنترلر",
            eng: "delete node",
            value: "controller_delete_node",
            permissionTab: null,
            _id: ""
          },
          {
            per: "ایجاد لینک",
            eng: "connect node",
            value: "controller_connect_node",
            permissionTab: null,
            _id: ""
          },
          {
            per: "حذف لینک",
            eng: " remove connection",
            value: "controller_remove_connection",
            permissionTab: null,
            _id: ""
          }
        ],
        dataManager: [
          {
            per: "ایجاد داده",
            eng: "add data",
            value: "dataManager_add",
            permissionTab: null,
            _id: ""
          },
          {
            per: "ویرایش داده",
            eng: "edit data",
            value: "dataManager_edit",
            permissionTab: null,
            _id: ""
          },
          {
            per: "حذف داده",
            eng: "remove data",
            value: "dataManager_remove",
            permissionTab: null,
            _id: ""
          }
        ],
        processflowManager: [
          {
            per: "ایجاد فرآیند",
            eng: "add process",
            value: "process_add",
            permissionTab: null,
            _id: ""
          },
          {
            per: "ویرایش داده",
            eng: "edit process",
            value: "process_edit",
            permissionTab: null,
            _id: ""
          },
          {
            per: "حذف داده",
            eng: "remove process",
            value: "process_remove",
            permissionTab: null,
            _id: ""
          }
        ],
        dataArchive: [
          {
            per: "ایجاد آرشیو",
            eng: "add archive",
            value: "archive_add",
            permissionTab: null,
            _id: ""
          }
        ],
        workbenchManager: [
          {
            per: "سربرگ مشخصات میزکار",
            eng: "add data",
            value: "properties_tab",
            permissionTab: [
              {
                per: "ویرایش نام میزکار",
                eng: "edit name",
                value: "wb_edit",
                permissionTab: null,
                _id: ""
              },
              {
                per: "تغییر مالکیت میزکار",
                eng: "edit owner",
                value: "wb_edit_owner",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ مدیریت کاربران",
            eng: "user management tab",
            value: "user_management_tab",
            permissionTab: [
              {
                per: "دعوت کاربر",
                eng: " invite user",
                value: "wb_invite_user",
                permissionTab: null,
                _id: ""
              },
              {
                per: "حذف کاربر",
                eng: " remove user",
                value: "wb_remove_user",
                permissionTab: null,
                _id: ""
              },
              {
                per: "ایجاد نقش",
                eng: "role manager",
                value: "wb_role_manager",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ مدیریت نقش ها",
            eng: "roles management tab",
            value: "roles_management_tab",
            permissionTab: [
              {
                per: "ایجاد نقش",
                eng: "add role",
                value: "wb_add_role",
                permissionTab: null,
                _id: ""
              },
              {
                per: "ویرایش نقش",
                eng: "edit role",
                value: "wb_edit_role",
                permissionTab: null,
                _id: ""
              },
              {
                per: "حذف نقش",
                eng: "remove role",
                value: "wb_remove_role",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          }
        ],
        adminPanel: [
          {
            per: "سربرگ مدیریت منابع",
            eng: "license manager",
            value: "license_tab",
            permissionTab: [
              {
                per: "بارگذاری فایل لایسنس",
                eng: "upload license",
                value: "admin_upload_license",
                permissionTab: null,
                _id: ""
              },
              {
                per: "تخصیص منابع",
                eng: "allocate resources",
                value: "admin_allocate_resource",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ مدیریت میزکارها",
            eng: "workbenches manager",
            value: "workbenches_tab",
            permissionTab: [
              {
                per: "ایجاد میزکار",
                eng: "add new workbench",
                value: "admin_new_wb",
                permissionTab: null,
                _id: ""
              },
              {
                per: "مشاهده لیست کاربران میزکار",
                eng: "workbench's users list",
                value: "admin_workbench's_users_list",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ مدیریت کاربران",
            eng: "users manager",
            value: "users_tab",
            permissionTab: [
              {
                per: "ویرایش اطلاعات کاربر",
                eng: "edit user data",
                value: "admin_user_data",
                permissionTab: null,
                _id: ""
              },
              {
                per: "لیست میزکارهای کاربران",
                eng: "workbench's users list",
                value: "admin_workbench_users",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ مدیریت کانکشن ها",
            eng: "connections manager",
            value: "connections_tab",
            permissionTab: [
              {
                per: "ایجاد کانکشن",
                eng: "add connection",
                value: "admin_add_connection",
                permissionTab: null,
                _id: ""
              },
              {
                per: "حذف کانکشن",
                eng: "remove connection",
                value: "admin_remove_connection",
                permissionTab: null,
                _id: ""
              },
              {
                per: "تغییر وضعیت کانکشن",
                eng: "change status",
                value: "admin_status",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          }
        ],
        mapManager: [
          {
            per: "سربرگ مدیریت نقشه",
            eng: "map tab",
            value: "map_tab",
            permissionTab: [
              {
                per: "بارگذاری فایل نقشه",
                eng: "upload indoor map",
                value: "map_upload",
                permissionTab: null,
                _id: ""
              },
              {
                per: "ویرایش فایل نقشه",
                eng: "edit indoor map",
                value: "map_edit",
                permissionTab: null,
                _id: ""
              },
              {
                per: "حذف فایل نقشه",
                eng: "remove indoor map",
                value: "map_remove",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
        ],
        eventManager: [
          {
            per: "سربرگ رویدادهای کنونی",
            eng: "current alarms tab",
            value: "event_current",
            permissionTab: [
              {
                per: "فعال کردن رویداد",
                eng: "acknowledge event",
                value: "event_acknowledge",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ رویدادهای آرشیو شده",
            eng: "archived alarms tab",
            value: "event_archives",
            permissionTab: [
              {
                per: "مشاهده لیست آرشیوها",
                eng: "show archived list",
                value: "event_archive_list",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ انواع رویدادها",
            eng: "event type tab",
            value: "event_types",
            permissionTab: [
              {
                per: "ایجاد نوع جدید رویداد",
                eng: "add event type",
                value: "event_type_add",
                permissionTab: null,
                _id: ""
              },
              {
                per: "ویرایش نوع رویداد",
                eng: "edit event type",
                value: "event_type_edit",
                permissionTab: null,
                _id: ""
              },
              {
                per: "حذف نوع رویداد",
                eng: "remove event type",
                value: "event_type_remove",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          },
          {
            per: "سربرگ قوانین رویدادها",
            eng: "event rules tab",
            value: "event_rules",
            permissionTab: [
              {
                per: "ایجاد رویداد",
                eng: "add event rule",
                value: "event_rule_add",
                permissionTab: null,
                _id: ""
              },
              {
                per: "ویرایش رویداد",
                eng: "edit event rule",
                value: "event_rule_edit",
                permissionTab: null,
                _id: ""
              },
              {
                per: "حذف رویداد",
                eng: "remove event rule",
                value: "event_rule_remove",
                permissionTab: null,
                _id: ""
              }
            ],
            _id: ""
          }
        ]
      },
      list: [],
      editMode: false,
      name: '',
      reRender: 0,
      id: "",
      selectedPermissions: {},
    };
  },
  computed: {
    lang() {
      return this.$store.state.chpge.lang;
    },
    modalTitle() {
      if (this.editMode) return this.dict.modalTitleEdit[this.lang];
      else return this.dict.modalTitle[this.lang];
    }
  },
  methods: {
    getUserDatas() {
      this.$store.dispatch("data/getUserData");
    },
    selectByGroup(val, catName) {
      if (val.indexOf(catName) > -1) {
        this.selectedPermissions = [];
        val.forEach((item) => {
          this.list.forEach((x) => {
            if (x.category == item) this.selectedPermissions.push(x._id);
          });
        });
      } else if (val.indexOf(catName) == -1) {
        this.list.forEach((x) => {
          if (x.category == catName)
            this.selectedPermissions.splice(
              this.selectedPermissions.indexOf(x._id),
              1
            );
        });
      }
    },
    getList() {
      this.$store.dispatch("data/getPermission").then((res) => {
        this.categoryList = [];
        this.$store.state.data.permissionList.message.forEach((x) => {
          if (x.category[0] != undefined && x.category[0] != "")

            this.permissions[x.category[0]].forEach((per) => {

              if (per.permissionTab == null && per.value == x.name) {
                per._id = x._id;
              }
              if (per.permissionTab != null)
                per.permissionTab.forEach((sub) => {
                  if (sub.value == x.name)
                    sub._id = x._id;
                });

            });
        });
        this.list = res.message;
      });
    },
    getRoleInfo(id) {
      this.editMode = true;
      this.id = id;
      this.$store.dispatch("data/getRoleData", { _id: id }).then((res) => {
        this.name = res.message.name;
        this.selectedPermissions = res.message.permissions;

        this.selectedPermissions.forEach((item) => {
          this.list
            .find((x) => x._id == item)
            .category.forEach((y) => {
            if (this.selectedCats.indexOf(y) == -1) this.selectedCats.push(y);
          });
        });
        // this.selectedCats = new Set(this.selectedCats);

        this.$bvModal.show("createRole");
      });
    },
    storeRole() {
      let permission = [];
      Object.entries(this.selectedPermissions).forEach((item) => {
        permission.push(item[1]);
      });
      const isValid = new FormValidator("#roleName").validate();
      if (isValid && permission.length>0) {
        if (!this.editMode) {
          const data = {
            name: this.name,
            permissions: permission,
            workbench: this.idWb
          };
          this.$store
            .dispatch("data/createRoleForAdmin", data)
            .then((res) => {
              this.loading = false;
              this.selectedPermissions = {};
              this.categoryList = [];
              this.selectedCats = [];
              this.name = "";
              this.getRoles();
              this.getList();
              this.$bvModal.hide("createRole");
              this.$bvModal.hide("openCreate");
            })
            .catch((err) => {
              this.loading = false;
            });
        } else this.updateRole();
      } else {
        this.loading = false;
        ToastUtility.show({
          content: this.dict.err[this.lang],
          timeOut: 3000,
          cssClass: "e-toast-danger", // Add a custom class for Error styling
          position: { X: "Center", Y: "Center" }, // Adjust the position
          showCloseButton: true,
          enableRtl: store.state.chpge.lang == "per" ? true : false
        });
      }
    },
    openModal() {
      this.name = "";
      this.editMode = false;
      this.selectedPermissions = [];
      this.$bvModal.show("createRole");
    },
    closeModal() {
      this.name = "";
      this.selectedPermissions = [];
      this.selectedCats = [];
      this.$bvModal.hide("createRole");
    },
    getRoles() {
      this.$store.dispatch("data/getRolesList");
    },
    getVal(val, name, id) {
      if (val.checked) {
        this.selectedPermissions[name] = id;
      } else {
        delete this.selectedPermissions[name];
      }
    },
  },
  mounted() {
    this.getList();
    // this.getRoles();
  }
};
</script>

<template>
  <div>
    <div >
      <div class="py-3" id="roleName">
        <ejs-textbox
          :autocomplete="'off'"
          :placeholder="dict.fieldTitle[lang]"
          v-model="name"
          :enableRtl="lang == 'per'"
          :required="true"
          floatLabelType="Auto"
        ></ejs-textbox>
        <div dir="ltr" class="accordion col-12 my-3" role="tablist">
          <b-card no-body class="mb-1">
            <ejs-accordion :key="reRender" expandMode="Single" :enableRtl="lang == 'per'">
              <div
                v-for="(item, tabName) in permissions"
                :key="'acc' + tabName"
              >
                <div>
                  <div> {{ dict[tabName][lang] }}</div>
                </div>
                <div class="row">
                  <div
                    v-for="(btn, i) in item"
                    :key="i"
                    class="col-md-3 col-12 py-2"
                  >

                    <p v-if="btn.permissionTab!=null" class="text-bold">{{ btn[lang] }}</p>
                    <div v-if="btn.permissionTab==null">
                      <ejs-switch
                        @change="getVal($event,btn.value,btn._id)"
                        :enableRtl="lang == 'per'"
                        :checked="selectedPermissions[btn.value] ? true:false "
                        :value="btn._id"
                      />
                      <span class="mx-2">{{ btn[lang] }}</span>
                    </div>

                    <div
                      class="py-2"
                      v-for="(btn2, i) in btn.permissionTab"
                      :key="i">
                      {{ selectedPermissions[btn2.value] }}
                      <ejs-switch
                        @change="getVal($event,btn2.value, btn2._id)"
                        :enableRtl="lang == 'per'"
                        :value="btn2._id"
                        :checked="selectedPermissions[btn2.value] ? true:false"
                      />
                      <span class="mx-2">{{ btn2[lang] }}</span>
                    </div>
                  </div>
                </div>
              </div>

            </ejs-accordion>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
