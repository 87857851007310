<template>
  <div ref="appContainer" class="app-container">
    <div v-show="isDragging" class="wrapper" ref="wrapper">
      <div id="wrapper-diagram" ref="wrapperDiagram" class="wrapper-diagram"></div>
    </div>

    <div class="main-container">
      <div class="palette">
        <input
          type="text"
          v-model="paletteSearch"
          placeholder="Search..."
          class="palette-search"
        />
        <div
          v-for="(category, index) in filteredCategories"
          :key="index"
          class="palette-category"
        >
          <div class="category-header" @click="toggleCategory(index)">
            {{ category.name }}
            <span>{{ category.collapsed ? '+' : '-' }}</span>
          </div>
          <div v-show="!category.collapsed" class="category-items">
            <div
              :id="'palette-container-' + index"
              :ref="'paletteContainer' + index"
              class="palette-env"
            ></div>
          </div>
        </div>
      </div>

      <div class="diagram-controls">
        {{`${Math.round(scale*100)}%`}}
        <button @click="zoomIn">+</button>
        <button @click="zoomOut">-</button>
      </div>

      <div class="diagram-env" >
        <div
          v-show="selectionBox.visible"
          :style="selectionBox.style"
          class="selection-rectangle"
        >
        </div>

        <div 
          id="diagram-container" 
          ref="diagramContainer" 
          @mousemove="updateSelection"
        ></div>
      </div>
      
    </div>
    <DataPickerModal/>
    <DataCategoryModalPicker/>
  </div>
</template>

<script>
import * as joint from 'jointjs';
import DataPickerModal from "@/components/dataManagerModalDataPicker.vue";
import DataCategoryModalPicker from "@/components/dataCategoryPicker.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: 'DiagramEditor',
  components: { 
    DataPickerModal,
    DataCategoryModalPicker
  },
  data() {
    return {
      alo:'ahmad',
      mainGraph: null,
      mainPaper: null,
      paletteGraph: null,
      palettePaper: null,
      wrapperGraph: null,
      wrapperPaper: null,
      isDragging: false,
      draggedElement: null,
      scale: 1,

      mainGraphCells:[],
      categories:[],
      blocks: [],
      MyElemnts:{},

      selectedDataTag:{},
      paletteCategories: [],
      paletteSearch: '', // Search query for palette
      
      selectionBox: {
        visible: false,
        style: {},
        startX: 0,
        startY: 0,
      },
      selectedElements:[],
      elementPreviousPositions:{},
      addCellReq:{
        queue:[],
        timer:null,
        interval:100
      },
      removeCellReq:{
        queue:[],
        timer:null,
        interval:100
      },
      editCellReq:{
        queue:[],
        timer:null,
        interval:100
      }
    };
  },
  mounted() {
    this.initialize()

    document.addEventListener('keydown', this.handleShortcuts);
    this.createNodeClass();
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleShortcuts);
  },
  methods: {
    async initialize(){
      await this.getRuleEditorCategories();
      await this.getRuleEditorBlocks();
      await this.getRuleEditorCells();

      this.paletteCategories = this.createPaletteCategories(this.categories, this.blocks);

      this.initializeMainDiagram();
      this.initializePalette();
      this.initializeWrapper();
      this.setupDragAndDrop();

    },
    // Initialize Main Diagram
    initializeMainDiagram() {
      this.mainGraph = new joint.dia.Graph();
      this.mainPaper = new joint.dia.Paper({
        el: this.$refs.diagramContainer,
        model: this.mainGraph,
        width: '100%',
        height: '100%',
        gridSize: 5,
        drawGrid: true, // Enable grid on main diagram
        background: {
          color: '#fff', // White background for the main diagram
        },
        linkPinning: false, // Prevent link being dropped in blank paper area
        defaultLink: () => new joint.shapes.standard.Link({
          router: {
            name: 'manhattan',
            args: {
              avoidObstacles: true, // Avoid passing through elements
              excludeEnds: ['source'], // Exclude routing near the source
              excludeTypes: ['myNamespace.MyCommentElement'], // Exclude specific types
              startDirections: ['right'], // Force links to start from the top of the source
              endDirections: ['left'], // Force links to end at the bottom of the target
              step : 20,
              padding : 20
            },
          },







          connector: { name: 'rounded', args:{radius: 40 }}, // rounded  jumpover curve
          attrs: {
              line: {
                  stroke: 'gray',
                  strokeWidth: 4,
                  strokeDasharray: '4 2', // Dashed line
              },
          },
        }),
        defaultConnectionPoint: { name: 'boundary' },
        validateConnection: function(cellViewS, magnetS, cellViewT, magnetT, end, linkView) {
          // Prevent linking from input ports
          if (magnetS && magnetS.getAttribute('port-group') === 'in') return false;
          // Prevent linking from output ports to input ports within one element
          if (cellViewS === cellViewT) return false;
          // Prevent linking to output ports
          return magnetT && magnetT.getAttribute('port-group') === 'in';
        },
        validateMagnet: function(cellView, magnet) {
          // Note that this is the default behaviour. It is shown for reference purposes.
          // Disable linking interaction for magnets marked as passive
          return magnet.getAttribute('magnet') !== 'passive';
        },
        snapLinks: { radius: 20 },
        markAvailable: true,
        
        elementView: joint.dia.ElementView.extend({

          events: {
            'submit form': 'onSubmit',
            // 'change input': 'onChange',
            'change input,select': 'onSelect'
          },



          

        onSelect: function(evt) {
          // if (this.model.get('type') === 'megashid.selectNode'){
            const input = evt.target;
            if (!input.validity.valid) return;
            const valuePath = input.getAttribute('joint-selector') + '/props/value';
            this.model.attr(valuePath, input.value);
            this.model.attr({ submitted: true });
          // }
            console.log('this.model',this.model.get('type'),this.model)
        },
          
          onSubmit: function (evt) {
            evt.preventDefault();

            this.model.attr({ submitted: true });
            console.log('this.model',this.model.get('type'),this.model)
            // console.log(`Form submitted for block with ID: ${nodeId} , ${nodeClass}`,this.model);
          },

          // onChange: function (evt) {
          //   console.log('=============> onChange',evt.target.value)
            
          //   this.model.attr('name/props/value', evt.target.value);

          //   this.model.set('data', {name:evt.target.value, value:evt.target.value, dataType:"Number"});
          //   this.model.attr({ submitted: true });

          //   // this.model.attr('name/props/value', evt.target.value);

          // },

        })
        
      });

      this.mainGraphCells.forEach((item, i) => {
        if (item.class !== 'link'){
          const match = this.blocks.filter((block)=> {return block.class === item.class})
          var block = this.createCustomBlock(match[0],item.position)
          // console.log('before',JSON.stringify(block))
          block.set('id', item.id); // Replace with the new ID
          block.set('_id', item._id);
          // block.set('data', item.data);


          if (block.get('type') === 'megashid.ButtonNode'){
            block.attr('data/props/value', item.data.name? item.data.name:'');
          }else{
            block.attr('data/props/value', item.data.value? item.data.value:'');
          }
          block.attr('data/props/name', item.data.name? item.data.name:'');
          block.attr('data/props/id', item.data.id? item.data.id:'');


          this.mainGraph.addCell(block);
        }else{
          const link = this.mainPaper.getDefaultLink();
          link.set({
            _id: item._id,
            id: item.id,
            source: item.source,
            target: item.target
          });
          this.mainGraph.addCell(link);
        }

        

        // Instantiate a Node object
        

        
      });
      this.setupEvents();
    },
    initializePalette() {
      this.paletteCategories.forEach((category, index) => {

        const graph = new joint.dia.Graph();
        const paper = new joint.dia.Paper({
          el: this.$refs[`paletteContainer${index}`], // Dynamic reference for each category
          model: graph,
          width: 200,
          height: category.items.length * 70,
          gridSize: 5,
          interactive: false, // Palette is not interactive
          background: { color: '#f0f0f0' },
        });
        
        var posy = 20;
        category.items.forEach((item, i) => {
          var block = this.createCustomBlock(item,{x:25,y:posy})
          
          //console.log('block size',block.attributes.size.height)

          posy = posy + block.attributes.size.height + 20
          graph.addCell(block);
        });
        paper.setDimensions(200, posy);
        this.$set(category, 'graph', graph);
        this.$set(category, 'paper', paper);
      });
    },
    createCustomBlock(item,pos) {
      if (item.class === 'input_tag' || item.class === 'output_tag' || item.class === 'list_tag'){
        const node = new this.MyElemnts.buttonElement({
          position: { x: pos.x, y: pos.y },
          size: { width: 150, height: 10 },
          attrs: {
            nodeBody: { fill: item.color },
            nodeHeaderLabel: { text: item.label }
          },
          class: item.class
        });
        node.addDynamicPorts(item.ports, maxPorts => {
          const height = maxPorts * 25 + 30;
          node.resize(150, height);
        });
        return node
      }else if (item.class === 'boolean_tag' ){
        const node = new this.MyElemnts.selectElement({
          position: { x: pos.x, y: pos.y },
          size: { width: 150, height: 10 },
          attrs: {
            nodeBody: { fill: item.color },
            nodeHeaderLabel: { text: item.label }
          },
          class: item.class
        });
        node.addDynamicPorts(item.ports, maxPorts => {
          const height = maxPorts * 25 + 30;
          node.resize(150, height);
        });
        return node
      }else if (item.class === 'string_tag' ){
        const node = new this.MyElemnts.textElement({
          position: { x: pos.x, y: pos.y },
          size: { width: 150, height: 10 },
          attrs: {
            nodeBody: { fill: item.color },
            nodeHeaderLabel: { text: item.label }
          },
          class: item.class
        });
        node.addDynamicPorts(item.ports, maxPorts => {
          const height = maxPorts * 25 + 30;
          node.resize(150, height);
        });
        return node
      }else if (item.class === 'number_tag' ){
        const node = new this.MyElemnts.numberElement({
          position: { x: pos.x, y: pos.y },
          size: { width: 150, height: 10 },
          attrs: {
            nodeBody: { fill: item.color },
            nodeHeaderLabel: { text: item.label }
          },
          class: item.class
        });
        node.addDynamicPorts(item.ports, maxPorts => {
          const height = maxPorts * 25 + 30;
          node.resize(150, height);
        });
        return node
      }else{
        const node = new this.MyElemnts.generalElement({
          position: { x: pos.x, y: pos.y },
          size: { width: 150, height: 10 },
          attrs: {
            nodeBody: { fill: item.color },
            nodeHeaderLabel: { text: item.label }
          },
          class: item.class
        });
        node.addDynamicPorts(item.ports, maxPorts => {
          const height = maxPorts * 25 + 30;
          node.resize(150, height);
        });

        return node
      }
    },
    // createCustomBlock1(item,pos) {

    //   var portsIn = {
    //     position: {
    //       name: 'left'
    //     },
    //     attrs: {
    //       portBody: {
    //         magnet: true,
    //         width: 16,
    //         height: 16,
    //         rx: 5,
    //         ry: 5,
    //         x: -8,
    //         y: -8,
    //         fill: '#f0f0f0',
    //         stroke:'#023047',
    //         strokeWidth : 1
    //       },
    //       label: {
    //         fontSize: 12, // Add fontSize to customize label font size
    //         fill: '#fff', // Optional: set label color
    //       }
    //     },
    //     label: {
    //       position: {
    //         name: 'right',
    //         // args: { x: 20, y: 0 },
    //       },
    //       markup: [{
    //         tagName: 'text',
    //         selector: 'label',
    //         className: 'label-text'
    //       }]
    //     },
    //     markup: [{
    //       tagName: 'rect',
    //       selector: 'portBody'
    //     }]
    //   };

    //   var portsOut = {
    //     position: {
    //       name: 'right'
    //     },
    //     attrs: {
    //       portBody: {
    //         magnet: true,
    //         width: 16,
    //         height: 16,
    //         rx: 5,
    //         ry: 5,
    //         x: -8,
    //         y: -8,
    //         fill: '#f0f0f0',
    //         stroke:'#023047',
    //         strokeWidth : 1
    //       },
    //       label: {
    //         fontSize: 12, // Add fontSize to customize label font size
    //         fill: '#fff', // Optional: set label color
    //       }
    //     },
    //     label: {
    //       position: {
    //         name: 'left',
    //         // args: { x: 20, y: 0 },
    //       },
    //       markup: [{
    //         tagName: 'text',
    //         selector: 'label',
    //         className: 'label-text'
    //       }]
    //     },
    //     markup: [{
    //       tagName: 'rect',
    //       selector: 'portBody'
    //     }]
    //   };
      
    //   const block = new joint.shapes.standard.Rectangle({
    //     position: { x: pos.x, y: pos.y },
    //     size: { width: 150, height: 10 },
    //     attrs: {
    //       body: { fill: item.color, stroke: '#0f0f0f', 'stroke-width': 1, rx: 5, ry: 5 },  
    //       // label: { text: item.label, fill: '#fff', fontSize: 14 },
    //       label: {
    //           text: item.label,
    //           fill: '#000',
    //           fontSize: 14,
    //           ref: 'body', // Reference to the block's body
    //           refX: 0.5, // Horizontal alignment (centered to the block)
    //           refY: -10, // Vertical offset (above the block)
    //           textAnchor: 'middle', // Horizontal text alignment
    //           yAlignment: 'middle', // Vertical text alignment
    //       },
    //     },
    //     ports: {
    //       groups: {
    //         'in': portsIn,
    //         'out': portsOut
    //       }
    //     }
    //   });

    //   block.prop('class', item.class);
      

    //   var ports = []
    //   var iprt = 0;
    //   var oprt = 0;
    //   for (var port of item.ports){
    //     port.group === 'in'? iprt+=1 : oprt+=1;
    //     ports.push(
    //       {
    //         id: port.id, //uuidv4(),
    //         group: port.group,
    //         attrs: { label: { text: port.label } },
    //       },
    //     )
    //   }
    //   //console.log('numper of ports:',Math.max(iprt,oprt))
    //   block.resize(150, Math.max(iprt,oprt)*25+10);
    //   block.addPorts(ports)
    //   return block;
    // },

    createNodeClass() {
      const nodeMarkup = joint.util.svg/* xml */`
        <rect @selector="nodeBody"/>
        <clipPath @selector="clipPath"><rect @selector="clipPathRect"/></clipPath>
        <rect @selector="nodeHeader"/>
        <text @selector="nodeHeaderLabel"/>
        <text @selector="label"/>
      `;

      var portsIn = {
        position: {
          name: 'line',
          args: {
            start: { x: 0, y: '10%' },
            end: { x: 0, y: '90%' }
          }
        },
        attrs: {
          portBody: {
            magnet: true,
            width: 16,
            height: 16,
            rx: 5,
            ry: 5,
            x: -8,
            fill: '#f0f0f0',
            stroke:'#023047',
            strokeWidth : 1
          },
          label: {
            fontSize: 12, // Add fontSize to customize label font size
            fill: '#fff', // Optional: set label color
            y: 11,
          }
        },
        label: {
          position: {
            name: 'right',
          },
          markup: [{
            tagName: 'text',
            selector: 'label',
            className: 'label-text'
          }]
        },
        markup: [{
          tagName: 'rect',
          selector: 'portBody'
        }]
      };

      var portsOut = {
        position: {
          name: 'line',
          args: {
            start: { x: '100%', y: '10%' },
            end: { x: '100%', y: '90%' }
          }
        },
        attrs: {
          portBody: {
            magnet: true,
            width: 16,
            height: 16,
            rx: 5,
            ry: 5,
            x: -8,
            fill: '#f0f0f0',
            stroke:'#023047',
            strokeWidth : 1
          },
          label: {
            fontSize: 12, // Add fontSize to customize label font size
            fill: '#fff', // Optional: set label color
            y: 11,
          }
        },
        label: {
          position: {
            name: 'left',
            // args: { x: 20, y: 0 },
          },
          markup: [{
            tagName: 'text',
            selector: 'label',
            className: 'label-text'
          }]
        },
        markup: [{
          tagName: 'rect',
          selector: 'portBody'
        }]
      };

      class Node extends joint.dia.Element {
        preinitialize() {
          this.markup = nodeMarkup;
        }

        constructor(attributes = {}, options = {}) {
          super({
            ...attributes,
            class: attributes.class || 'Node', // Assign dynamic className or default to 'Node'
          }, options);
        }

        defaults() {
          const clipId = joint.util.uuid();

          return {
            ...super.defaults,
            type: 'megashid.Node',
            size: {
              width: this.nodeWidth,
              height: this.nodeHeight
            },
            attrs: {
              root: {
                cursor: 'move',
                
              },
              nodeBody: {
                width: 'calc(w)',
                height: 'calc(h)',
                fill: this.nodeColor,
                rx: 6,
                stroke: '',
                'stroke-width': 0,
              },
              nodeHeaderLabel: {
                x: 15,
                y: 20 / 2 + 1,
                textAnchor: 'start',
                textVerticalAnchor: 'middle',
                fill: '#ffffff',
                text: this.nodeLabel,
                fontSize: 10
              },
              nodeHeader: {
                x: 0,
                y: 0,
                width: 'calc(w)',
                height: 20,
                clipPath: `url(#${clipId})`,
                fill: '#023047'
              },
              clipPath: {
                id: clipId
              },
              clipPathRect: {
                width: 'calc(w)',
                height: 'calc(h)',
                rx: 6,
              },
              label: {
                x: 'calc(w/2)',
                y: 'calc(h/2+10)',
                textAnchor: 'middle',
                textVerticalAnchor: 'middle',
                fill: 'white'
              }
            },
            ports: {
              groups: {
                'in': portsIn,
                'out': portsOut
              }
            }
          };
        }
        
        addDynamicPorts(portConfig, sizeCallback) {
          const ports = [];
          let inPorts = 0;
          let outPorts = 0;

          for (const port of portConfig) {
            if (port.group === 'in') inPorts++;
            else if (port.group === 'out') outPorts++;

            ports.push({
              id: port.id,
              group: port.group,
              attrs: { label: { text: port.label } },
            });
          }

          this.addPorts(ports);

          if (sizeCallback) {
            const maxPorts = Math.max(inPorts, outPorts);
            sizeCallback(maxPorts);
          }
        }
      }
      const buttonNodeMarkup1 = joint.util.svg/* xml */
        `<foreignObject @selector="foreignObject">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            class="jj-form"
          >
            
              <form class="jj-field-vertical">
                <input @selector="data" class="jj-input" type="text" name="name" autocomplete="off" placeholder="select data" disabled="" />
                <button type="submit" class="jj-button"><span>edit</span></button>
              </form>
           
          </div>
        </foreignObject>`

      

      class ButtonNode extends Node {
        preinitialize() {
          super.preinitialize();
          this.markup = this.markup.concat(buttonNodeMarkup1);
        }

        defaults() {
          const clipId = joint.util.uuid();

          return joint.util.defaultsDeep({
            type: 'megashid.ButtonNode',
            attrs: {
              nodeHeaderLabel: {
                text: 'Button node'
              },
              label: {
                y: 'calc(h/2+25)',
                visibility: 'hidden'
              },
              foreignObject: {
                x: 0,
                y: 0,
                width: 'calc(w)',
                height: 'calc(h)',
              },
              data: {
                props: {
                  type: 'data',
                  value: '', 
                  dataType: '',
                  name: '',
                  id: '',
                }
              }
            }
          }, super.defaults());
        }
      }

      const numberNodeMarkup = joint.util.svg/* xml */
        `<foreignObject @selector="foreignObject">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            class="jj-form"
          >
            <input @selector="data" class="jj-input" type="number" step="any" name="name" autocomplete="off" placeholder="enter value" />
          </div>
        </foreignObject>`


      class numberNode extends Node {
        preinitialize() {
          super.preinitialize();
          this.markup = this.markup.concat(numberNodeMarkup);
        }

        defaults() {
          const clipId = joint.util.uuid();

          return joint.util.defaultsDeep({
            type: 'megashid.numberNode',
            attrs: {
              nodeHeaderLabel: {
                text: 'input node'
              },
              label: {
                y: 'calc(h/2+25)',
                visibility: 'hidden'
              },
              foreignObject: {
                x: 0,
                y: 0,
                width: 'calc(w)',
                height: 'calc(h)',
              },
              data: {
                props: {
                  type: 'constant',
                  value: 0, 
                  dataType: 'Number',
                  name: '',
                  id: '',
                }
              }
            }
          }, super.defaults());
        }
      }

      const textNodeMarkup = joint.util.svg/* xml */
        `<foreignObject @selector="foreignObject">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            class="jj-form"
          >
            <input @selector="data" class="jj-input" type="text" name="name" autocomplete="off" placeholder="enter value" />
          </div>
        </foreignObject>`


      class textNode extends Node {
        preinitialize() {
          super.preinitialize();
          this.markup = this.markup.concat(textNodeMarkup);
        }

        defaults() {
          const clipId = joint.util.uuid();

          return joint.util.defaultsDeep({
            type: 'megashid.textNode',
            attrs: {
              nodeHeaderLabel: {
                text: 'text node'
              },
              label: {
                y: 'calc(h/2+25)',
                visibility: 'hidden'
              },
              foreignObject: {
                x: 0,
                y: 0,
                width: 'calc(w)',
                height: 'calc(h)',
              },
              data: {
                props: {
                  type: 'constant',
                  value: "", 
                  dataType: 'String',
                  name: '',
                  id: '',
                }
              }
            }
          }, super.defaults());
        }
      }

      const selectNodeMarkup = joint.util.svg/* xml */
        `<foreignObject @selector="foreignObject">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            class="jj-form"
          >
            <select @selector="data" class="jj-input" type="text">
              <option value="true">true</option>
              <option value="false">false</option>
            </select>

          </div>
        </foreignObject>`

      class selectNode extends Node {
        preinitialize() {
          super.preinitialize();
          this.markup = this.markup.concat(selectNodeMarkup);
        }

        defaults() {
          const clipId = joint.util.uuid();

          return joint.util.defaultsDeep({
            type: 'megashid.selectNode',
            attrs: {
              nodeHeaderLabel: {
                text: 'select node'
              },
              label: {
                y: 'calc(h/2+25)',
                visibility: 'hidden'
              },
              foreignObject: {
                x: 0,
                y: 0,
                width: 'calc(w)',
                height: 'calc(h)',
              },
              data: {
                props: {
                  type: 'constant',
                  value: "true", 
                  dataType: 'Boolean',
                  name: '',
                  id: '',
                }
              }
            }
          }, super.defaults());
        }
      }
      this.MyElemnts = {generalElement: Node, buttonElement:ButtonNode, numberElement:numberNode, textElement:textNode, selectElement:selectNode};
    },
    toggleCategory(index) {
      this.paletteCategories.forEach((category, i) => {
        category.collapsed = i !== index ? category.collapsed : !category.collapsed;
        // category.collapsed = i !== index ? true : !category.collapsed;
      });
      this.initializePalette()
      this.setupDragAndDrop()
    },
    refreshWrapperPaper() {
      if (this.wrapperPaper && this.$refs.wrapperDiagram) {
        this.wrapperPaper.setDimensions(
          this.$refs.wrapperDiagram.clientWidth,
          this.$refs.wrapperDiagram.clientHeight
        );
        this.wrapperPaper.render();
      }
    },
    initializeWrapper() {
      this.wrapperGraph = new joint.dia.Graph();
      this.wrapperPaper = new joint.dia.Paper({
        el: this.$refs.wrapperDiagram,
        model: this.wrapperGraph,
        width: '100%',
        height: '100%',
        gridSize: 5,
        drawGrid: false, // No grid for wrapper, to keep it simpler
        interactive: false, // Wrapper is not interactive
        background: {
          color: 'rgba(0, 255, 255, 0.2)', // Semi-transparent blue background for wrapper
        },
      });
      this.wrapperPaper.scale(this.scale);
    },
    setupDragAndDrop() {
      this.paletteCategories.forEach((category, index) => {
        //console.log(index)
        category.paper.on('element:pointerdown', (elementView, evt) => {
          const originalElement = elementView.model;

          const originalBBox = originalElement.getBBox();
          const paletteRect = this.$refs[`paletteContainer${index}`][0].getBoundingClientRect();
          const initialX = originalBBox.x;
          const initialY = originalBBox.y;
          const offsetX = evt.clientX - originalBBox.x - paletteRect.left;
          const offsety = evt.clientY - originalBBox.y - paletteRect.top;
          const appContainer = this.$refs.appContainer.getBoundingClientRect();
          const x = originalBBox.x + paletteRect.left - appContainer.left;   //wrapper location regarding to screen due to header and sidebar
          const y = originalBBox.y + paletteRect.top - appContainer.top;
          
          // Clone the element for dragging

          const clonedElement = originalElement.clone();

          clonedElement.id = joint.util.uuid();

          // this.draggedElement = originalElement.clone();
          this.draggedElement = clonedElement;
          
          this.draggedElement.attr('body/opacity', 0.5);
          this.draggedElement.position(x, y); // Place at the same position in the wrapper
          this.draggedElement.offset = {x:offsetX, y:offsety}; // Place at the same position in the wrapper

          
          this.initializeWrapper()
          this.wrapperGraph.addCell(this.draggedElement);

          setTimeout(() => {
            this.isDragging = true; // Show wrapper
          }, 100);

        })

      })

      // Track mouse movement during dragging
      window.addEventListener('mousemove', (evt) => {
        if (this.isDragging && this.draggedElement) {
          const wrapperRect = this.$refs.wrapperDiagram.getBoundingClientRect();
          const offset = this.draggedElement.offset;
          const x = evt.clientX  - wrapperRect.left - offset.x;
          const y = evt.clientY - wrapperRect.top - offset.y;
          this.draggedElement.position(x, y);
        }
      });

      // Handle dropping of the element
      window.addEventListener('mouseup', (evt) => {
        if (this.isDragging) {
          const diagramRect = this.$refs.diagramContainer.getBoundingClientRect();
          const offset = this.draggedElement.offset;
          const x = evt.clientX - diagramRect.left - offset.x;
          const y = evt.clientY - diagramRect.top - offset.y;
          // console.log('evt: ',evt.clientX,evt.clientY)
          // console.log('diagramRect: ',diagramRect)
          if (
            evt.clientX >= diagramRect.left &&
            evt.clientX <= diagramRect.right &&
            evt.clientY >= diagramRect.top &&
            evt.clientY <= diagramRect.bottom
          ) {
            this.draggedElement.attr('body/opacity', 1); // Restore opacity
            this.draggedElement.position(x, y);
            // this.mainGraph.addCell(this.draggedElement);
            // console.log('this.mainGraph',JSON.stringify(this.mainGraph))

            //=======================================
            const newCell = this.recreateCell(this.draggedElement);

            this.mainGraph.addCell(newCell);
            // console.log('this.mainGraph',JSON.stringify(this.mainGraph))
            //=============================================



          }
          this.draggedElement = null;
          this.wrapperGraph.clear(); // Clear the wrapper
          this.isDragging = false; // Hide wrapper
        }
      });
    },
    zoomIn() {
      this.scale += 0.1;
      this.mainPaper.scale(this.scale);
    },
    zoomOut() {
      this.scale = Math.max(this.scale - 0.1, 0.2); // Prevent too much zoom out
      this.mainPaper.scale(this.scale);
    },
    startSelection(event, x, y) {
      // Initialize the selection box only when clicking on a blank space
      if (!this.selectionBox.visible){
        this.selectionBox.visible = true;
        this.selectionBox.startX = x;
        this.selectionBox.startY = y;
        this.selectionBox.style = {
          left: `${x}px`,
          top: `${y}px`,
          width: '0px',
          height: '0px',
        };
      }
    },
    updateSelection(event) {
      if (this.selectionBox.visible) {
        const x = Math.min(event.offsetX, this.selectionBox.startX);
        const y = Math.min(event.offsetY, this.selectionBox.startY);
        const width = Math.abs(event.offsetX - this.selectionBox.startX);
        const height = Math.abs(event.offsetY - this.selectionBox.startY);
        this.selectionBox.style = {
          left: `${x}px`,
          top: `${y}px`,
          width: `${width}px`,
          height: `${height}px`,
        };
      }
    },
    endSelection() {
      // console.log('endSelection');
      if (this.selectionBox.visible) {
          const rect = this.selectionBox.style;
          const selectionBox = {
              left: parseFloat(rect.left),
              top: parseFloat(rect.top),
              right: parseFloat(rect.left) + parseFloat(rect.width),
              bottom: parseFloat(rect.top) + parseFloat(rect.height)
          };

          // Select elements (blocks)
          const selectedElements = this.mainGraph.getElements().filter((element) => {
              const bbox = element.getBBox();
              return (
                  bbox.x + bbox.width > selectionBox.left &&
                  bbox.x < selectionBox.right &&
                  bbox.y + bbox.height > selectionBox.top &&
                  bbox.y < selectionBox.bottom
              );
          });

          // Select links
          const selectedLinks = this.mainGraph.getLinks().filter((link) => {
            
              const linkView = this.mainPaper.findViewByModel(link);
              
              if (linkView) {
                  const bbox = linkView.getBBox();
                  // console.log('link',link,bbox,selectionBox)
                  return (
                      bbox.x + bbox.width > selectionBox.left &&
                      bbox.x < selectionBox.right &&
                      bbox.y + bbox.height > selectionBox.top &&
                      bbox.y < selectionBox.bottom
                  );
              }
              return false;
          });

          // Combine selected elements and links
          this.selectedElements = [...selectedElements, ...selectedLinks];

          // console.log('selectedElements afg',this.selectedElements)
          
          // Update selection styles
          this.updateElementSelectionStyles();

          // Hide the selection box
          this.selectionBox.visible = false;
      }
    },
    updateElementSelectionStyles() {
      // console.log('updateElementSelectionStyles')
      this.mainGraph.getElements().forEach((element) => {
        element.attr('nodeBody/stroke', this.selectedElements.includes(element) ? '#FF0000' : '');
        element.attr('nodeBody/stroke-width', this.selectedElements.includes(element) ? 2 : 0);
      });
      this.mainGraph.getLinks().forEach((element) => {
        element.attr('line/stroke', this.selectedElements.includes(element) ? '#FF0000' : 'gray');
      });

    },
    setupEvents() {
      let dragStartPosition = null;
      let dragged = 0
      this.mainPaper.on('blank:pointerdown', (evt, x, y) => {
        // console.log('start from blank')
        this.startSelection(evt, x, y);
      });
      this.mainPaper.on('blank:pointerup', (evt, x, y) => {
        // console.log('stop to blank')
        this.endSelection();
      });
      this.mainPaper.on('element:pointerdown', (elementView, evt, x, y) => {
        if (this.selectedElements.includes(elementView.model)) {
          dragStartPosition = this.selectedElements.map((el) => ({
            element: el,
            startX: el.position().x,
            startY: el.position().y,
          }));
        }
      });
      this.mainPaper.on('element:pointermove', (elementView, evt, x, y) => {
        if (this.selectedElements.includes(elementView.model)) {
          // console.log('drag')
          dragged = dragged + 1;
          if (dragStartPosition) {
            
            // Calculate the movement offset for the dragged element
            const draggedElement = dragStartPosition.find(
              (pos) => pos.element === elementView.model
            );
            const offsetX = x - draggedElement.startX;
            const offsetY = y - draggedElement.startY;

            // Apply the offset to all selected elements
            dragStartPosition.forEach(({ element, startX, startY }) => {
              element.position(startX + offsetX, startY + offsetY);
            });
          }
        }
      });
      this.mainPaper.on('element:pointerup', (elementView, evt, x, y) => {
        if (this.selectedElements.includes(elementView.model)) {
          console.log('element:pointerup',dragged)
          if (dragged>0){
            // Clear drag start positions when the move is complete
            if (this.selectedElements.length > 0){
              // console.log('drag complete',this.selectedElements)
              this.handleUpdate(this.selectedElements);
            }else{
              // console.log('drag complete, single element',elementView.model)
              this.handleUpdate([elementView.model]);
            }
          }
          dragStartPosition = null;
          dragged = 0
        }
        
      });
      // this.mainPaper.on('element:pointerclick', (elementView) => {
      //   // Toggle selection on click
      //   const element = elementView.model;
      //   if (this.selectedElements.includes(element)) {
      //     this.selectedElements = this.selectedElements.filter(
      //       (e) => e !== element
      //     );
      //   } else {
      //     this.selectedElements.push(element);
      //   }
      //   this.updateElementSelectionStyles();
      // });
      this.mainPaper.on('link:mouseenter', (linkView) => {
        this.showLinkTools(linkView);
      });
      
      this.mainPaper.on('link:mouseleave', (linkView) => {
        linkView.removeTools();
      });
      this.mainGraph.on({
        'add': (cell) => this.handleAdd(cell),
        'remove': (cell) => this.handleRemove(cell),
        // 'change': (cell) => this.handleUpdate(cell)
      });
      // this.mainGraph.on('change:position', this.debounce(function (element) {
      //   const prevPosition = this.elementPreviousPositions[element.id] || { x: null, y: null };
      //   const currentPosition = element.position();

      //   if (prevPosition.x !== currentPosition.x || prevPosition.y !== currentPosition.y) {
      //     this.elementPreviousPositions[element.id] = currentPosition; 
      //     this.handleUpdate(element);
      //   }
      // }, 200, this)); 

      this.mainPaper.on('link:connect', (linkView, evt, elementView, magnet, arrowhead) => {
        
        // `linkView` is the view of the link being connected
        const link = linkView.model; // The actual link model
        const source = link.get('source'); // Source of the link
        const target = link.get('target'); // Target of the link

        // console.log('Link connected:');
        // console.log('Source:', source);
        // console.log('Target:', target);

        // Example: Save the link to the database
        this.handleAddLink(link);
    });

    this.mainGraph.on('change:attrs', (cell, attrs, { submitted }) => {
    // Check if the changed cell is a ButtonNode
    if (cell.isElement() && cell.get('type') === 'megashid.ButtonNode' && attrs.submitted && (cell.prop('class') === 'input_tag' || cell.prop('class') === 'output_tag')) {
      
      const nodeId = cell.id;
      console.log('pick data for block ',JSON.stringify(nodeId))
      cell.attr({ submitted: false });
      this.$store.dispatch("chpge/parent2child", {
        task: "pickedData",
        from: "ruleEditor",
        filter: {
          dataType: ["!complex"],
          usedBy: [],
          parents: [],
          archive: null,
          perPage: 2000,
          page: 1,
          withChild: false
        }
      });
      this.$root.$emit("bv::show::modal", "modal-data-picker");
      this.selectedDataTag = {
        _id: cell.prop('_id'),
        // class: cell.prop('class'),
        id: cell.id,
        // position: cell.position(),
      }
    }else if(cell.isElement() && cell.get('type') === 'megashid.ButtonNode' && attrs.submitted && (cell.prop('class') === 'list_tag' )){
      const nodeId = cell.id;
      console.log('pick data cat. for block ',JSON.stringify(nodeId))
      cell.attr({ submitted: false });
      this.$store.dispatch("chpge/parent2child", {
        task: "pickDataCategory",
        from: "ruleEditor",
        filter: {}
      });
      this.$root.$emit("bv::show::modal", "modal-data-category-picker");
      this.selectedDataTag = {
        _id: cell.prop('_id'),
        id: cell.id,
      }
    }else if (
      cell.isElement() && attrs.submitted && (cell.prop('class') === 'boolean_tag' || cell.prop('class') === 'string_tag' || cell.prop('class') === 'number_tag') &&
      (cell.get('type') === 'megashid.numberNode' || cell.get('type') === 'megashid.selectNode' || cell.get('type') === 'megashid.textNode') ){
        
        cell.attr({ submitted: false });

        console.log('-->',cell.prop('_id'),cell.id, cell.attr('data/props'))

        this.editRuleEditorCell(
          {
            cell:[
              {
                id: cell.id,
                _id: cell.prop('_id'),
                data:cell.attr('data/props') 
              }
            ]
          }
        )
    }
});
      
      
    },
    pasteSelection(){
      var clonedClipboard = {
        elements: [],
        links: []
      };
      var idMap = {}; // Map of original element IDs to cloned element IDs
      this.clipboard
        .filter((el) => el.isElement())
        .forEach((element) => {
            const clonedElement = element.clone();
            idMap[element.id] = clonedElement.id;
            clonedClipboard.elements.push(clonedElement);
        });

      const selectedElementIds = Object.keys(idMap); // Original IDs of selected elements
      this.clipboard
        .filter((el) => el.isLink())
        .filter((link) => {
          const sourceId = link.get('source').id;
          const targetId = link.get('target').id;
          return (
              selectedElementIds.includes(sourceId) ||
              selectedElementIds.includes(targetId)
          );
        })
        .forEach((link) => {
          const clonedLink = link.clone();
          const originalSource = link.get('source');
          const originalTarget = link.get('target');
          const newSourceId = idMap[originalSource.id];
          const newTargetId = idMap[originalTarget.id];
          clonedLink.set('source', {
              ...clonedLink.get('source'), 
              id: newSourceId // Override only the id
          });
          clonedLink.set('target', {
              ...clonedLink.get('target'), 
              id: newTargetId // Override only the id
          });
          clonedClipboard.links.push(clonedLink);
        });
      
      // Reset selected elements
      this.selectedElements = [];

      // Add cloned elements to the graph
      clonedClipboard.elements.forEach((clonedElement) => {
        clonedElement.translate(20, 20);
        this.mainGraph.addCell(clonedElement);
        this.selectedElements.push(clonedElement);
      });

      // Add cloned links to the graph
      clonedClipboard.links.forEach((clonedLink) => {
        const vertices = clonedLink.get('vertices');
        if (vertices) {
          clonedLink.set('vertices', vertices.map((v) => ({
            x: v.x + 20,
            y: v.y + 20,
          })));
        }
        this.mainGraph.addCell(clonedLink);
        this.selectedElements.push(clonedLink);
        
      });
      this.copySelection()

      // Update selection styles
      this.updateElementSelectionStyles();
    },
    copySelection() {
      this.clipboard = this.selectedElements
    },
    cutSelection() {
        this.copySelection(); // Copy elements and links
        this.mainGraph.removeCells(this.selectedElements); // Remove from graph
        this.selectedElements = [];
    },
    deleteSelection() {
      this.mainGraph.removeCells(this.selectedElements);
      this.selectedElements = [];
    },
    handleShortcuts(event) {
      // console.log('event',event)
      if (event.ctrlKey) {
        switch (event.key) {
          case 'c': // Ctrl + C for Copy
            this.copySelection();
            break;
          case 'x': // Ctrl + X for Cut
            this.cutSelection();
            break;
          case 'v': // Ctrl + V for Paste
            this.pasteSelection();
            break;
        }
      }else if (event.key === 'Delete'){  // || event.key === 'Backspace'
        this.deleteSelection();
      }
    },
    recreateCell(clonedCell) {
      // Extract the attributes, position, size, and ports of the cloned cell
      const attributes = clonedCell.attributes;
  

      // Create a new cell based on the cloned cell's type
      const newCell = new clonedCell.constructor();

      var ports = []
      var portGroups = {}

      if (clonedCell.getPorts) {
        ports = clonedCell.getPorts();
        portGroups = clonedCell.attributes.ports.groups || {};
      }

      // Apply the basic attributes to the new cell
      
      newCell.set({
        position: attributes.position,
        size: attributes.size,
        attrs: attributes.attrs,
        ports: {
          groups: portGroups
        },
        class:attributes.class,
      });

        if (ports.length > 0) {
          newCell.addPorts(ports); // Add port items
        }

      // Copy ports if the cell has them
      

      // Optionally copy other custom properties if required
      // Example: newCell.prop('customProperty', clonedCell.prop('customProperty'));

      return newCell;
    },
    showLinkTools(linkView) {
      var tools = new joint.dia.ToolsView({
        tools: [
          new joint.linkTools.Remove({
            distance: '50%',
            markup: [{
              tagName: 'circle',
              selector: 'button',
              attributes: {
                  'r': 7,
                  'fill': '#f6f6f6',
                  'stroke': '#ff5148',
                  'stroke-width': 2,
                  'cursor': 'pointer'
              }
            }, {
              tagName: 'path',
              selector: 'icon',
              attributes: {
                  'd': 'M -3 -3 3 3 M -3 3 3 -3',
                  'fill': 'none',
                  'stroke': '#ff5148',
                  'stroke-width': 2,
                  'pointer-events': 'none'
              }
            }]
          })
        ]
      });
      linkView.addTools(tools);
    },
    handleAddLink(cell) {
      const link = {
        class: 'link',
        id: cell.id,
        source: cell.get('source'),
        target: cell.get('target'),
      }
      console.log('apiAddLink',link)
      // this.addRuleEditorCell({cell:link})
      this.addRuleEditorCell(link)
    },
    handleAdd(cell) {
      if (cell.isLink()) {
        var source = cell.get('source');
        var target = cell.get('target');
        if (source.id && target.id) {
          const link = {
            class: 'link',
            id: cell.id,
            source: source,
            target: target
          }
          console.log('apiAddLink',link)
          // this.addRuleEditorCell({cell:link})
          this.addRuleEditorCell(link)
        }
      }
      if (cell.isElement()) {
        const element = {
          class: cell.prop('class'),
          id: cell.id,
          position: cell.position(),
        }
        console.log('apiAddElement',element)
        // this.addRuleEditorCell({cell:element})
        this.addRuleEditorCell(element)
      }
    },
    handleRemove(cell) {
      if (cell.isLink()) {
        var source = cell.get('source');
        var target = cell.get('target');
        if (source.id && target.id) {
          // this.removeRuleEditorCell({cell:cell.prop('_id')})
          this.removeRuleEditorCell(cell.prop('_id'))
          console.log('apiDeleteLink',cell,cell.prop('_id'))
        }
      } else {
        // this.removeRuleEditorCell({cell:cell.prop('_id')})
        this.removeRuleEditorCell(cell.prop('_id'))
        console.log('apiDeleteElement',cell.prop('_id'))
      }
    },
    handleUpdate(cells) {
      var elements = cells
        .filter(cell => cell.isElement())
        .map((cell)=>{
            return {
              _id: cell.prop('_id'),
              // class: cell.prop('class'),
              // id: cell.id,
              position: cell.position(),
            }
        })


      console.log('cells',cells)
      console.log('apiUpdateElement',JSON.stringify(elements))
      this.editRuleEditorCell({cell:elements})
    },
    createPaletteCategories(categories, blocks) {
      return categories.map(category => {
        return {
          name: category.label,
          collapsed: 'false',
          items: blocks
            .filter(block => block.category === category.type)
            .map(block => ({
              class: block.class,
              label: block.label, 
              color: block.color, 
              ports: block.ports, 
            }))
        };
      });
    },
    addRuleEditorCell(data) {
      // console.log('add rule')
      this.addCellReq.queue.push(data);
      if (this.addCellReq.timer) 
        clearTimeout(this.addCellReq.timer);

      this.addCellReq.timer = setTimeout(() => {
        console.log('this.addCellReq.queue',this.addCellReq.queue)
        
        this.$store.dispatch("data/addRuleEditorCell", {cell:this.addCellReq.queue}).then(
          (datas) => {
            // console.log('---> returned data',datas)
            datas.forEach((data)=>{
              const cell = this.mainGraph.getCell(data.id);
              // console.log('===>',cell.prop('class'),cell.isLink())
              cell.set('_id', data._id);
              if (cell.isElement()){
                cell.set('data', {});
                cell.attr('name/props/value', '');
              }
            })
          },
          (error) => {
          }
        );
        this.addCellReq.queue = []
        
      }, this.addCellReq.interval);
    },
    removeRuleEditorCell(data) {
      // console.log('remove rule')
      this.removeCellReq.queue.push(data);
      if (this.removeCellReq.timer) 
        clearTimeout(this.removeCellReq.timer);

      this.removeCellReq.timer = setTimeout(() => {
        console.log('this.removeCellReq.queue',this.removeCellReq.queue)
        
        this.$store.dispatch("data/removeRuleEditorCell", {cell:this.removeCellReq.queue}).then(
          (data) => {
          },
          (error) => {
          }
        );
        this.removeCellReq.queue = []
        
      }, this.removeCellReq.interval);
    },
    editRuleEditorCell(data) {
        this.$store.dispatch("data/editRuleEditorCell", data).then(
          (data) => {
          },
          (error) => {
          }
        );

      // console.log('edit rule')
      // this.editCellReq.queue.push(data);
      // if (this.editCellReq.timer) 
      //   clearTimeout(this.editCellReq.timer);

      // this.editCellReq.timer = setTimeout(() => {
      //   console.log('this.editCellReq.queue',this.editCellReq.queue)
        
      //   this.$store.dispatch("data/editRuleEditorCell", this.editCellReq.queue).then(
      //     (data) => {
      //     },
      //     (error) => {
      //     }
      //   );
      //   this.editCellReq.queue = []
        
      // }, this.editCellReq.interval);
    },
    async getRuleEditorCells() {
      await this.$store.dispatch("data/getRuleEditorCells").then(
        (data) => {
          // console.log(data)
          this.mainGraphCells = data
        },
        (error) => {
        }
      );
    },
    async getRuleEditorBlocks() {
      await this.$store.dispatch("data/getRuleEditorBlocks").then(
        (data) => {
          // console.log(data)
          this.blocks = data;
        },
        (error) => {
        }
      );
    },
    async getRuleEditorCategories() {
      await this.$store.dispatch("data/getRuleEditorCategories").then(
        (data) => {
          // console.log(data)
          this.categories = data
        },
        (error) => {
        }
      );
    },
  },
  computed: {
    filteredCategories() {
      const search = this.paletteSearch.toLowerCase();
      return this.paletteCategories.map((category) => ({
        ...category,
        items: category.items.filter((item) =>
          item.label.toLowerCase().includes(search)
        ),
      }));
    },
    child() {
      return this.$store.state.chpge.child2child;
    },
  },
  watch: {
    child(newValue) {
      if (newValue.task == "pickedData" && newValue.to == "ruleEditor" ) {
        console.log('picked data  ',newValue.item)
        if (Object.keys(newValue.item).length > 0){
          

          const cell = this.mainGraph.getCell(this.selectedDataTag.id);
          cell.attr('data/props/value', newValue.item.name);
          cell.attr('data/props/id', newValue.item.id);
          cell.attr('data/props/name', newValue.item.name);
          cell.attr('data/props/type', newValue.item.type);
          cell.attr('data/props/dataType', newValue.item.dataType);
          this.editRuleEditorCell({cell:[{...this.selectedDataTag, data:newValue.item}]})

          console.log('newvalue',newValue,cell)
        }
      }else if(newValue.task == "pickDataCategory" && newValue.to == "ruleEditor" ){
        console.log('picked data categort ',newValue.item)

        if (Object.keys(newValue.item).length > 0){
          const cell = this.mainGraph.getCell(this.selectedDataTag.id);
          cell.attr('data/props/value', newValue.item.name);
          cell.attr('data/props/id', newValue.item.id);
          cell.attr('data/props/name', newValue.item.name);
          cell.attr('data/props/type', newValue.item.type);
          cell.attr('data/props/dataType', newValue.item.dataType);
          this.editRuleEditorCell({cell:[{...this.selectedDataTag, data:newValue.item}]})

          console.log('newvalue',newValue,cell)
        }
      
      }
    }
  }
};
</script>

<style>
  .app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
  }
  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
  }
  .wrapper-diagram {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  .main-container {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .palette {
    width: 200px;
    background-color: #f0f0f0;
    border-right: 1px solid #ccc;
  }
  .palette h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .diagram-env {
    flex-grow: 1;
    background-color: #fff;
    border: 1px solid #ccc;
    position: relative;
  }
  .palette-search {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .palette-category {
    margin-bottom: 10px;
  }
  .category-header {
    background-color: #e0e0e0;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .category-items {
    background-color: #f9f9f9;
    padding: 0px;
  }
  .palette-env {
    width: 100%;
    height: auto;
  }
  .diagram-controls {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 20;
  }
  .selection-rectangle {
    position: absolute;
    background-color: rgba(0, 123, 255, 0.2);
    border: 1px solid #007bff;
    pointer-events: none; /* Prevent interfering with mouse events */
    z-index: 30;
  }
  .available-magnet {
    fill: #5DA271;
  }
  .available-cell rect {
    stroke-dasharray: 5, 2;
  }
  .jj-form {
    position: static;
    font-size: 14px;
    text-align: center;
    touch-action: none;
    padding: 25px 10px 5px 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
  }
  .jj-field-vertical {
    display: flex;
  }
  .jj-input {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
  .jj-button {
    width: 100%;
    height: 100%;
    margin: auto;
    color: white;
    background-color: rgba(255, 255, 255, 0.45);
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  .jj-field-vertical .jj-input {
    width: 100%;
  }
  .jj-field-vertical .jj-button {
    width: 30%;
  }
</style>
