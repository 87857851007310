<template>
  <div>
    <b-modal
      id="modal-location-picker"
      size="xl"
      centered
      header-text-variant="dark"
      header-border-variant="primary"
      body-text-variant="dark"
      :dir="dict.dir[lang]"
      scrollable
      content-class="shadow"
      @shown="initModal"
      @hidden="resetModal"
      hide-footer
    >
      <template #modal-header>
        <h5>{{ modalTitle[lang] }}</h5>
        <i>
          <font-awesome-icon
            icon="fas fa-xmark"
            class="close"
            @click="handleClose"
          />
        </i>
      </template>

      <div>
        <b-container fluid>
          <!--          <b-form-group>-->
          <!--            <b-form-radio-group-->
          <!--              v-model="type"-->
          <!--              class="d-flex"-->
          <!--              style="justify-content: space-around"-->
          <!--            >-->
          <!--              <b-form-radio value="data">Data</b-form-radio>-->
          <!--              <b-form-radio value="config" class="ml-5">Config</b-form-radio>-->
          <!--            </b-form-radio-group>-->
          <!--          </b-form-group>-->

          <div id="mapContainer_location" style="width:100%;height: 500px" class="mymap"></div>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */

import L from "leaflet";

export default {
  name: "locationPicker",
  data() {
    return {
      selected: null,
      tiles: ["outdoors-v11",
        "streets-v11",
        "dark-v10",
        "light-v10",
        "satellite-v9",
        "satellite-streets-v11",
        "navigation-day-v1",
        "navigation-night-v1"],
      to: "",
      type: "data",
      items: [],
      result: [],
      fields: [
        {
          key: "detail",
          label: "",
          _showDetails: true,
          thStyle: { width: "5%" }
        },
        { key: "name", per: "نام", eng: "name", label: "name", sortable: true },
        { key: "type", per: "نوع", eng: "type", label: "type", sortable: true, sortDirection: "asc" }
        // { key: "id", per: "نام", eng: "id", label: "name", sortable: true }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [{ value: 5, per: "5 ردیف", eng: "5 rows" },
        { value: 10, per: "10 ردیف", eng: "10 rows" },
        { value: 15, per: "15 ردیف", eng: "15 rows" },
        { value: 100, per: "100 ردیف", eng: "Show a lot" }],
      sortBy: "name",
      sortDesc: true,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      filterData: {},
      modalTitle: { per: "انتخاب داده", eng: "Pick Data" },
      dict: {
        dir: { per: "rtl", eng: "ltr" },
        emptyStr: { per: "هیچ رکوردی برای نمایش وجود ندارد", eng: "No records to display" },
        Clear: { per: "حذف", eng: "Clear" },
        search: { per: "جستجو", eng: "search" },
        name: { per: "نام داده", eng: "name" },
        type: { per: "نوع داده", eng: "type" }
      },
      map: {
        center: [0, 0],
        minZoom: "",
        maxZoom: "",
        maxNativeZoom: "",
        tileId: "",
        viewZoom: 14
      },
      mymap: [],
      marker: {},
      position: []
    };
  },
  computed: {
    parent2child() {
      return this.$store.state.chpge.locationPick;
    },
    lang() {
      return this.$store.state.chpge.lang;
    }
  },
  watch: {
    type(newValue) {
      this.getAllData();
    },
    parent2child(newValue) {
      if (newValue.task === "pickLocations") {
        this.to = newValue.from;
        this.filterData = newValue.filter;
        this.position = newValue.pos;
      }
    }
  },
  methods: {
    setupLeafletMap: function() {
      this.mymap = L.map("mapContainer_location");
      this.createMap();
    },
    initModal: function() {
      // this.getAllData();
      this.setupLeafletMap();
    },
    resetModal: function() {
    },
    getAllData() {
      this.$store.dispatch("data/getAllCategoryforList").then(
        data => {
          this.items = data;
          this.totalRows = data.length;
        },
        error => {
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    onRowClicked(item) {
      this.$store.dispatch("chpge/child2parent", {
        task: "pickDataCategory",
        to: this.to,
        item: {
          id: item._id,
          name: item.name,
          dataType: "freeArray",
          type: item.type
        }
      });
      this.$bvModal.hide("modal-data-category-picker");
    },
    handleClose() {
      this.$bvModal.hide("modal-location-picker");
    },
    getMouseCoordinate() {
      // this.mymap.on("mousemove", (e) => {
      //   this.marker.setLatLng([(e.latlng.lat).toFixed(4), (e.latlng.lng).toFixed(4)]);
      // });

      this.mymap.on("click", (e) => {
        function convertCoordinates(coordinates) {
          return `${coordinates.lat},${coordinates.lng}`;
        }

        this.result = convertCoordinates(e.latlng);

        // this.position = [e.latlng.lat.toFixed(4), e.latlng.lng.toFixed(4)];
        // console.log(result);
        this.$store.dispatch("chpge/locationPick", {
          task: "pickLocation",
          to: "dataManagerModal",
          item: {
            location: this.result,
            dataType: "location"
          }
        });
        this.$bvModal.hide("modal-location-picker");
      });
    },
    createMap: function() {
      this.map.center = [32.6577, 51.6699];
      this.map.minZoom = 2;
      this.map.maxZoom = 100;
      this.map.maxNativeZoom = 100;
      this.map.tileId = 1;
      this.map.viewZoom = 12;

      this.mymap.setView([32.6577, 51.6699], this.map.viewZoom);
      var layers = [];
      this.mymap.eachLayer(function(layer) {
        layers.push(layer);
      });
      layers.forEach((item) => this.mymap.removeLayer(item));

      // var mapOptions = {
      //   minZoom: this.map.minZoom,
      //   maxZoom: this.map.maxZoom,
      //   maxNativeZoom: this.map.maxNativeZoom,
      //   attribution: "&copy; <a href=\"http://www.openstreetmap.org/copyright\">OpenStreetMap</a>",
      //   id: "mapbox/" + this.tiles[this.map.tileId],//'mapbox/outdoors-v11', //dark-v10, light-v10, streets-v11, outdoors-v11, satellite-v9, satellite-streets-v11, navigation-day-v1, navigation-night-v1
      //   accessToken: "pk.eyJ1IjoiYWhtYWRzaGFmaWVpIiwiYSI6ImNrc2VwdHZ1bjB4aHEybnAyN282a29nMDEifQ.6uf31gUq1E3Em0x9MsdeRg"
      // };


      L.tileLayer.wms("https://name.isfahan.ir/saeeserver/wms", {
        layers: "monam:boostan,monam:roads,monam:water",
        format: "image/png",
        transparent: true,
        version: "1.3.0",
        tiled: true,
        crs: L.CRS.EPSG32639,
        uppercase: true,
        styles: "",
        attribution: "Map data © <source>"
      }).addTo(this.mymap);

      // L.tileLayer.wms('http://ows.mundialis.de/services/service?', {
      //   layers: 'TOPO-OSM-WMS',
      // }).addTo(this.mymap);


      // L.tileLayer("https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}", mapOptions).addTo(this.mymap);
      var myIcon = L.icon({
        iconUrl: require("@/assets/images/mapIcons/location-pin.png"),
        iconSize: [30, 30],
        iconAnchor: [15, 15],
        popupAnchor: [-15, -40],
        shadowAnchor: [0, 0],
        shadowSize: [10, 10]
      });
      this.marker = L.marker(this.map.center, {
        icon: myIcon,
        draggable: true
      }).addTo(this.mymap);
      if (this.position != "")
        this.marker.setLatLng(this.position.split(","));
      this.getMouseCoordinate();
    }
  }
};
</script>

<style scoped>
.close {
  font-size: 20px;
  color: #dc3545;
  cursor: pointer;
}

.close :hover {
  color: #9b9b9b;
}
</style>